import React, { useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
// @ts-ignore
import { Modal as ModalWrapper } from 'react-dialog-polyfill';
import { useOnClickOutside } from '@avtkit/hooks/useOnClickOutside';
import { withTheme } from '@avtkit/helpers/withTheme';
import { ModalProps } from '../types';
import DefaultCloseButton from './CloseButton';
import * as scheme from '../theme';
import { useModal } from '../useModal';

const ContentsWrapper = styled.div``;
const ButtonsContainer = styled.div``;

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
   const {
      index,
      children,
      className,
      extraButtons,
      closeButton: CloseButton = DefaultCloseButton,
      renderOnOpen = true,
      clickOverlayClose = true,
      escClose = true,
   } = props;
   const { activeModal, closeModal } = useModal();
   const isActive = activeModal.id === index;

   const wrapperRef = useRef<HTMLDivElement | null>();

   const closeHandler = useCallback(() => {
      if (isActive) {
         closeModal(activeModal.id);
      }
   }, [isActive, activeModal.id, closeModal]);

   useOnClickOutside(wrapperRef, () => {
      if (clickOverlayClose) {
         closeHandler();
      }
   });

   const generateExtraButtons = ({ id, button: Button }: { id: string; button: React.FC<any> }) => <Button key={id} />;

   return (
      <ModalWrapper
         className={className}
         open={isActive}
         onCancel={() => {
            if (escClose) {
               closeHandler();
            }
         }}
      >
         <ContentsWrapper
            ref={(ref) => {
               if (ref) {
                  wrapperRef.current = ref;
               }
            }}
         >
            <ButtonsContainer className="ms-modal-buttons-container">
               {!!CloseButton && <CloseButton onClick={() => closeHandler()} />}
               {extraButtons?.map(generateExtraButtons)}
            </ButtonsContainer>
            {(!renderOnOpen || isActive) && children}
         </ContentsWrapper>
      </ModalWrapper>
   );
};
const theme = withTheme<ModalProps>(scheme);

Modal.defaultProps = {
   clickOverlayClose: true,
};

export default styled(Modal)<ModalProps>`
   position: fixed;
   padding: 0;
   margin: 0;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);

   ${ButtonsContainer} {
      display: flex;
      flex-direction: row-reverse;
      position: absolute;
      right: ${(props) => props?.buttonsPosition?.x || '10px'};
      top: ${(props) => props?.buttonsPosition?.y || '10px'};
      z-index: 1;
   }

   & + .backdrop {
      height: 100vh;
      position: fixed;
      width: 100vw;
      top: 0;
      left: 0;
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         background: ${c.background};
         border: 1px solid ${c.border};
         box-shadow: 2px 10px 25px ${c.shadow};
         color: ${c.color};

         &::backdrop,
         & + .backdrop {
            background-color: ${c.backdrop};
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         border-radius: ${s.borderRadius};
      `
   )}

   ${(props) => css`
      ${props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}
   `}
`;
