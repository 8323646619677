import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { PromotionBannerProps } from './types';
import * as scheme from './theme';
import Close from './CloseBanner.svgr';
import { IconButton } from '@avtkit/controls/Button';
import Icon from '@avtkit/controls/Icon/components/SimpleIcon';
import ImageBox from '../ImageBox';

const PromotionBanner: React.FC<PromotionBannerProps> = (props: PromotionBannerProps) => {
   const { className, id, children, src, link } = props;
   const [isAvailable, setAvailable] = useState(localStorage.getItem(`dp${id}Banner`) !== '0');

   const closeBannerHandler = useCallback(() => {
      localStorage.setItem(`dp${id}Banner`, '0');
      setAvailable(false);
   }, [id]);

   if (!isAvailable) {
      return null;
   }

   const content = (
      <>
         {src && <ImageBox displayLoading src={src} alt="" fitMode="fill" />}
         {children}
      </>
   );

   return (
      <div className={className}>
         <IconButton onClick={closeBannerHandler}>
            <Icon shape={Close} />
         </IconButton>
         {link && (
            <a href={link} target="_blank" rel="noreferrer">
               {content}
            </a>
         )}
         {!link && content}
      </div>
   );
};
const theme = withTheme<PromotionBannerProps>(scheme);

export default styled(PromotionBanner)<PromotionBannerProps>`
   position: relative;
   display: inline-block;
   overflow: hidden;

   ${({ width = 'auto', height = 'auto' }) => css`
      width: ${width};
      height: ${height};
   `}

   ${IconButton} {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         ${IconButton} {
            svg rect {
               fill: ${c.iconBg};
            }

            svg path {
               fill: ${c.icon};
            }

            &:hover {
               svg {
                  fill: ${c.icon};
               }
            }
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         border-radius: ${s.border};
      `
   )}
`;
