import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { OffCanvasProps } from '../types';
import * as scheme from '../theme';
import { useOffCanvas } from '../useOffCanvas';

const OffCanvasBackdrop = styled.div``;
const OffCanvasContent = styled.div``;
export const OffCanvasCloseHolder = styled.div``;

const OffCanvasWrapper: React.FC<OffCanvasProps> = (props: OffCanvasProps) => {
   const { index, children, className, useBackdrop, closeButton } = props;
   const { activeOffCanvas, closeOffCanvas } = useOffCanvas();
   const isActive = activeOffCanvas.id === index;

   const close = useCallback(() => {
      closeOffCanvas(index);
   }, [closeOffCanvas, index]);

   return (
      <div className={`${className} ${isActive ? 'depicter-is-open' : ''}`}>
         {useBackdrop && <OffCanvasBackdrop />}
         {closeButton && <OffCanvasCloseHolder onClick={close}>{closeButton}</OffCanvasCloseHolder>}
         <OffCanvasContent>
            {React.isValidElement(children) && React.cloneElement(children, { options: activeOffCanvas?.options })}
         </OffCanvasContent>
      </div>
   );
};
const theme = withTheme<OffCanvasProps>(scheme);

export default styled(OffCanvasWrapper)<OffCanvasProps>`
   ${(props) => css`
      ${OffCanvasContent} {
         width: ${props.width};
         height: ${props.height};
      }
   `}

   ${OffCanvasBackdrop} {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      z-index: 8;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);
   }

   ${OffCanvasContent} {
      position: fixed;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      z-index: 9;
      transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
   }

   ${OffCanvasCloseHolder} {
      position: fixed;
      top: 30px;
      right: 30px;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
   }

   &.depicter-is-open {
      ${OffCanvasContent} {
         transform: translateX(0);
      }

      ${OffCanvasCloseHolder}, ${OffCanvasBackdrop} {
         opacity: 1;
         visibility: visible;
      }
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         ${OffCanvasContent} {
            background-color: ${c.background};
            border-right: 1px solid ${c.border};
         }

         ${OffCanvasBackdrop} {
            background-color: ${c.backdrop};
         }
      `
   )}
`;
