import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Stylable } from '@avtkit/types/commons';
import KitUserMenu from '@avtkit/controls/UserMenu';
import { MenuItem } from '@avtkit/controls/ContextMenu';
import { UserInfo } from '../API/types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClickEvent } from '@szhsin/react-menu';
import { useLogout } from '../hooks/useLogin';

type Props = Stylable & UserInfo;

const UserMenu: React.FC<Props> = (props) => {
   const { className, email, firstName, lastName } = props;
   const { t } = useTranslation();
   const navigate = useNavigate();
   const doLogout = useLogout();

   const onItemClickHandler = useCallback(
      ({ value }: ClickEvent) => {
         switch (value) {
            case 'profile':
               navigate('/profile');
               break;
            case 'logout':
               doLogout();
               break;
            default:
               break;
         }
      },
      [doLogout, navigate]
   );

   return (
      <KitUserMenu
         className={className}
         gravatar={email}
         name={`${firstName} ${lastName}`}
         onItemClick={onItemClickHandler}
      >
         <MenuItem size="medium" value="profile">
            {t('Profile')}
         </MenuItem>
         <MenuItem size="medium" value="logout">
            {t('Logout')}
         </MenuItem>
      </KitUserMenu>
   );
};

export default styled(UserMenu)``;
