import * as React from 'react';
import styled, { css } from 'styled-components';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import { withTheme } from '@avtkit/helpers/withTheme';
import Dropdown from './Dropdown';
import { StyledDropdownProps } from '../types';
import { DropdownColors, DropdownSizes, dropdown } from '../theme';
import RemoveIndicator from './RemoveIndicator';

const MultiSelect: React.FC<StyledDropdownProps> = (props: StyledDropdownProps) => <Dropdown {...props} />;

const theme = withTheme<StyledDropdownProps>(dropdown);

export default withDefaultProps(
   styled(MultiSelect)`
      .ms-dd__control {
         height: auto;
      }

      .ms-dd__value-container {
         display: flex;
         gap: 5px;
         padding: 5px;
      }

      ${theme.colors(
         (c: DropdownColors) => css`
            .ms-dd__multi-value {
               background: ${c.multi.background};

               .ms-dd__multi-value__label {
                  color: ${c.multi.color};
               }

               .ms-dd__multi-value__remove {
                  > svg {
                     fill: ${c.multi.icon};
                  }

                  &:hover {
                     background: transparent;
                  }
               }

               &:hover {
                  background: ${c.multi.hover};
               }
            }
         `
      )}

      ${theme.sizes(
         (s: DropdownSizes, { size }) => css`
            .ms-dd__multi-value {
               border-radius: ${s.multi.borderRadius};
               font-size: ${s[size].fontSize};
               font-weight: ${s[size].fontWeight};
            }
         `
      )}
   `,
   {
      components: {
         DropdownIndicator: () => null,
         IndicatorSeparator: () => null,
         ClearIndicator: () => null,
         MultiValueRemove: RemoveIndicator,
      },
      isMulti: true,
      size: 'medium',
   }
);
