import React from 'react';
import styled from 'styled-components';
import { Stylable, WithChildren } from '@avtkit/types/commons';
import { SolidButton } from '../Button';
import GoogleIconSrc from './Google.svg';

type Props = Stylable &
   WithChildren & {
      onClick: () => void;
      disabled?: boolean;
      loading?: boolean;
   };

const Button = styled(SolidButton)`
   border: solid 2px #e3e3e3;
   background: #ffffff;
   color: #757575;
   font-weight: 600;

   :hover {
      background: #f1f1f1;
      color: inherit;
   }
`;

const Icon = styled.img.attrs({ src: GoogleIconSrc, alt: '' })`
   margin-right: 5px;
`;

const LoginButton: React.FC<Props> = (props) => <Button icon={<Icon />} {...props} />;

export default styled(LoginButton)``;
