import styled from 'styled-components';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import { iconSizes } from '../constants';
import { StyledIconProps } from '../types';
import Icon from './SimpleIcon';

const StyledIcon = styled(Icon)<StyledIconProps>`
   display: flex;

   ${(props) => `
        width: ${iconSizes[props.size]};
        height: ${iconSizes[props.size]};
    `}
   & > *:first-child {
      ${(props) => (props.size !== 'auto' ? 'width: 100%; height: auto;' : '')}

      fill: ${(props) => (props.color ? props.color : 'inherit')};
   }
`;

export default withDefaultProps(StyledIcon, {
   size: 'auto',
});
