import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   input: {
      normal: {
         background: '#FDFDFD',
         border: '#CACACA',
         color: '#000000',
      },
      hover: {
         background: '#FDFDFD',
         border: '#4F4F4F',
      },
      focus: {
         background: '#FDFDFD',
         border: '#4F4F4F',
      },
      disable: {
         background: '#FDFDFD',
         border: '#CACACA',
         color: '#CACACA',
      },
      error: {
         background: '#FF504A',
         color: '#FFFFFF',
         border: 'red', // #4F4F4F',
         icon: '#FF504A',
      },
      clearButton: {
         color: '#3C3C3C',
         hover: {
            background: '#3C3C3C',
            color: 'white',
         },
      },
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   input: {
      medium: {
         fontSize: '15px',
         padding: '10px 15px',
         border: '1px',
         borderRadius: '5px',
         height: '37px',
         clearIcon: {
            width: '22px',
            height: '22px',
         },
      },
      small: {
         fontSize: '12px',
         padding: '8px 9px',
         border: '1px',
         borderRadius: '5px',
         height: '31px',
         clearIcon: {
            width: '20px',
            height: '20px',
         },
      },
      tiny: {
         fontSize: '11px',
         padding: '6px 9px',
         border: '1px',
         borderRadius: '5px',
         height: '25px',
         clearIcon: {
            width: '20px',
            height: '20px',
         },
      },
   },
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
