import { Stylable } from '@avtkit/types/commons';
import React from 'react';
import styled from 'styled-components';
import { useMySubscriptions } from '../../contexts/MySubscriptionsData';
import SubscriptionItem from './SubscriptionItem';

type Props = Stylable & {};

const SubscriptionsList: React.FC<Props> = (props) => {
   const { className } = props;

   const {
      state: { items },
   } = useMySubscriptions();

   return (
      <div className={className}>
         {items.map((item) => (
            <SubscriptionItem key={item.id} {...item} />
         ))}
      </div>
   );
};

export default styled(SubscriptionsList)``;
