import styled, { css } from 'styled-components';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import * as scheme from './theme';

type Props = TooltipProps &
   WithThemeProps & {
      inverted?: boolean;
   };

const theme = withTheme<Props>(scheme);

export default styled(ReactTooltip)<Props>`
   &.__react_component_tooltip {
      z-index: 90000;

      &:after {
         display: none;
      }

      border: solid 1px;

      &.show {
         opacity: 1 !important;
      }

      ${theme.colors(
         (c: scheme.Colors, { inverted }: Props) => css`
            background-color: ${inverted ? c.inverted.background : c.background} !important;
            border-color: ${inverted ? c.inverted.border : c.border};
            box-shadow: ${inverted ? c.inverted.shadow : c.shadow};
            color: ${inverted ? c.inverted.color : c.color} !important;
         `
      )}

      ${theme.sizes(
         (s: scheme.Sizes) => css`
            padding: ${s.padding};
            font-size: ${s.fontSize};
            border-radius: ${s.radius};
            max-width: ${s.maxWidth};
            &.place-right {
               margin-left: ${s.space};
            }

            &.place-left {
               margin-right: ${s.space};
            }

            &.place-top {
               margin-bottom: ${s.space};
            }

            &.place-bottom {
               margin-top: ${s.space};
            }
         `
      )}
   }
`;
