import React from 'react';
import styled from 'styled-components';
import { IndicatorProps, OptionTypeBase } from 'react-select';
import Arrow from './icons/arrow.svgr';

const DropdownIndicator = ({ className }: IndicatorProps<OptionTypeBase, boolean>) => (
      <div className={className}>
         <Arrow />
      </div>
   );

export default styled(DropdownIndicator)`
   display: flex;
`;
