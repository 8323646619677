/* eslint-disable react/no-unused-prop-types */
import { Stylable, WithChildren } from '@avtkit/types/commons';
import React from 'react';
import styled, { css } from 'styled-components';

type AppearWrapProps = Stylable &
   WithChildren & {
      renderOnAppear: boolean;
      appeared: boolean;
      duration?: number;
      delay?: number;
   };

const AppearWrap: React.FC<AppearWrapProps> = (props) => {
   const { className, children, appeared, renderOnAppear } = props;
   return <div className={className}>{(appeared || !renderOnAppear) && children}</div>;
};

export default styled(AppearWrap)`
   width: 100%;
   height: 100%;

   ${({ renderOnAppear, duration = 400, appeared, delay = 0 }) =>
      renderOnAppear &&
      css`
         transition: opacity ${duration}ms ${delay}ms, visibility ${duration}ms ${delay}ms;
         visibility: ${appeared ? 'visible' : 'hidden'};
         opacity: ${appeared ? '1' : '0'};
      `}
`;
