import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   variants: {
      active: {
         bg: 'rgba(1, 208, 111, 0.1)',
         border: '#01D06F',
         color: '#01D06F',
      },
      'expires-soon': {
         bg: 'rgba(255, 185, 19, 0.1)',
         border: '#FFB913',
         color: '#FFB913',
      },
      expired: {
         bg: 'rgba(255, 80, 74, 0.1)',
         border: '#FF504A',
         color: '#FF504A',
      },
      'pending-cancel': {
         bg: 'rgba(0, 0, 0, 0.1)',
         border: '#000000',
         color: '#000000',
      },
      cancelled: {
         bg: 'rgba(0, 0, 0, 0.1)',
         border: '#000000',
         color: '#000000',
      },
      new: {
         bg: '#01D06F',
         border: 'transparent',
         color: '#FFFFFF',
      },
      fixed: {
         bg: '#FF504A',
         border: 'transparent',
         color: '#FFFFFF',
      },
      improved: {
         bg: '#7361E8',
         border: 'transparent',
         color: '#FFFFFF',
      },
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
