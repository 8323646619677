import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import ButtonLogic from './ButtonLogic';
import { ButtonProps } from '../types';
import { OutlineButtonColors, OutlineButtonSizes, outlineButton } from '../theme';
import DotSpinner from '@avtkit/controls/Loading/components/DotSpinner';

export type OutlineButtonProps = ButtonProps &
   WithThemeProps & {
      size: 'tiny' | 'large' | 'medium' | 'small';
      variant?: 'round' | 'curve';
   };

const theme = withTheme<OutlineButtonProps>(outlineButton);
const OutlineButton = styled(ButtonLogic)<OutlineButtonProps>`
   appearance: none;
   span,
   & {
      transition: background-color 300ms, color 300ms, border-color 300ms;
   }

   display: flex;
   align-items: center;
   justify-content: space-around;
   text-decoration: none;
   cursor: ${(props) => (props.disabled || props.loading ? 'auto' : 'pointer')};
   opacity: ${(props) => (!(props.disabled || props.loading) ? '1' : '0.3')};

   ${(props) =>
      props.icon &&
      css`
         svg {
            margin-right: 5px;
            display: block;
         }
      `}

   ${(props) =>
      props.loading &&
      css`
         position: relative;
         > * {
            visibility: hidden;
         }

         > ${DotSpinner} {
            position: absolute;
            top: 50%;
            left: 50%;
            visibility: visible;
            transform: translate(-50%, -50%);
         }
      `}

   ${theme.colors(
      (c: OutlineButtonColors, props) => css`
         color: ${c.color};

         span {
            color: ${c.color};
         }

         background-color: ${c.background};
         border: solid ${c.border};

         ${props.icon &&
         css`
            svg {
               fill: ${c.icon} !important;
            }
         `}

         &:hover {
            color: ${c.hover.color};
            background-color: ${c.hover.background};
            border-color: ${c.hover.border};

            span {
               color: ${c.hover.color};
            }

            ${props.icon &&
            css`
               svg {
                  fill: ${c.hover.icon} !important;
               }
            `}
         }

         ${(props.disabled || props.loading) &&
         css`
            &:hover {
               background-color: ${c.background};
               color: ${c.color};

               border-color: ${c.border};

               ${props.icon &&
               css`
                  svg {
                     fill: ${c.icon} !important;
                  }
               `}
            }
         `}
      `
   )}

   ${theme.sizes(
      (s: OutlineButtonSizes, { size, variant = 'curve' }) => css`
         padding: ${s[size].padding};
         height: ${s[size].height};
         font-size: ${s[size].fontSize};
         font-weight: ${s[size].fontWeight};
         border-width: ${s[size].border};
         border-radius: ${s.borderRadius[variant]};
      `
   )}
`;

export default withDefaultProps(OutlineButton, {
   size: 'large',
});

export const RoundOutlineButton = withDefaultProps((props: OutlineButtonProps) => <OutlineButton {...props} />, {
   size: 'large',
   variant: 'round',
});
