import React, { useState } from 'react';
import { AuthenticationData, AuthenticationProviderProps } from '../types';
import AuthenticationContext from './AuthenticationContext';
import Cookies from 'js-cookie';

const getTokenAuthenticationData = (name: string): AuthenticationData => {
   if (!Cookies.get(name)) {
      return {
         isAuthorized: false,
      };
   }
   const cookieData = JSON.parse(Cookies.get(name)!);

   return {
      isAuthorized: true,
      ...cookieData,
   };
};

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
   const [authenticationData, setAuthenticationData] = useState<AuthenticationData>(
      getTokenAuthenticationData('depicterTokenData')
   );

   return (
      <AuthenticationContext.Provider value={{ ...authenticationData, setAuthenticationData }}>
         {children}
      </AuthenticationContext.Provider>
   );
};

export default AuthenticationProvider;
