import {
   createRemoteDataContext,
   createRemoteDataProvider,
   useRemoteDataRequest,
} from '@avtkit/helpers/RemoteDataContext';
import { WithChildren } from '@avtkit/types/commons';
import { AxiosRequestConfig } from 'axios';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { api, axios } from '../API';
import { SubscriptionsSet } from '../API/types';

const initialMySubscriptions: SubscriptionsSet = {
   items: [],
};

export const MySubscriptionsContext = createRemoteDataContext<SubscriptionsSet>();
const DataProvider = createRemoteDataProvider<SubscriptionsSet>(MySubscriptionsContext);

export const MySubscriptionsProvider: React.FC<WithChildren> = (props) => {
   const { children } = props;
   return <DataProvider initialState={initialMySubscriptions}>{children}</DataProvider>;
};

export const useMySubscriptions = () => {
   const requestConfig: AxiosRequestConfig = useMemo(() => api.getMySubscriptions({ withVacancy: false }), []);
   const request = useRemoteDataRequest<SubscriptionsSet>(MySubscriptionsContext, axios);
   const requestHandler = useCallback(() => request(requestConfig), [requestConfig, request]);
   return { ...useContext(MySubscriptionsContext), request: requestHandler };
};

export const MySubscriptionRemoteDataRequest = () => {
   const { request } = useMySubscriptions();
   useEffect(() => request(), [request]);
   return null;
};
