import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { CheckBoxProps } from './types';
import { checkBox, CheckBoxSizesType, CheckBoxColorsType } from './theme';
import Checked from './icons/checked.svgr';
import Indeterminate from './icons/indeterminate.svgr';

const CheckboxLogic: React.FC<CheckBoxProps> = ({
   className,
   value,
   onChange,
   disabled,
   indeterminate,
   children,
}: CheckBoxProps) => {
   const clickHandler = () => {
      if (disabled || !onChange) {
         return;
      }

      if (value === 'indeterminate') {
         onChange(true);
      } else {
         onChange(!value);
      }
   };

   return (
      <div className={className} onClick={clickHandler}>
         <div>{indeterminate ? <Indeterminate /> : <Checked />}</div>
         <span>{children}</span>
      </div>
   );
};

const theme = withTheme<CheckBoxProps>(checkBox);

export const Checkbox = styled(CheckboxLogic)<CheckBoxProps>`
   position: relative;
   display: inline-flex;
   flex-flow: row;
   align-items: center;
   cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};

   div {
      position: relative;
      border-style: solid;
      transition: all 150ms ease-out;
      margin-right: 8px;
      margin-top: 2px;

      svg {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translateY(-50%) translateX(-50%);
         transition: all 150ms ease-out;
      }
   }

   ${theme.sizes(
      (s: CheckBoxSizesType, props: CheckBoxProps) => css`
         div {
            width: ${s.width};
            height: ${s.height};
            border-radius: ${props.round ? s.roundBorder : '50%'};
            border-width: ${s.border};
         }
      `
   )}

   ${theme.colors(
      (c: CheckBoxColorsType, props: CheckBoxProps) => css`
         div {
            ${props.value
               ? css`
                    ${props.disabled
                       ? css`
                            background-color: ${c.checked.disabled.background};
                            border-color: ${c.checked.disabled.border};

                            svg path {
                               fill: ${c.checked.disabled.iconColor};
                            }
                         `
                       : css`
                            background-color: ${c.checked.background};
                            border-color: ${c.checked.border};

                            svg path {
                               fill: ${c.checked.iconColor};
                            }
                         `}

                    svg {
                       opacity: 1;
                    }
                 `
               : css`
                    ${props.disabled
                       ? css`
                            background-color: ${c.unChecked.disabled.background};
                            border-color: ${c.unChecked.disabled.border};
                         `
                       : css`
                            background-color: ${c.unChecked.background};
                            border-color: ${c.unChecked.border};
                            &:hover {
                               border-color: ${c.hover.border};
                            }
                         `}

                    svg {
                       opacity: 0;
                    }
                 `}
         }

         ${props.indeterminate &&
         css`
            div {
               background-color: ${c.indeterminate.background};
               border-color: ${c.indeterminate.border};

               &:hover {
                  background-color: ${c.indeterminate.background};
                  border-color: ${c.indeterminate.border};
               }

               svg rect {
                  fill: #fff;
               }
            }
         `}
      `
   )}
`;
