import { useCallback, useContext, useEffect, useState } from 'react';
import OffCanvasContext, { OffCanvasStack, OffCanvasContextType } from './components/OffCanvasContext';
import { uniqBy } from 'lodash';

export const useOffCanvas = () => {
   const { offCanvasStacks, setOffCanvasStacks } = useContext<OffCanvasContextType>(OffCanvasContext);
   const [activeOffCanvas, setActiveOffCanvas] = useState<OffCanvasStack>(
      offCanvasStacks[0] || { id: '', open: false }
   );

   useEffect(() => {
      setActiveOffCanvas(offCanvasStacks[0] || { id: '' });
   }, [offCanvasStacks]);

   const closeOffCanvas = useCallback(
      (id: string) => {
         setOffCanvasStacks((previousStacks) => previousStacks.filter((stack) => stack.id !== id));
      },
      [setOffCanvasStacks]
   );

   const addToOffCanvasStack = useCallback(
      (newStacks: OffCanvasStack[]) => {
         setOffCanvasStacks((previousStacks) => uniqBy([...previousStacks, ...newStacks], 'id'));
      },
      [setOffCanvasStacks]
   );

   return {
      activeOffCanvas,
      closeOffCanvas,
      addToOffCanvasStack,
   };
};
