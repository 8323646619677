import {
   createRemoteDataContext,
   createRemoteDataProvider,
   useRemoteDataRequest,
} from '@avtkit/helpers/RemoteDataContext';
import { RemoteDataRequestOptions } from '@avtkit/helpers/RemoteDataContext/hooks';
import { WithChildren } from '@avtkit/types/commons';
import React, { useCallback, useContext, useEffect } from 'react';
import { api, axios } from '../API';
import { ChangelogsRequestParams, ChangelogsSet } from '../API/types';

const initialChangelogs: ChangelogsSet = {
   hits: [],
   hasMore: true,
};

export const ChangelogsContext = createRemoteDataContext<ChangelogsSet>();
const DataProvider = createRemoteDataProvider<ChangelogsSet>(ChangelogsContext);

export const ChangelogsProvider: React.FC<WithChildren> = (props) => (
   <DataProvider initialState={initialChangelogs} {...props} />
);

export const useChangelogs = (remoteRequestOptions?: RemoteDataRequestOptions) => {
   const request = useRemoteDataRequest<ChangelogsSet>(ChangelogsContext, axios, remoteRequestOptions);
   const requestHandler = useCallback(
      (params: ChangelogsRequestParams, filterResponse?: (data: ChangelogsSet) => void) =>
         request(api.getChangelog(params), undefined, filterResponse),
      [request]
   );

   return {
      ...useContext(ChangelogsContext),
      request: requestHandler,
   };
};

export const ChangelogsRemoteDataRequest = (props: ChangelogsRequestParams) => {
   const { request } = useChangelogs();
   useEffect(() => request(props), [request, props]);
   return null;
};
