/* eslint-disable global-require */
import React from 'react';
import ReactDOM from 'react-dom';
import browserEnv from './env/browser.env';

const render = () => {
   const App = require('./App').default;
   ReactDOM.render(<App />, document.querySelector(browserEnv.rootElement));
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
   module.hot.accept('./App', render);
}
