import React, { useState, useCallback, useEffect, FormEvent, FocusEvent } from 'react';
import useInputValidation from './useInputValidation';
import { assignRef } from '@avtkit/utils/functions';
import { FormInputProps } from '../types';

const useInputProps = (
   ref: React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>,
   props: Partial<FormInputProps>
) => {
   const { pattern, validationOn, onInput, onBlur, ...rest } = props;
   const { setInputRef, checkValidity } = useInputValidation(pattern);
   const [isValid, setValid] = useState(true);
   const [inputValue, setInputValue] = useState(rest.defaultValue);

   const onGetRef = useCallback(
      (node: HTMLInputElement | HTMLTextAreaElement) => {
         if (node) {
            assignRef(node, ref);
            setInputRef(node);
         }
      },
      [setInputRef, ref]
   );

   const onInputHandler = useCallback(
      (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
         setInputValue(event.currentTarget.value);
         if (validationOn === 'input') {
            setValid(checkValidity());
         }
         onInput?.(event);
      },
      [validationOn, checkValidity, onInput]
   );

   const onBlurHandler = useCallback(
      (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
         if (validationOn === 'blur') {
            setValid(checkValidity());
         }

         onBlur?.(event);
      },
      [validationOn, checkValidity, onBlur]
   );

   const onInvalidHandler = (event: { preventDefault: () => void }) => {
      event.preventDefault();
   };

   const onSubmit = useCallback(() => {
      if (validationOn === 'submit') {
         setValid(checkValidity());
      }
   }, [validationOn, checkValidity]);

   useEffect(() => {
      const formElement = ref.current?.form;

      if (formElement && validationOn === 'submit') {
         formElement.addEventListener('submit', onSubmit);
         return () => {
            formElement.removeEventListener('submit', onSubmit);
         };
      }

      return () => {};
   }, [validationOn, ref, onSubmit]);

   useEffect(() => {
      setInputValue(rest.defaultValue);
   }, [rest.defaultValue]);

   return {
      inputValue,
      valid: isValid,
      ref: onGetRef,
      onInput: onInputHandler,
      onBlur: onBlurHandler,
      onInvalid: onInvalidHandler,
      ...rest,
   };
};

export default useInputProps;
