import React, { useCallback, useState } from 'react';
import { Stylable, WithChildren } from '@avtkit/types/commons';

import styled from 'styled-components';
import LoginButton from './LoginButton';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';

type Props = Stylable &
   WithChildren & {
      onSuccess: (resp: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => void;
      onError: (resp: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => void;
   };

const GoogleLoginButton = (props: Props) => {
   const { className, children, onSuccess, onError } = props;
   const [loading, setLoading] = useState<boolean>(false);

   const login = useGoogleLogin({
      onSuccess: (tokenResponse) => {
         setLoading(false);
         onSuccess(tokenResponse);
      },
      onError: (tokenResponse) => {
         setLoading(false);
         onError(tokenResponse);
      },
   });

   const onclick = useCallback(() => {
      setLoading(true);
      login();
   }, [login]);

   return (
      <LoginButton onClick={onclick} loading={loading} className={className}>
         {children || 'Login by Google'}
      </LoginButton>
   );
};

export default styled(GoogleLoginButton)``;
