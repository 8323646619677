import { IconProps } from '@avtkit/controls/Icon/types';
import styled, { css } from 'styled-components';
import { SimpleIcon } from '@avtkit/controls/Icon';
import * as React from 'react';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import * as scheme from '../theme';
import ClearIcon from '@avtkit/controls/Icon/shapes/Cross.svgr';
import ClearMediumIcon from '@avtkit/controls/Icon/shapes/CrossMedium.svgr';

export type ClearButtonProps = Omit<IconProps, 'shape'> &
   WithThemeProps & {
      size: 'tiny' | 'medium' | 'small';
   };
const ClearButton = ({ size, ...props }: ClearButtonProps) => (
   <SimpleIcon shape={size === 'medium' ? ClearIcon : ClearMediumIcon} {...props} />
);

const theme = withTheme<ClearButtonProps>(scheme);

export default styled(ClearButton)`
   width: ${(props) => (props.size === 'medium' ? '30px' : '25px')};
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;

   &::after {
      content: '';
      display: block;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      transition: opacity 100ms;

      ${theme.sizes(
         (s: scheme.Sizes, { size }) => css`
            width: ${s.input[size].clearIcon.width};
            height: ${s.input[size].clearIcon.height};
         `
      )};
   }

   &:hover::after {
      opacity: 1;
   }

   svg {
      position: relative;
      z-index: 1;
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         fill: ${c.input.clearButton.color};

         &:hover {
            fill: ${c.input.clearButton.hover.color};
         }

         &::after {
            background-color: ${c.input.clearButton.hover.background};
         }
      `
   )};
`;
