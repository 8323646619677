import React, { useEffect, useState } from 'react';
import { OffCanvasProviderProps } from '../types';
import OffCanvasContext, { OffCanvasStack } from './OffCanvasContext';

const OffCanvasManager = ({ children, defaultStack }: OffCanvasProviderProps) => {
   const [offCanvasStacks, setOffCanvasStacks] = useState<OffCanvasStack[]>(defaultStack || []);

   useEffect(() => {
      setOffCanvasStacks(defaultStack || []);
   }, [defaultStack]);

   return (
      <OffCanvasContext.Provider value={{ offCanvasStacks, setOffCanvasStacks }}>{children}</OffCanvasContext.Provider>
   );
};

export default OffCanvasManager;
