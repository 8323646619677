import { OutlineButton } from '@avtkit/controls/Button';
import Table from '@avtkit/controls/Table';
import { Stylable } from '@avtkit/types/commons';
import { formatDateAndTime } from '@avtkit/utils/functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PaymentInfo } from '../../API/types';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/Typography';

type Props = Stylable & {
   items: PaymentInfo[];
};

const ViewInvoiceButton = styled(OutlineButton).attrs({ variant: 'round', size: 'small' })`
   display: inline-block;
`;

const TableContainer = styled.div`
   overflow-x: auto;
`;

const PaymentHistoryCard: React.FC<Props> = (props) => {
   const { className, items } = props;
   const { t } = useTranslation();
   return (
      <Card className={className}>
         <CardTitle>{t('Payment history')}</CardTitle>
         <TableContainer>
            <Table>
               <thead>
                  <tr>
                     <th>{t('ID')}</th>
                     <th>{t('Payment date')}</th>
                     <th>{t('Type')}</th>
                     <th>{t('Amount')}</th>
                     <th>{t('Invoice')}</th>
                  </tr>
               </thead>
               <tbody>
                  {items.map((item) => (
                     <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{formatDateAndTime(item.date)}</td>
                        <td>{item.type}</td>
                        <td>{item.amount}</td>
                        <td>
                           <ViewInvoiceButton>{t('View')}</ViewInvoiceButton>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </Table>
         </TableContainer>
      </Card>
   );
};

export default styled(PaymentHistoryCard)`
   ${TableContainer} {
      margin-top: 20px;
   }

   ${Table} {
      min-width: 600px;
      td:last-child,
      th:last-child {
         text-align: end;
      }
   }
`;
