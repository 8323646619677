/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Stylable } from '@avtkit/types/commons';
import { Content } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import { OutlineButton, SolidButton } from '@avtkit/controls/Button';
import { useSimpleRequest } from '@avtkit/hooks/useSimpleRequest';
import { api, axios } from '../../API';
import { Flag } from '@avtkit/controls/Flags';
import { useSingleSubscription } from '../../contexts/SingleSubscriptionData';
import { useModal } from '@avtkit/controls/ModalsManager/useModal';

type Props = Stylable & {};

const Confirm = styled(OutlineButton).attrs({ variant: 'round', size: 'medium' })``;
const Close = styled(SolidButton).attrs({ variant: 'round', size: 'medium' })``;
const PositiveConfirm = styled(SolidButton).attrs({ variant: 'round', size: 'medium' })``;
const PositiveClose = styled(OutlineButton).attrs({ variant: 'round', size: 'medium' })``;
const Row = styled.div``;

const AutoRenewal: React.FC<Props> = (props) => {
   const { className } = props;
   const { t } = useTranslation();
   const { setState: updateSubscription, state: subscription } = useSingleSubscription();
   const { renewable, id } = subscription;
   const { closeModal } = useModal();

   const onSuccess = useCallback(() => {
      updateSubscription({ ...subscription, renewable: !renewable });
      closeModal('autoRenewal');
   }, [closeModal, renewable, subscription, updateSubscription]);

   const { errorMessage, loading, request } = useSimpleRequest(api.updateAutoRenewal, onSuccess, axios);

   const switchRenewal = useCallback(() => {
      request({
         id,
         enable: !renewable,
      });
   }, [id, renewable, request]);

   return (
      <div className={className}>
         {renewable && (
            <>
               <Content>
                  {t(
                     'Are you sure you want to disable auto-renewal on this subscription? Keep auto-renewal activated always to have access to premium features.'
                  )}
               </Content>
               <Row>
                  <Close onClick={() => closeModal('autoRenewal')}>{t('Cancel')}</Close>
                  <Confirm loading={loading} onClick={switchRenewal}>
                     {t('Disable Auto Renewal')}
                  </Confirm>
               </Row>
               {errorMessage && <Flag type="error">{errorMessage}</Flag>}
            </>
         )}
         {!renewable && (
            <>
               <Content>
                  {t('By enabling the auto renewal, you acknowledge that your subscription will be renewed annually.')}
               </Content>
               <Row>
                  <PositiveConfirm loading={loading} onClick={switchRenewal}>
                     {t('Confirm')}
                  </PositiveConfirm>
                  <PositiveClose onClick={() => closeModal('autoRenewal')}>{t('Cancel')}</PositiveClose>
               </Row>
               {errorMessage && <Flag type="error">{errorMessage}</Flag>}
            </>
         )}
      </div>
   );
};

export default styled(AutoRenewal)`
   width: calc(100vw - 100px);
   max-width: 730px;
   padding: 45px;
   display: flex;
   flex-direction: column;
   row-gap: 25px;

   ${Row} {
      display: flex;
      column-gap: 10px;
   }

   ${Content} {
      line-height: 1.5;
   }
`;
