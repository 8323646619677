import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import symbol from './LogoSymbol.svg';
import symbolLight from './LogoSymbolLight.svg';
import { Link } from 'react-router-dom';

type Props = Stylable & {
   variant?: 'light' | 'dark';
};

const Logo: React.FC<Props> = (props) => {
   const { className, variant = 'dark' } = props;
   return (
      <div className={className}>
         <Link to="/">
            <img src={variant === 'light' ? symbolLight : symbol} alt="Depicter" />
         </Link>
      </div>
   );
};

export default styled(Logo)``;
