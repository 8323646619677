import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkTailSpin from '@loadings/loadings/tailSpin/dark.html';
import lightTailSpin from '@loadings/loadings/tailSpin/light.html';
import LoadingSymbol from './LoadingSymbol';

const TailSpin = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkTailSpin} light={lightTailSpin} />;

export default styled(TailSpin)``;
