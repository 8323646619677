import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import TooltipWrap, { TooltipWrapProps } from './TooltipWrap';
import { tooltipWrap, TooltipWrapColors, TooltipWrapSizes } from './theme';

type StyledTooltipWrapProps = WithThemeProps & TooltipWrapProps;

const theme = withTheme<StyledTooltipWrapProps>(tooltipWrap);

export default styled(TooltipWrap)`
   /* Tooltip Wrapper */
   + div {
      &:after,
      &:before {
         display: none;
      }
   }

   ${theme.colors(
      (c: TooltipWrapColors) => css`
         + div {
            background: ${c.background} !important;
            border: 1px solid ${c.border};
            box-shadow: 2px 10px 25px ${c.shadow};
            color: ${c.color} !important;
         }
      `
   )}

   ${theme.sizes(
      (s: TooltipWrapSizes) => css`
         + div {
            border-radius: ${s.border};
         }
      `
   )}
`;
