import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: '#FFFFFF',
   border: '#E6E6E6',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
