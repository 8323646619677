import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   color: '#cacaca',
};

const lightColors = {
   color: '#cacaca',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: lightColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
