import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: 'rgba(0, 0, 0, 0.8)',
   label: '#AAAAAA',
};

const lightColors = {
   background: '#f9f9fb',
   label: '#AAAAAA',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: lightColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   blur: '10px',
   label: {
      fontSize: '15px',
   },
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
