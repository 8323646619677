import { useAuthentication } from '@avtkit/helpers/Authentication';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserData } from '../API/types';
import { useUserPanelData } from '../contexts/UserPanelData';

export const useLogin = (rememberLogin: boolean = true) => {
   const { setState: setUserPanelData, state: userPanelData } = useUserPanelData();
   const { setToken, redirectPath } = useAuthentication();
   const navigate = useNavigate();

   const doLogin = useCallback(
      ({ token, firstName, lastName, email }: UserData) => {
         if (token) {
            setToken(token, { firstName, lastName, email }, rememberLogin);
            setUserPanelData({ ...userPanelData, user: { firstName, lastName, email } });

            navigate(redirectPath || '/');
         }
      },
      [navigate, redirectPath, rememberLogin, setToken, setUserPanelData, userPanelData]
   );

   return doLogin;
};

export const useLogout = () => {
   const { removeToken } = useAuthentication();

   return useCallback(() => {
      removeToken();
      window.location.reload();
   }, [removeToken]);
};
