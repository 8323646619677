import { Stylable } from '@avtkit/types/commons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PrivacyPolicyNotice from '../../components/PrivacyPolicyNotice';
import Separator from '@avtkit/controls/Separator';
import { Content, PageTitle } from '../../components/Typography';
// import { GoogleLogin } from '@avtkit/controls/GoogleLogin';
import RegisterForm from './RegisterForm';
import { RouterLink } from '@avtkit/controls/Link';
import { JWTGoogleLogin } from '@avtkit/controls/GoogleLogin';
import { api, axios } from '../../API';
import { useLogin } from '../../hooks/useLogin';

type Props = Stylable;

const Register = (props: Props) => {
   const { className } = props;
   const { t } = useTranslation();
   const doLogin = useLogin(true);

   return (
      <div className={className}>
         <PageTitle>{t('Register')}</PageTitle>
         <Content>
            {t('Have an account?')} <RouterLink to="/auth/login">{t('Login')}</RouterLink>
         </Content>
         <JWTGoogleLogin request={api.loginByGoogle} axios={axios} onLogin={doLogin}>
            {t('Register by Google')}
         </JWTGoogleLogin>
         <Separator>{t('OR')}</Separator>
         <RegisterForm onLogin={doLogin} />
         <PrivacyPolicyNotice />
      </div>
   );
};

export default styled(Register)`
   ${PageTitle} {
      font-weight: 400;
      margin-bottom: 20px;
   }

   ${Content} {
      margin-bottom: 25px;
   }

   ${Separator} {
      margin-top: 20px;
      margin-bottom: 30px;
   }

   ${PrivacyPolicyNotice} {
      margin-top: 20px;
   }
`;
