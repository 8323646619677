import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   color: '#FFF',
   iconColor: '#FFF',
   shadow: 'rgba(0, 0, 0, 0.25)',
   success: {
      accent: '#01D06F',
      bg: '#22222',
   },
   warning: {
      accent: '#FFB913',
      bg: '#22222',
   },
   info: {
      accent: '#7361E8',
      bg: '#22222',
   },
   error: {
      accent: '#FF504A',
      bg: '#22222',
   },
   custom: {
      accent: '#000000',
      bg: '#22222',
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: {
      color: '#000000',
      iconColor: '#FFF',
      shadow: 'rgba(0, 0, 0, 0)',
      success: {
         accent: '#01D06F',
         bg: 'rgba(1, 208, 111, 0.1)',
      },
      warning: {
         accent: '#FFB913',
         bg: 'rgba(255, 185, 19, 0.1)',
      },
      info: {
         accent: '#7361E8',
         bg: 'rgba(115, 97, 232, 0.1)',
      },
      error: {
         accent: '#FF504A',
         bg: 'rgba(255, 80, 74, 0.1)',
      },
      custom: {
         accent: '#000000',
         bg: '#22222',
      },
   },
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   width: '320px',
   border: '5px',
   padding: '10px',
   distance: '15px',
   title: {
      size: '12px',
      weight: '600',
   },
   content: {
      size: '12px',
      weight: '400',
   },
   top: '50px',
   left: '50px',
   right: '50px',
   bottom: '50px',
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
