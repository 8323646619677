import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   row: {
      even: {
         background: '#FFFFFF',
         color: '#000000',
      },
      odd: {
         background: '#F5F5F5',
         color: '#000000',
      },
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
};

const defaultSizing = {
   cell: {
      fontSize: '16px',
      fontWeight: '400',
      padding: '10px 15px',
   },
   heading: {
      fontSize: '18px',
      fontWeight: '700',
      padding: '9px 15px',
   },
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
};
