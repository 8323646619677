import { Stylable, WithChildren } from '@/types/commons';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, LinkProps } from 'react-router-dom';
import * as scheme from './theme';

export type VerticalMenuItemProps = Stylable &
   WithChildren &
   WithThemeProps & {
      icon?: React.ReactNode;
      badge?: string;
      disabled?: boolean;
      onClick?: () => void;
      selected?: boolean;
      routeLink?: LinkProps;
   };

const IconHolder = styled.div``;
const BadgeHolder = styled.div``;

const theme = withTheme<VerticalMenuItemProps>(scheme);

const VerticalMenuItem: React.FC<VerticalMenuItemProps> = (props) => {
   const { className, icon, children, onClick, disabled, badge, routeLink } = props;

   const content = (
      <>
         {icon && <IconHolder>{icon}</IconHolder>}
         {children}
         {badge && <BadgeHolder>{badge}</BadgeHolder>}
      </>
   );

   return routeLink ? (
      <NavLink
         className={({ isActive }) => className + (isActive ? ' dp-menu-item-selected' : '')}
         onClick={disabled ? undefined : onClick}
         {...routeLink}
      >
         {content}
      </NavLink>
   ) : (
      <div className={className} onClick={disabled ? undefined : onClick}>
         {content}
      </div>
   );
};

export default styled(VerticalMenuItem)`
   display: flex;
   flex-direction: row;
   align-items: center;
   transition: background-color 300ms, color 300ms;
   text-decoration: none;

   ${({ disabled }) => css`
      cursor: ${!disabled ? 'pointer' : 'auto'};
   `}

   ${IconHolder} {
      svg {
         display: block;
         fill: currentColor;
         transition: fill 300ms;
      }
   }

   ${BadgeHolder} {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 10em;
      text-transform: uppercase;
      line-height: 100%;
      font-weight: 600;
      position: relative;
      top: 2px;
   }

   ${theme.colors(
      (c: scheme.Colors, props) => css`
         color: ${props.selected ? c.item.selected.color : c.item.color};
         ${props.selected ? `background-color: ${c.item.selected.background};` : ''}

         &.dp-menu-item-selected {
            color: ${c.item.selected.color};
            background-color: ${c.item.selected.background};
         }

         ${!props.disabled
            ? css`
                 &:hover {
                    color: ${c.item.hover.color};
                    background-color: ${c.item.hover.background};
                 }
              `
            : ''}

         ${BadgeHolder} {
            color: ${c.item.badge.color};
            background-color: ${c.item.badge.background};
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         ${IconHolder} {
            margin-right: ${s.item.iconGap};
         }

         border-radius: ${s.item.borderRadius};
         padding: ${s.item.padding};
         height: ${s.item.height};
         font-size: ${s.item.fontSize};

         ${BadgeHolder} {
            height: ${s.item.badge.height};
            padding: ${s.item.badge.padding};
            margin-left: ${s.item.badge.gap};
            font-size: ${s.item.badge.fontSize};
         }
      `
   )}
`;
