import { useCallback, useContext, useEffect, useState } from 'react';
import ModalContext, { ModalStack, ModalContextType } from './components/ModalContext';
import { uniqBy } from 'lodash';

export const useModal = () => {
   const { modalsStack, setModalsStack } = useContext<ModalContextType>(ModalContext);
   const [activeModal, setActiveModal] = useState<ModalStack>(modalsStack[0] || { id: '', options: {} });

   useEffect(() => {
      setActiveModal(modalsStack[0] || { id: '', options: {} });
   }, [modalsStack]);

   const closeModal = useCallback(
      (id: string) => {
         setModalsStack((previousStacks) => {
            const clone = [...previousStacks];
            const index = clone.findIndex((m) => m.id === id);
            if (index >= 0) {
               clone.splice(index, 1);
            }

            return clone;
         });
      },
      [setModalsStack]
   );

   const addToModalsStack = useCallback(
      (newStacks: ModalStack[], removeDuplicates: boolean = true) => {
         setModalsStack((previousStacks) =>
            removeDuplicates ? uniqBy([...previousStacks, ...newStacks], 'id') : [...previousStacks, ...newStacks]
         );
      },
      [setModalsStack]
   );

   return {
      activeModal,
      closeModal,
      addToModalsStack,
   };
};
