import React, { useRef } from 'react';
import { TooltipProps } from 'react-tooltip';
import { nanoid } from 'nanoid';
import Tooltip from './Tooltip';

export type TooltipWrapProps = {
   tooltip: string | React.ReactNode;
   tooltipProps?: TooltipProps;
   children?: React.ReactNode;
   className?: string;
};

const TooltipWrap: React.FC<TooltipWrapProps> = (props: TooltipWrapProps) => {
   const { tooltipProps, children, tooltip, className } = props;

   const ref = useRef({
      tooltipID: nanoid(),
   });

   const addTooltip = !!tooltipProps;
   const tooltipFor = addTooltip ? ref.current.tooltipID : undefined;
   const elementProps = {
      'data-for': tooltipFor,
      'data-tip': !addTooltip && typeof tooltip === 'string' ? tooltip : '',
      'data-effect': 'solid',
      className,
   };

   if (tooltipProps) {
      tooltipProps.id = tooltipFor;
   }

   return (
      <>
         <div {...elementProps}>{children}</div>
         {addTooltip && <Tooltip {...tooltipProps}>{tooltip}</Tooltip>}
      </>
   );
};

export default TooltipWrap;
