import { Stylable } from '@/types/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Content, PageTitle } from '../../../components/Typography';
import NoSubscriptionImage from './NoSubscriptionImage.png';

import ImageBox from '@avtkit/controls/ImageBox';
import { NewSubscriptionButton } from '../../../components/CommonButtons';
import { MOBILE_BP } from '../../../constants';
import PremiumFeatures from './PremiumFeatures';

const ContentContainer = styled.div``;

const NoSubscription: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <div className={className}>
         <ContentContainer>
            <PageTitle>{t("You don't have any Depicter Pro subscription yet.")}</PageTitle>
            <Content>
               {t(
                  'Get a subscription and activate your website to achieve more possibilities and ease your work with Depicter Pro.'
               )}
            </Content>
            <PremiumFeatures />
            <NewSubscriptionButton />
         </ContentContainer>
         <ImageBox src={NoSubscriptionImage} />
      </div>
   );
};

export default styled(NoSubscription)`
   position: relative;
   width: 100%;

   display: flex;
   gap: 20px;
   align-items: center;

   ${ContentContainer} {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
   }

   ${NewSubscriptionButton} {
      max-width: 240px;
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      flex-direction: column;

      ${ImageBox} {
         order: 1;
      }

      ${ContentContainer} {
         order: 2;
         margin-bottom: 45px;
      }
   }
`;
