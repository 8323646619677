import { Stylable } from '@avtkit/types/commons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/Typography';
import { Form, FormInput as Input, FormLabel, FormSubmitButton } from '@avtkit/controls/Form';
import { eightCharPass } from '@avtkit/controls/Form/helpers/presetPatterns';
import { useSubmitForm } from '@avtkit/hooks/useSubmitForm';
import { api, axios } from '../../API';
import { Flag } from '@avtkit/controls/Flags';

type Props = Stylable;

const Row = styled.div``;
const SaveButton = styled(FormSubmitButton).attrs({
   size: 'medium',
   variant: 'round',
})``;
const Empty = styled.div``;

const UserForgotPasswordCard: React.FC<Props> = (props) => {
   const { className } = props;
   const { t } = useTranslation();
   const [success, setSuccess] = useState<boolean>(false);

   const onSuccess = useCallback((data) => {
      if (data.success) {
         setSuccess(true);
      }
   }, []);

   const { loading, errorMessage, submit } = useSubmitForm(api.changePassword, onSuccess, axios, () =>
      setSuccess(false)
   );
   return (
      <Card className={className}>
         <CardTitle>{t('Change password')}</CardTitle>
         <Form onSubmit={submit}>
            <Row>
               <FormLabel htmlFor="current">{t('Current password')}</FormLabel>
               <Input
                  type="password"
                  id="current"
                  validationMessage={t('Please enter your current password')}
                  required
                  validationOn="submit"
                  size={32}
                  name="newPassword"
               />
            </Row>

            <Row>
               <FormLabel htmlFor="new">{t('New password')}</FormLabel>
               <Input
                  type="password"
                  required
                  id="new"
                  validationOn="submit"
                  validationMessage={t(
                     'Password should be minimum eight characters, at least one letter and one number'
                  )}
                  pattern={eightCharPass}
                  size={32}
                  name="newPassword"
               />
            </Row>

            <Row>
               <Empty />
               <SaveButton loading={loading}>{t('Change')}</SaveButton>
            </Row>
            {errorMessage && <Flag type="error">{errorMessage}</Flag>}
            {success && <Flag type="success">{t('Changed successfully.')}</Flag>}
         </Form>
      </Card>
   );
};

export default styled(UserForgotPasswordCard)`
   ${Row} {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      ${FormLabel}, ${Empty} {
         width: 140px;
         text-align: right;
         margin-right: 15px;
      }
   }

   ${SaveButton} {
      flex-shrink: 0;
   }

   ${Form} {
      margin-top: 43px;
   }

   ${Input} {
      max-width: 260px;
   }
`;
