import StaticAxios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import React, { useCallback, useContext, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { RemoteDataType } from './types';

export type RemoteDataRequestOptions = {
   useStatus?: boolean;
};

export const useRemoteDataRequest = <T>(
   context: React.Context<RemoteDataType<T>>,
   axios: AxiosInstance = StaticAxios,
   { useStatus = true }: RemoteDataRequestOptions = {}
) => {
   const { setState, setStatus } = useContext(context);

   const request = useCallback(
      (requestConfig: AxiosRequestConfig, onComplete?: (data: T) => void, filterResponse?: (data: T) => any) => {
         const tokenSource = StaticAxios.CancelToken.source();

         if (useStatus) {
            setStatus('loading');
         }
         axios
            .request({ ...requestConfig, cancelToken: tokenSource.token })
            .then((value) => {
               const response = filterResponse?.(value.data) || value.data;

               unstable_batchedUpdates(() => {
                  setState(response);
                  if (useStatus) {
                     setStatus('loaded');
                  }
               });

               onComplete?.(response);
            })
            .catch((e) => {
               console.error(e);
            });

         return () => tokenSource.cancel();
      },
      [axios, setState, setStatus, useStatus]
   );

   return request;
};

export const useSendRequest = (request: Function, config: AxiosRequestConfig) =>
   useEffect(() => request(config), [request, config]);
