import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';

export const useSubmitForm = (
   request: (data: FormData) => AxiosRequestConfig,
   success: (data: any, form: HTMLFormElement) => void,
   axios: AxiosInstance = Axios,
   onSubmit?: () => void
) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [errorMessage, setErrorMessage] = useState<string>('');
   const [succeed, setSucceed] = useState<boolean>(false);

   const submit = useCallback(
      (event) => {
         event.preventDefault();
         if (event.target.checkValidity()) {
            setLoading(true);
            setSucceed(false);
            setErrorMessage('');
            axios
               .request(request(new FormData(event.target)))
               .then(({ data }) => {
                  setSucceed(true);
                  success(data, event.target);
               })
               .catch((error: AxiosError) => {
                  setErrorMessage(error.response?.data?.errors?.[0] || error.message);
               })
               .finally(() => setLoading(false));
         }
         onSubmit?.();
      },
      [axios, onSubmit, request, success]
   );

   return { loading, errorMessage, submit, succeed };
};
