import React from 'react';
import styled, { css } from 'styled-components';
import { MenuItem, applyStatics } from '@szhsin/react-menu';
import { MenuItemProps } from '../types';
import { withTheme } from '@avtkit/helpers/withTheme';
import * as scheme from '../theme';
import Label from '@avtkit/controls/Label';

const Shortcut = styled(Label)`
   margin-bottom: 0;
   margin-left: 5px;
`;

const StyledMenuItem = ({ shortcut, children, disabled, ...props }: MenuItemProps) => (
   <MenuItem disabled={disabled} {...props}>
      {children}
      {!disabled && <Shortcut>{shortcut}</Shortcut>}
   </MenuItem>
);

const theme = withTheme<MenuItemProps>(scheme);

applyStatics(MenuItem)(StyledMenuItem);

export default styled(StyledMenuItem)`
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-weight: 400;

   &.szh-menu__item--checked {
      position: relative;

      &:after {
         content: '';
         display: inline-block;
         width: 6px;
         height: 5px;
         margin-left: 10px;
         transform: translateY(-50%);
         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%0Awidth='7.071'%0Aheight='5.657'%0A%3E%3Cg fill='%23fff'%3E%3Cpath data-name='Rectangle 1868' d='m0 3.535.707-.707 1.414 1.414-.707.707z' /%3E%3Cpath data-name='Rectangle 1869' d='m1.414 4.949 4.95-4.95.707.707-4.95 4.95z' /%3E%3C/g%3E%3C/svg%3E");
      }
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         color: ${c.color};

         &.szh-menu__item--hover {
            background-color: ${c.hoverBg};
            color: ${c.hoverText};
         }

         &.szh-menu__item--disabled {
            color: ${c.disabledColor};
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes, { size }: MenuItemProps) => css`
         font-size: ${s[size].fontSize};
         padding: ${s[size].padding};
      `
   )}
`;
