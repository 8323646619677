import { OutlineButton } from '@avtkit/controls/Button';
import { FormInput, FormSubmitButton } from '@avtkit/controls/Form';
import { CountriesDropdown } from '@avtkit/controls/CountriesDropdown';
import { GenericObject, Stylable } from '@avtkit/types/commons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BillingInfo } from '../../API/types';

import Label from '../../components/Label';
import { CardTitle } from '../../components/Typography';
import { useSubmitForm } from '@avtkit/hooks/useSubmitForm';
import { api, axios } from '../../API';
import { Flag } from '@avtkit/controls/Flags';
import { useSingleSubscription } from '../../contexts/SingleSubscriptionData';
import { Dropdown } from '@avtkit/controls/Dropdown';
import i18n from '../../services/i18n';

type Props = Stylable & BillingInfo & {};

const EditButton = styled(OutlineButton).attrs({ variant: 'round', size: 'small' })``;
const SaveButton = styled(FormSubmitButton).attrs({ size: 'small', variant: 'round' })``;
const Header = styled.div``;
const Content = styled.div``;
const Row = styled.div``;
const Input = styled(FormInput).attrs({ variant: 'medium', type: 'text' })``;
const OptionalLabel = styled.span.attrs({ children: i18n.t('Optional') })`
   font-weight: 500;
   font-size: 14px;
   color: #828282;
`;

const BillingInfoModal: React.FC<Props> = (props) => {
   const { className, ...formData } = props;
   const { t } = useTranslation();
   const { state: subscription, setState: updateSubscription } = useSingleSubscription();
   const [editingMode, setEditingMode] = useState<boolean>(false);

   const onSuccess = useCallback(
      (_data, form: HTMLFormElement) => {
         const newData: GenericObject = {};
         new FormData(form).forEach((value, key) => {
            newData[key] = value;
         });

         updateSubscription({ ...subscription, billingInfo: { ...subscription.billingInfo, ...newData } });
         setEditingMode(false);
      },
      [subscription, updateSubscription]
   );

   const { errorMessage, submit, loading, succeed } = useSubmitForm(api.updateBillingInfo, onSuccess, axios);

   return (
      <div className={className}>
         <form onSubmit={submit}>
            <Header>
               <CardTitle>{t('Billing info')}</CardTitle>
               {editingMode ? (
                  <SaveButton loading={loading}>{t('Save')}</SaveButton>
               ) : (
                  <EditButton onClick={() => setEditingMode(true)}>{t('Edit')}</EditButton>
               )}
            </Header>
            {errorMessage && <Flag type="error">{errorMessage}</Flag>}
            {succeed && <Flag type="success">{t('Saved successfully.')}</Flag>}
            <Content>
               <input type="hidden" name="id" value={subscription.id} />
               <Row>
                  <Label>{t('First name')}:</Label>
                  <Input
                     disabled={!editingMode}
                     validationOn="blur"
                     size="32"
                     required
                     name="firstName"
                     defaultValue={formData.firstName}
                  />
               </Row>
               <Row>
                  <Label>{t('Last name')}:</Label>
                  <Input
                     disabled={!editingMode}
                     validationOn="blur"
                     size="32"
                     required
                     name="lastName"
                     defaultValue={formData.lastName}
                  />
               </Row>
               <Row>
                  <Label>{t('Email')}:</Label>
                  <Input
                     required
                     validationOn="blur"
                     size="32"
                     name="email"
                     defaultValue={formData.email}
                     disabled={!editingMode}
                  />
               </Row>
               <Row>
                  <Label>{t('Country')}:</Label>
                  <CountriesDropdown
                     size="medium"
                     disabled={!editingMode}
                     name="country"
                     defaultValue={formData.country}
                  />
               </Row>
               <Row>
                  <Label>{t('State')}:</Label>
                  <Input
                     disabled={!editingMode}
                     validationOn="blur"
                     size="32"
                     name="state"
                     defaultValue={formData.state}
                  />
                  <OptionalLabel />
               </Row>
               <Row>
                  <Label>{t('City')}:</Label>
                  <Input
                     disabled={!editingMode}
                     validationOn="blur"
                     size="32"
                     name="city"
                     defaultValue={formData.city}
                  />
                  <OptionalLabel />
               </Row>
               <Row>
                  <Label>{t('Address')}:</Label>
                  <Input
                     disabled={!editingMode}
                     validationOn="blur"
                     size="50"
                     name="address"
                     defaultValue={formData.address}
                  />
                  <OptionalLabel />
               </Row>
               <Row>
                  <Label>{t('Postal/Zip code')}:</Label>
                  <Input
                     disabled={!editingMode}
                     validationOn="blur"
                     size="50"
                     required
                     name="postCode"
                     defaultValue={formData.postCode}
                  />
               </Row>
               <Row>
                  <Label>{t('Company name')}:</Label>
                  <Input
                     disabled={!editingMode}
                     validationOn="blur"
                     size="50"
                     name="company"
                     defaultValue={formData.company}
                  />
               </Row>
            </Content>
         </form>
      </div>
   );
};

export default styled(BillingInfoModal)`
   width: calc(100vw - 100px);
   max-width: 730px;
   padding: 45px;

   ${Header} {
      display: flex;
      margin-bottom: 25px;
   }

   ${CardTitle} {
      margin-right: 20px;
   }

   ${Row} {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      column-gap: 15px;
      ${Label} {
         width: 130px;
         text-align: right;
      }
   }

   ${Dropdown} {
      width: 267px;
   }
`;
