import { Stylable } from '@avtkit/types/commons';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = Stylable & {
   iconSrc?: string;
   label?: string;
   hideLabelOn?: number;
};

const Icon = styled.img`
   max-width: 52px;
   width: 30%;
`;

const NoticeOverlay: React.FC<Props> = (props) => {
   const { className, iconSrc, label, hideLabelOn = 100 } = props;
   const [hideLabel, setHideLabel] = useState<boolean>(false);
   const ref = useRef<HTMLDivElement | null>(null);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   useEffect(() => {
      if (ref.current && label) {
         const hide = hideLabelOn >= ref.current.clientWidth;
         if (hide !== hideLabel) setHideLabel(hide);
      }
   });

   return (
      <div ref={ref} className={className}>
         {iconSrc && <Icon src={iconSrc} alt="" />}
         {label && !hideLabel && <span>{label}</span>}
      </div>
   );
};

export default styled(NoticeOverlay)`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   min-width: 50px;
   background: rgba(0, 0, 0, 0.34);
   color: white;
   align-items: center;
   justify-content: center;
   font-size: 16px;
   font-weight: 500;
   text-align: center;

   span {
      margin-top: 15px;
   }
`;
