import { useCallback, useEffect, useState } from 'react';
import browserEnv from '@avtkit/browser.env';
import { useModal } from '../ModalsManager/useModal';

const useOpenChangelogs = () => {
   const { addToModalsStack } = useModal();
   const [open, setOpen] = useState(false);

   useEffect(() => {
      const changelogLastSeen = localStorage.getItem('dpChangelogLastSeen');
      if (browserEnv.updateInfo.from !== null && changelogLastSeen !== browserEnv.updateInfo.to) {
         localStorage.setItem('dpChangelogLastSeen', browserEnv.updateInfo.to);
         setOpen(true);
      } else {
         setOpen(false);
      }
   }, [addToModalsStack]);

   const openChangelog = useCallback(
      (force?: boolean) => {
         if (open || force) {
            addToModalsStack([
               {
                  id: 'changelog',
                  options: { from: browserEnv.updateInfo.from, to: browserEnv.updateInfo.to },
               },
            ]);
         }
      },
      [open, addToModalsStack]
   );

   return openChangelog;
};

export default useOpenChangelogs;
