import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: '#FAFAFA',
   symbolArea: '#0034C7',
   contentArea: '#FFFFFF',
   border: '#E6E6E6',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   wrapper: {
      desktop: '1000px',
      tablet: '800px',
      mobile: '450px',
   },
   content: {
      desktop: '600px',
      tablet: '450px',
      mobile: '100%',
   },
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
