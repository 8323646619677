import { Stylable } from '@/types/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLinkClickHandler, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NeedHelpButton, PageBackButton, PrimaryUpgradeButton } from '../../components/CommonButtons';
import PageLoading from '../../components/PageLoading';
import { PageTitle } from '../../components/Typography';
import { MOBILE_BP } from '../../constants';
import {
   SingleSubscriptionContext,
   SingleSubscriptionProvider,
   SingleSubscriptionRemoteDataRequest,
} from '../../contexts/SingleSubscriptionData';
import PageContent from './PageContent';

const ButtonsContainer = styled.div``;
const Header = styled.div``;

const MySubscriptions: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();
   const { id } = useParams();
   const back = useLinkClickHandler('/');

   return (
      <div className={className}>
         <SingleSubscriptionProvider>
            <SingleSubscriptionRemoteDataRequest id={id!} />
            <Header>
               <PageBackButton onClick={back}>{t('Back to My Subscriptions')}</PageBackButton>
               <ButtonsContainer>
                  <NeedHelpButton />
                  <PrimaryUpgradeButton />
               </ButtonsContainer>
            </Header>
            <PageLoading dataContext={SingleSubscriptionContext}>
               <PageContent />
            </PageLoading>
         </SingleSubscriptionProvider>
      </div>
   );
};

export default styled(MySubscriptions)`
   ${Header} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 40px;
   }

   ${ButtonsContainer} {
      display: flex;
   }

   ${NeedHelpButton} {
      margin-right: 20px;
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      ${Header} {
         flex-wrap: wrap;
         ${PageTitle} {
            width: 100%;
            margin-bottom: 30px;
         }
      }
      ${NeedHelpButton} {
         margin-right: 10px;
      }

      ${ButtonsContainer} {
         margin-top: 20px;
      }
   }
`;
