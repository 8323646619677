import React from 'react';

export type OffCanvasContextType = {
   offCanvasStacks: OffCanvasStack[];
   setOffCanvasStacks: React.Dispatch<React.SetStateAction<OffCanvasStack[]>>;
};

export type OffCanvasStack = {
   id: string;
   options?: { [key: string]: any };
};

export default React.createContext<OffCanvasContextType>({
   offCanvasStacks: [],
   setOffCanvasStacks: () => {},
});
