import { Stylable } from '@/types/commons';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { WithChildren } from '@avtkit/types/commons';
import React from 'react';
import styled, { css } from 'styled-components';
import * as scheme from './theme';

export type CardProps = Stylable &
   WithChildren &
   WithThemeProps & {
      onClick?: (e: React.MouseEvent<any, any>) => void;
   };

const Card: React.FC<CardProps> = (props) => <section {...props} />;

const theme = withTheme<CardProps>(scheme);
export default styled(Card)`
   transition: 300ms box-shadow;

   ${({ onClick }) => css`
      cursor: ${onClick ? 'pointer' : 'auto'};
   `}

   ${theme.colors(
      (c: scheme.Colors, { onClick }) => css`
         background-color: ${c.background};
         box-shadow: 0 0 0 1px ${c.border} inset;

         ${onClick
            ? css`
                 &:hover {
                    box-shadow: 0 0 0 2px ${c.hover.border} inset;
                 }
              `
            : ''}
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         padding: ${s.padding};
         margin-bottom: ${s.belowSpace};
         border-radius: ${s.borderRadius};
      `
   )}
`;
