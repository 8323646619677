import styled from 'styled-components';
import { MOBILE_BP } from '../constants';

export const PageTitle = styled.h2`
   font-weight: 600;
   font-size: 32px;
   line-height: 38px;

   @media screen and (max-width: ${MOBILE_BP}) {
      font-size: 28px;
   }
`;

export const CardTitle = styled.h3`
   font-weight: 700;
   font-size: 22px;
   line-height: 26px;

   @media screen and (max-width: ${MOBILE_BP}) {
      font-size: 18px;
   }
`;

export const Content = styled.div`
   font-weight: 400;
   font-size: 16px;
   line-height: 1.5;

   @media screen and (max-width: ${MOBILE_BP}) {
      font-size: 14px;
   }
`;
