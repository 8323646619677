import React, { useCallback } from 'react';
import styled from 'styled-components';
import SubscriptionCard, { SubscriptionCardProps } from '../../features/SubscriptionCard';
import ActivateButton from './ActivateButton';
import { useModal } from '@avtkit/controls/ModalsManager/useModal';

const ActiveSubscriptionItem: React.FC<SubscriptionCardProps> = (props) => {
   const { addToModalsStack } = useModal();

   const openModalHandler = useCallback(() => {
      addToModalsStack([{ id: 'activeSubscription', options: props }]);
   }, [props, addToModalsStack]);

   const buttons = (
      <>
         <ActivateButton onClick={openModalHandler} />
      </>
   );

   return <SubscriptionCard onClick={openModalHandler} buttons={buttons} {...props} />;
};

export default styled(ActiveSubscriptionItem)``;
