import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export const i18nInit = i18n
   .use(Backend)
   .use(initReactI18next)
   .init({
      debug: false,
      fallbackLng: 'en',
      lng: 'en',
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
         escapeValue: false, // react already safes from xss
      },
      react: {
         useSuspense: false,
      },
      // backend: {
      //    loadPath: `${browserEnv.membersAreaAPI}?action=depicter/document/localization?depicter-csrf=${browserEnv.csrfToken}`,
      // },
   });

export default i18n;
