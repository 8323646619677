import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { DotSpinner } from '@avtkit/controls/Loading';
import Label from '@avtkit/controls/Label';
import { LoadingSplashProps } from './types';
import * as scheme from './theme';
import AppearWrap from '../AppearWrap';

const LoadingContainer = styled(AppearWrap)``;
const ContentWrapper = styled(AppearWrap)``;

const LoadingSplash = (props: LoadingSplashProps) => {
   const { className, visible = false, label, overlay, children, loadingSize = 'medium' } = props;
   return (
      <div className={className}>
         <LoadingContainer renderOnAppear appeared={visible}>
            {label && <Label>{label}</Label>}
            <DotSpinner size={loadingSize} />
         </LoadingContainer>
         <ContentWrapper renderOnAppear={!overlay} appeared={!visible}>
            {children}
         </ContentWrapper>
      </div>
   );
};

const theme = withTheme<LoadingSplashProps>(scheme);

LoadingSplash.defaultProps = {
   background: false,
};

export default styled(LoadingSplash)`
   position: relative;
   width: 100%;
   height: 100%;

   ${LoadingContainer} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: inherit;
      z-index: 3;

      ${Label} {
         margin-bottom: 30px;
      }
   }

   ${theme.colors(
      (c: scheme.Colors, { background }) => css`
         background-color: ${background ? c.background : 'inherit'};

         ${LoadingContainer} ${Label} {
            color: ${c.label};
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes, { overlay, blur }) => css`
         ${LoadingContainer} {
            ${overlay &&
            blur &&
            css`
               backdrop-filter: blur(${s.blur});
            `}

            ${Label} {
               font-size: ${s.label.fontSize};
            }
         }
      `
   )}
`;
