import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { useTranslation } from 'react-i18next';
import Lock from '@avtkit/controls/Icon/shapes/Lock.svgr';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import ButtonLogic from './ButtonLogic';
import { ButtonProps } from '../types';
import { ActivateButtonColors, SolidButtonSizes, activateButton, solidButton } from '../theme';

type ActivateButtonProps = ButtonProps &
   WithThemeProps & {
      size: 'tiny' | 'large' | 'medium' | 'small';
   };

const ActivateButton: React.FC<ActivateButtonProps> = (props) => {
   const { t } = useTranslation();
   return (
      <ButtonLogic {...props} icon={<Lock />} iconAlign="left">
         {t('Not Activated')}
      </ButtonLogic>
   );
};
const theme = withTheme<ActivateButtonProps>(activateButton);
const solidTheme = withTheme<ActivateButtonProps>(solidButton);

const StyledActivateButton = styled(ActivateButton)<ActivateButtonProps>`
   appearance: none;
   transition: background-color 300ms, color 300ms, border-color 300ms;
   display: flex;
   border: none;
   align-items: center;
   justify-content: space-around;
   cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
   opacity: ${(props) => (!props.disabled ? '1' : '0.3')};

   svg {
      margin-right: 8px;
      display: block;
      transition: fill 300ms;
   }

   ${theme.colors(
      (c: ActivateButtonColors, props) => css`
         color: ${c.color};
         background-color: ${c.background};

         svg {
            fill: ${c.icon} !important;
         }

         ${!props.disabled &&
         css`
            &:hover {
               color: ${c.hover.color};
               background-color: ${c.hover.background};
               svg {
                  fill: ${c.hover.icon} !important;
               }
            }
         `}
      `
   )}

   ${solidTheme.sizes(
      (s: SolidButtonSizes, { size }) => css`
         border-radius: ${s[size].borderRadius};
         padding: ${s[size].padding};
         height: ${s[size].height};
         font-size: ${s[size].fontSize};
         border-width: ${s[size].border};
      `
   )}
`;

export default withDefaultProps(StyledActivateButton, {
   size: 'small',
});
