import React, { forwardRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import * as scheme from './theme';
import { VerticalThumb, HorizontalThumb, HorizontalTrack, VerticalTrack } from './components/RenderedElements';
import { ScrollbarsProps } from './types';
import { Stylable } from '@avtkit/types/commons';
import { CustomScrollView } from './components/CustomScrollbarView';

const renderVerticalThumb = (props: Stylable) => <VerticalThumb {...props} />;
const renderHorizontalThumb = (props: Stylable) => <HorizontalThumb {...props} />;
const renderVerticalTrack = (props: Stylable) => <VerticalTrack {...props} />;
const renderHorizontalTrack = (props: Stylable) => <HorizontalTrack {...props} />;

const CustomScrollbars = forwardRef<Scrollbars, ScrollbarsProps>((props, ref) => (
   <Scrollbars
      ref={ref}
      // @ts-ignore
      renderView={CustomScrollView}
      renderThumbVertical={renderVerticalThumb}
      renderThumbHorizontal={renderHorizontalThumb}
      renderTrackVertical={renderVerticalTrack}
      renderTrackHorizontal={renderHorizontalTrack}
      {...props}
   />
));

const theme = withTheme<ScrollbarsProps>(scheme);

export default styled(CustomScrollbars)`
   ${VerticalThumb},
   ${HorizontalThumb} {
      z-index: 10;
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         ${VerticalThumb},
         ${HorizontalThumb} {
            background-color: ${c.background};

            &:hover,
            &:active {
               background-color: ${c.hover};
            }
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         ${VerticalTrack} {
            width: ${s.width} !important;
            border-radius: ${s.borderRadius};
         }

         ${HorizontalTrack} {
            height: ${s.width} !important;
            border-radius: ${s.borderRadius};
         }
      `
   )}
`;
