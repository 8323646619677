import React, { useEffect, useState } from 'react';
import { ModalProviderProps } from '../types';
import ModalContext, { ModalStack } from './ModalContext';

const ModalsManager = ({ children, defaultStack }: ModalProviderProps) => {
   const [modalsStack, setModalsStack] = useState<ModalStack[]>(defaultStack || []);

   useEffect(() => {
      setModalsStack(defaultStack || []);
   }, [defaultStack]);

   return <ModalContext.Provider value={{ modalsStack, setModalsStack }}>{children}</ModalContext.Provider>;
};

export default ModalsManager;
