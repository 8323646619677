import {
   createRemoteDataContext,
   createRemoteDataProvider,
   useRemoteDataRequest,
} from '@avtkit/helpers/RemoteDataContext';
import { WithChildren } from '@avtkit/types/commons';
import { AxiosRequestConfig } from 'axios';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { api, axios } from '../API';
import { SubscriptionsSet } from '../API/types';

const initialActivation: SubscriptionsSet = {
   items: [],
};

export const ActivationContext = createRemoteDataContext<SubscriptionsSet>();
const DataProvider = createRemoteDataProvider<SubscriptionsSet>(ActivationContext);

export const ActivationProvider: React.FC<WithChildren> = (props) => {
   const { children } = props;
   return <DataProvider initialState={initialActivation}>{children}</DataProvider>;
};

export const useActivation = () => {
   const requestConfig: AxiosRequestConfig = useMemo(() => api.getMySubscriptions({ withVacancy: true }), []);
   const request = useRemoteDataRequest<SubscriptionsSet>(ActivationContext, axios);
   const requestHandler = useCallback(() => request(requestConfig), [requestConfig, request]);
   return { ...useContext(ActivationContext), request: requestHandler };
};

export const ActivationRemoteDataRequest = () => {
   const { request } = useActivation();
   useEffect(() => request(), [request]);
   return null;
};
