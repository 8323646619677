import { Stylable } from '@/types/commons';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import React from 'react';
import styled, { css } from 'styled-components';
import i18n from '../../services/i18n';

import * as scheme from './theme';

type TagProps = Stylable &
   WithThemeProps & {
      variant?: 'active' | 'expires-soon' | 'expired' | 'pending-cancel' | 'cancelled' | 'new' | 'fixed' | 'improved';
   };

const variantLabels = new Map<TagProps['variant'], string>();

variantLabels.set('active', i18n.t('Active'));
variantLabels.set('expires-soon', i18n.t('Expire Soon'));
variantLabels.set('expired', i18n.t('Expired'));
variantLabels.set('pending-cancel', i18n.t('Pending Cancel'));
variantLabels.set('cancelled', i18n.t('Canceled'));
variantLabels.set('new', i18n.t('New'));
variantLabels.set('fixed', i18n.t('Fix'));
variantLabels.set('improved', i18n.t('Improve'));

const Tag: React.FC<TagProps> = (props) => {
   const { className, variant = 'active' } = props;

   return <div className={className}>{variantLabels.get(variant) || variant}</div>;
};

const theme = withTheme<TagProps>(scheme);

export default styled(Tag)`
   height: 25px;
   border: 2px solid;
   border-radius: 6px;
   padding: 4px 14px;
   display: inline-block;
   font-weight: 600;
   font-size: 14px;
   line-height: 100%;

   ${theme.colors(
      (c: scheme.Colors, { variant = 'active' }) => css`
         background-color: ${c.variants[variant]?.bg || 'rgba(0, 0, 0, 0.1)'};
         color: ${c.variants[variant]?.color || 'black'};
         border-color: ${c.variants[variant]?.border || 'black'};
      `
   )}
`;
