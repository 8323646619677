import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { useTranslation } from 'react-i18next';
import Publish from '@avtkit/controls/Icon/shapes/Publish.svgr';
import { SimpleIcon as Icon } from '@avtkit/controls/Icon';
import OutlineButton, { OutlineButtonProps } from './OutlineButton';
import { publishButton, PublishButtonColors } from '../theme';

type PublishedButtonProps = OutlineButtonProps & {
   unpublished?: boolean;
};

const PublishButton: React.FC<PublishedButtonProps> = ({ unpublished, ...props }) => {
   const { t } = useTranslation();

   return (
      <OutlineButton icon={<Icon shape={Publish} />} {...props}>
         {t('Publish')}
      </OutlineButton>
   );
};

const unPublishedKeyframes = keyframes`
   0%{ 
      opacity: 1
   }
   12.5% {
      opacity: 0;
   }
   25%,
   100% {
      opacity: 1;
   }
`;

const theme = withTheme<PublishedButtonProps>(publishButton);
export default styled(PublishButton)<PublishedButtonProps>`
   text-transform: uppercase;

   ${(props) =>
      props.unpublished &&
      css`
         &:after {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-left: 3px;
            animation: ${unPublishedKeyframes} 4s infinite cubic-bezier(0.76, 0, 0.24, 1);
         }
      `}

   ${theme.colors(
      (c: PublishButtonColors, props: PublishedButtonProps) => css`
         ${props.unpublished &&
         css`
            border-color: ${c.unpublished.border};
            background-color: ${c.unpublished.background};
            &:after {
               background: ${c.unpublished.dot};
            }

            &:hover {
               color: ${c.unpublished.hover.color};
               background-color: ${c.unpublished.hover.background};
               border-color: ${c.unpublished.border};

               svg {
                  fill: ${c.unpublished.hover.color} !important;
               }
            }
         `}
      `
   )}
`;
