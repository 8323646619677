import { Form, FormInput, FormSubmitButton } from '@avtkit/controls/Form';
import { Stylable } from '@avtkit/types/commons';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Label from '../components/FormLabel';
import LoginIcon from '../icons/Login.svgr';
import { Flag } from '@avtkit/controls/Flags';
import { eightCharPass } from '../helpers/presetPatterns';

type Props = Stylable & {
   onSubmit: (event: FormEvent<HTMLFormElement>) => void;
   hasError?: boolean;
   errorMessage?: string;
   loading?: boolean;
};

const Row = styled.div``;

const Input = styled(FormInput).attrs({ variant: 'medium' })``;
const RegisterButton = styled(FormSubmitButton).attrs({
   size: 'large',
   variant: 'round',
   icon: <LoginIcon />,
})``;

const RegisterForm = (props: Props) => {
   const { className, hasError, errorMessage, loading, onSubmit } = props;
   const { t } = useTranslation();

   return (
      <Form className={className} onSubmit={onSubmit}>
         <Row>
            <Label htmlFor="firstName">{t('First name')}</Label>
            <Input
               validationMessage={t('First name cannot be empty')}
               type="text"
               required
               validationOn="submit"
               size="32"
               name="firstName"
               autoComplete="given-name"
            />
         </Row>
         <Row>
            <Label htmlFor="lastName">{t('Last name')}</Label>
            <Input
               type="text"
               required
               validationOn="submit"
               size="32"
               name="lastName"
               validationMessage={t('Last name cannot be empty')}
               autoComplete="family-name"
            />
         </Row>

         <Row>
            <Label htmlFor="email">{t('Email')}</Label>
            <Input
               type="email"
               required
               validationOn="submit"
               size="32"
               name="email"
               validationMessage={t('Email address is not valid')}
               autoComplete="username"
            />
         </Row>
         <Row>
            <Label htmlFor="password">{t('Password')}</Label>
            <Input
               type="password"
               pattern={eightCharPass}
               validationMessage={t('Password should be minimum six characters, at least one letter and one number')}
               validation
               required
               validationOn="submit"
               name="password"
               autoComplete="new-password"
            />
         </Row>
         <Row>
            <RegisterButton loading={loading}>{t('Register')}</RegisterButton>
         </Row>
         {hasError && errorMessage && <Flag type="error">{errorMessage}</Flag>}
      </Form>
   );
};

export default styled(RegisterForm)``;
