/* eslint-disable jsx-a11y/anchor-is-valid */
import { Stylable } from '@avtkit/types/commons';
import Table from '@avtkit/controls/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ActiveWebsiteInfo } from '../../API/types';
import { Card } from '../../components/Card';
import { CardTitle, Content } from '../../components/Typography';
import { OutlineButton } from '@avtkit/controls/Button';
import { SimpleLink } from '@avtkit/controls/Link';
import { formatDateAndTime } from '@avtkit/utils/functions';

type Props = Stylable & {
   items: ActiveWebsiteInfo[];
   total: number;
};

const DeactivateButton = styled(OutlineButton).attrs({ size: 'small' })`
   display: inline-block;
`;

const TableContainer = styled.div`
   overflow-x: auto;
`;

const RemainingLabel = styled.span`
   font-weight: 600;
   font-size: 18px;
`;

const RemainingContainer = styled.div``;

const ActiveWebsitesCard: React.FC<Props> = (props) => {
   const { className, items, total } = props;
   const { t } = useTranslation();

   const openHelp = () => {};

   return (
      <Card className={className}>
         <CardTitle>{t('Active websites')}</CardTitle>
         {!!items.length && (
            <>
               <TableContainer>
                  <Table>
                     <thead>
                        <tr>
                           <th>{t('Domain')}</th>
                           <th>{t('Activation date')}</th>
                        </tr>
                     </thead>
                     <tbody>
                        {items.map((item) => (
                           <tr key={item.id}>
                              <td>
                                 <a href={item.url} target="_blank" rel="noreferrer">
                                    {item.domain}
                                 </a>
                              </td>
                              <td>{formatDateAndTime(item.activationDate)}</td>
                              <td>
                                 <DeactivateButton>{t('Deactivate')}</DeactivateButton>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </Table>
               </TableContainer>
               <RemainingContainer>
                  <RemainingLabel>{`${t('Remaining')}: ${total - items.length}`}</RemainingLabel>
                  {/* <SolidButton type="primary" size="small">
                     {t('Upgrade for more')}
                  </SolidButton> */}
               </RemainingContainer>
            </>
         )}
         {!items.length && (
            <Content>
               {t('You have not activated any website on this subscription.')}
               <SimpleLink onClick={openHelp}>{t('Learn how to activate a website.')}</SimpleLink>
            </Content>
         )}
      </Card>
   );
};

export default styled(ActiveWebsitesCard)`
   ${TableContainer} {
      margin-top: 20px;
   }

   ${Table} {
      min-width: 400px;
      overflow-x: auto;

      td:last-child {
         text-align: end;
      }

      a {
         text-decoration: none;
         font-weight: 600;
         color: #2f80ed;
      }
   }

   ${RemainingContainer} {
      display: flex;
      margin-top: 20px;
      align-items: center;
   }

   ${RemainingLabel} {
      margin-right: 10px;
   }

   ${Content} {
      margin-top: 25px;
   }

   ${SimpleLink} {
      display: inline;
      font-weight: inherit;
   }
`;
