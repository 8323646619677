import { Stylable } from '@avtkit/types/commons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Label from './Label';
import { SimpleLink } from '@avtkit/controls/Link';

type Props = Stylable;

const PrivacyPolicyNotice = (props: Props) => {
   const { t } = useTranslation();

   return (
      <Label {...props}>
         {t('By creating an account, you agree to our ')}
         <SimpleLink href="#">{t('Privacy Policy')}</SimpleLink>
         {t(' and recive email from us.')}
      </Label>
   );
};

export default styled(PrivacyPolicyNotice)`
   ${SimpleLink} {
      font-size: 16px;
      font-weight: 700;
   }
`;
