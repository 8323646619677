import * as React from 'react';
import styled from 'styled-components';
import { IconProps } from '../types';

const SimpleIcon: React.FC<IconProps> = (props: IconProps) => {
   const { shape: Shape, className, tooltip, onClick } = props;

   const tooltipParams = {
      'data-tip': tooltip?.content,
      'data-for': tooltip?.options?.id,
      'data-place': tooltip?.options?.place,
   };

   return (
      <div onClick={onClick} className={className} {...tooltipParams}>
         {typeof Shape === 'string' ? <img src={Shape} alt="" /> : <Shape />}
      </div>
   );
};

export default styled(SimpleIcon)`
   svg {
      display: block;
   }

   img {
      display: inline-block;
      max-width: none;
   }
`;
