import React from 'react';
import { SolidButton } from '@avtkit/controls/Button';
import styled from 'styled-components';
import i18n from '../../services/i18n';
import { Upgrade } from '../../components/icons';

export const UpgradeButton = styled(SolidButton).attrs({
   size: 'medium',
   icon: <Upgrade />,
   type: 'secondary',
   variant: 'round',
   children: i18n.t('Upgrade'),
})`
   color: #7361e8;
   display: none;
`;
