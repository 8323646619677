import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import StyledButton from './StyledButton';
import { ButtonProps } from '../types';
import { TextButtonColors, SolidButtonSizes, textButton, solidButton } from '../theme';

type TextButtonProps = ButtonProps &
   WithThemeProps & {
      size: 'tiny' | 'large' | 'medium' | 'small';
   };

const theme = withTheme<TextButtonProps>(textButton);
const solidTheme = withTheme<TextButtonProps>(solidButton);

const StyledActivateButton = styled(StyledButton)<TextButtonProps>`
   appearance: none;
   transition: background-color 300ms, color 300ms, border-color 300ms;
   display: flex;
   border: none;
   align-items: center;
   justify-content: space-around;
   cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
   opacity: ${(props) => (!props.disabled ? '1' : '0.3')};

   svg {
      margin-right: 5px;
      display: block;
      transition: fill 300ms;
   }

   ${theme.colors(
      (c: TextButtonColors, props) => css`
         color: ${c.color};

         svg {
            fill: ${c.icon};
         }

         ${!props.disabled &&
         css`
            &:hover {
               color: ${c.hover.color};

               ${props.icon &&
               css`
                  svg {
                     fill: ${c.hover.icon} !important;
                  }
               `}
            }
         `}
      `
   )}

   ${solidTheme.sizes(
      (s: SolidButtonSizes, { size }) => css`
         border-radius: ${s[size].borderRadius};
         /* padding: ${s[size].padding}; */
         height: ${s[size].height};
         font-size: ${s[size].fontSize};
         border-width: ${s[size].border};
      `
   )}
`;

export default withDefaultProps(StyledActivateButton, {
   size: 'small',
});
