import LoadingSplash from '@avtkit/controls/LoadingSplash';
import { LoadingSplashProps } from '@avtkit/controls/LoadingSplash/types';
import { RemoteDataType } from '@avtkit/helpers/RemoteDataContext';
import React, { useContext } from 'react';
import styled from 'styled-components';

type Props = LoadingSplashProps & {
   dataContext: React.Context<RemoteDataType>;
};

const PageLoading: React.FC<Props> = (props) => {
   const { dataContext, ...loadingProps } = props;
   const { status } = useContext(dataContext);

   return <LoadingSplash visible={status !== 'loaded'} {...loadingProps} />;
};

export default styled(PageLoading)`
   min-height: 500px;
`;
