import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: '#444444',
   hover: '#AAAAAA',
   loadingBackground: '#191919',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   width: '4px',
   borderRadius: '3px',
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
