import { Stylable } from '@/types/commons';
import md5 from 'md5';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import PromotionBanner from '@avtkit/controls/PromotionBanner';
import { useUserPanelData } from '../../contexts/UserPanelData';
import { CONTENT_WIDTH, MOBILE_BP, OFFCANVAS_MENU_BP } from '../../constants';
import { useMatchMedia } from '@avtkit/hooks/useMatchMedia';
import Logo from '../../components/Logo';
import { BurgerButton } from '../../components/BurgerMenu';
import { NameHolder } from '@avtkit/controls/Avatar';
import { useOffCanvas } from '@avtkit/controls/OffCanvas';
import UserMenu from '../UserMenu';

const LeftContainer = styled.div``;
const MiddleContainer = styled.div``;

const Header: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { state, status } = useUserPanelData();
   const { user, promotionBanner } = state;
   const [smallScreen, setSmallScreen] = useState<boolean>(false);
   const { addToOffCanvasStack } = useOffCanvas();

   useMatchMedia(`(max-width: ${OFFCANVAS_MENU_BP})`, setSmallScreen);

   const bannerId = useMemo(
      () =>
         promotionBanner &&
         md5(typeof promotionBanner.banner === 'string' ? promotionBanner.banner : promotionBanner.banner.src),
      [promotionBanner]
   );

   const openOffCanvasMenu = () => {
      addToOffCanvasStack([{ id: 'offcanvasMenu' }]);
   };

   return (
      <div className={className}>
         {smallScreen && (
            <LeftContainer>
               <BurgerButton onClick={openOffCanvasMenu} />
               <Logo />
            </LeftContainer>
         )}

         <MiddleContainer>
            {promotionBanner && (
               <PromotionBanner src={promotionBanner.banner} link={promotionBanner.link} id={bannerId!} />
            )}
         </MiddleContainer>

         {status === 'loaded' && <UserMenu {...user} />}
      </div>
   );
};

export default styled(Header)`
   min-height: 100px;
   margin-bottom: 50px;
   display: flex;
   position: relative;

   ${UserMenu} {
      position: absolute;
      top: 30px;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: end;
      flex: 0 0 auto;

      ${NameHolder} {
         display: none;
      }
   }

   ${MiddleContainer} {
      margin: 0 auto;
      margin-top: 20px;
      max-width: ${CONTENT_WIDTH};
   }

   ${LeftContainer} {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      top: 30px;
      ${Logo} {
         width: 128px;
         margin-left: 10px;
      }
   }

   ${PromotionBanner} {
      max-width: 100%;

      @media screen and (max-width: 1800px) {
         max-width: calc(100% - 83px);
      }

      @media screen and (max-width: ${OFFCANVAS_MENU_BP}) {
         margin-left: 216px;
         max-width: calc(100% - 175px - 132px);
      }

      @media screen and (max-width: ${OFFCANVAS_MENU_BP}) {
         margin-left: 216px;
         max-width: calc(100% - 175px - 132px);
      }

      @media screen and (max-width: ${MOBILE_BP}) {
         display: none;
      }
   }
`;
