import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { useTranslation } from 'react-i18next';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import Gem from '@avtkit/controls/Icon/shapes/Gem.svg';
import { SimpleIcon as Icon } from '@avtkit/controls/Icon';
import ButtonLogic from './ButtonLogic';

import { UpgradeButtonColors, SolidButtonSizes, upgradeButton } from '../theme';
import { useModal } from '@avtkit/controls/ModalsManager/useModal';
import { OutlineButtonProps } from './OutlineButton';

type UpgradeButtonProps = OutlineButtonProps & WithThemeProps;

const UpgradeButton: React.FC<UpgradeButtonProps> = (props) => {
   const { t } = useTranslation();
   const { children, onClick } = props;
   const { addToModalsStack } = useModal();

   const openModal = () => {
      addToModalsStack([{ id: 'upgradePro', options: {} }]);
   };

   return (
      <ButtonLogic {...props} icon={<Icon shape={Gem} />} onClick={onClick || openModal} iconAlign="left">
         {children || t('Upgrade to PRO')}
      </ButtonLogic>
   );
};
const theme = withTheme<UpgradeButtonProps>(upgradeButton);

const StyledUpgradeButton = styled(UpgradeButton)<UpgradeButtonProps>`
   appearance: none;
   transition: background-color 300ms, color 300ms, border-color 300ms;
   display: flex;
   border: none;
   align-items: center;
   justify-content: space-around;
   cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
   opacity: ${(props) => (!props.disabled ? '1' : '0.3')};

   > div {
      display: inline-flex;
      margin-right: 5px;
   }

   ${theme.colors(
      (c: UpgradeButtonColors) => css`
         color: ${c.color};
         background-color: ${c.background};
         box-shadow: 0 0 0 1.5px ${c.border} inset;
      `
   )}

   ${theme.sizes(
      (s: SolidButtonSizes, { size, variant = 'curve' }) => css`
         border-radius: ${s[size].borderRadius[variant]};
         padding: ${s[size].padding};
         height: ${s[size].height};
         font-size: ${s[size].fontSize};
         border-width: ${s[size].border};
      `
   )}
`;

export default withDefaultProps(StyledUpgradeButton, {
   size: 'small',
});
