import { createRemoteDataContext, createRemoteDataProvider } from '@avtkit/helpers/RemoteDataContext';
import { WithChildren } from '@avtkit/types/commons';
import React, { useContext } from 'react';
import { UserProfile } from '../API/types';

export const UserProfileContext = createRemoteDataContext<UserProfile>();
const DataProvider = createRemoteDataProvider<UserProfile>(UserProfileContext);

export const UserProfileProvider: React.FC<WithChildren> = (props) => {
   const { children } = props;
   return (
      <DataProvider initialState={{ success: false }} initialStatus="loaded">
         {children}
      </DataProvider>
   );
};

export const useUserProfile = () => ({
   ...useContext(UserProfileContext),
});
