import React from 'react';
import { Stylable } from '@avtkit/types/commons';
import { VerticalMenu, VerticalMenuItem } from '@avtkit/controls/VerticalMenu';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as icons from '../../components/icons';
import { useUserPanelData } from '../../contexts/UserPanelData';

const AffiliateProgramMenuItem = styled(VerticalMenuItem)``;
const VersionLabel = styled.span``;

const Menu: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();
   const {
      state: { downloadInfo },
   } = useUserPanelData();

   const downloadHandler = () => {
      window.open(downloadInfo.url, downloadInfo.url);
   };

   const openBeacon = () => {
      (window as any)?.Beacon('open');
   };

   return (
      <VerticalMenu className={className}>
         <VerticalMenuItem routeLink={{ to: '/subscriptions' }} icon={<icons.MySubscriptions />}>
            {t('My Subscriptions')}
         </VerticalMenuItem>
         <VerticalMenuItem onClick={openBeacon} icon={<icons.Support />}>
            {t('Support')}
         </VerticalMenuItem>
         <VerticalMenuItem onClick={downloadHandler} icon={<icons.Download />}>
            {t('Download')}
            <VersionLabel>{downloadInfo.version}</VersionLabel>
         </VerticalMenuItem>
         <VerticalMenuItem routeLink={{ to: '/changelogs' }} icon={<icons.Changelogs />}>
            {t('Changelogs')}
         </VerticalMenuItem>
         <AffiliateProgramMenuItem disabled badge={t('soon')} icon={<icons.AffiliateProgram />}>
            {t('Affiliate Program')}
         </AffiliateProgramMenuItem>
      </VerticalMenu>
   );
};

export default styled(Menu)`
   margin-top: 40px;
   max-width: 100%;

   ${AffiliateProgramMenuItem} {
      width: 230px;
   }

   ${VersionLabel} {
      opacity: 0.5;
      margin-left: 5px;
   }
`;
