import { GroupedOptionsType, OptionsType, OptionTypeBase } from 'react-select';

export const findOption = (
   options: GroupedOptionsType<OptionTypeBase> | OptionsType<OptionTypeBase> = [],
   value: string
) => {
   let result;
   for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === value) {
         result = options[i] as OptionsType<OptionTypeBase>;
         break;
      }
      if (options[i].options) {
         result = findOption(options[i].options, value) as GroupedOptionsType<OptionTypeBase>;
         if (result) break;
      }
   }
   return result;
};
