import React, { forwardRef } from 'react';
import { Stylable, WithChildren } from '@avtkit/types/commons';
import { getScrollbarWidth } from '@avtkit/utils/getScrollbarWidth';

type Props = Stylable & WithChildren & { id: string };

const CustomView: React.FC<Props> = forwardRef<HTMLDivElement, Props>(({ id, className, children }, ref) => {
   const scrollWidth = getScrollbarWidth();
   return (
      <div
         ref={ref}
         style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            overflow: 'scroll',
            marginRight: `-${scrollWidth}px`,
            marginBottom: `-${scrollWidth}px`,
         }}
         id={id}
         className={className}
      >
         {children}
      </div>
   );
});

export default (id: string) => ((props: Props) => <CustomView {...props} id={id} />) as React.FC<Props>;
