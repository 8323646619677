import { ThemeScheme } from '@avtkit/helpers/withTheme';

const checkBoxColor = {
   checked: {
      background: '#4980F5',
      border: '#4980F5',
      iconColor: '#FFF',
      disabled: {
         background: '#444444',
         border: '#444444',
         iconColor: '#6a6a6a',
      },
   },
   unChecked: {
      background: '#363636',
      border: '#C4C4C4',
      disabled: {
         background: '#363636',
         border: '#6A6A6A',
      },
   },
   hover: {
      border: '#FFF',
   },
   indeterminate: {
      background: '#444444',
      border: '#444444',
   },
};

export type CheckBoxColorsType = typeof checkBoxColor;
const CheckBoxColors: ThemeScheme<CheckBoxColorsType> = {
   dark: checkBoxColor,
   light: {
      checked: {
         background: '#4980F5',
         border: '#4980F5',
         iconColor: '#FFF',
         disabled: {
            background: '#444444',
            border: '#444444',
            iconColor: '#6a6a6a',
         },
      },
      unChecked: {
         background: 'white',
         border: '#BDBDBD',
         disabled: {
            background: 'white',
            border: '#C8C8C8',
         },
      },
      hover: {
         border: '#4F4F4F',
      },
      indeterminate: {
         background: '#444444',
         border: '#444444',
      },
   },
};

/* ------------------------------------------------------------------------------ */
const CheckBoxSize = {
   width: '15px',
   height: '15px',
   roundBorder: '3px',
   border: '1px',
};

export type CheckBoxSizesType = typeof CheckBoxSize;
const CheckBoxSizes: ThemeScheme<CheckBoxSizesType> = {
   default: CheckBoxSize,
};

export const checkBox = {
   colors: CheckBoxColors,
   sizes: CheckBoxSizes,
};
