import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   backdrop: 'rgba(34, 34, 34, 0.9)',
   background: '#222222',
   border: '#000000',
   shadow: 'rgba(0, 0, 0, 0.25)',
   color: '#FFF',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: {
      backdrop: 'rgba(250, 250, 250, 0.9)',
      background: '#FFFFFF',
      border: '#E6E6E6',
      shadow: 'rgba(0, 0, 0, 0.1)',
      color: '#000000',
   },
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   borderRadius: '5px',
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
};
