import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: '#222222',
   border: '#444444',
   shadow: 'rgba(0, 0, 0, 0.25)',
   color: '#FFF',
   hoverText: '#fff',
   hoverBg: 'rgba(73, 128, 245, 1)',
   disabledColor: 'rgba(68, 68, 68, 1)',
   divider: 'rgba(68, 68, 68, 1)',
   header: 'rgba(129, 129, 129, 0.87)',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: {
      background: '#FFFFFF',
      border: '#E6E6E6',
      shadow: 'rgba(0, 0, 0, 0.25)',
      color: '#000000',
      hoverBg: 'rgba(73, 128, 245, 1)',
      hoverText: '#fff',
      disabledColor: '#E6E6E6',
      divider: '#E6E6E6',
      header: 'rgba(129, 129, 129, 0.87)',
   },
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   borderRadius: '5px',
   medium: {
      padding: '5px 14px',
      fontSize: '12px',
      wrapperPadding: '5px 0',
      headerSize: '10px',
   },
   small: {
      padding: '4px 8px',
      fontSize: '11px',
      wrapperPadding: '5px 0',
      headerSize: '9px',
   },
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
