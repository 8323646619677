import React, { LabelHTMLAttributes, ReactNode } from 'react';
import { Stylable } from '@avtkit/types/commons';
import styled from 'styled-components';

const IconHolder = styled.span``;

type LabelProps = Stylable &
   LabelHTMLAttributes<HTMLLabelElement> & {
      icon?: ReactNode;
   };

const Label: React.FC<LabelProps> = (props) => {
   const { children, icon, ...rest } = props;

   return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label {...rest}>
         {icon && <IconHolder>{icon}</IconHolder>}
         {children}
      </label>
   );
};

export default styled(Label)`
   ${IconHolder} {
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
   }
`;
