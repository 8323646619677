import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { useMySubscriptions } from '../../contexts/MySubscriptionsData';
import NoSubscriptions from './NoSubscriptions';
import Subscriptions from './Subscriptions';

const MySubscriptionsArea: React.FC<Stylable> = (props) => {
   const { className } = props;
   const {
      state: { items },
   } = useMySubscriptions();

   return <div className={className}>{items.length ? <Subscriptions /> : <NoSubscriptions />}</div>;
};

export default styled(MySubscriptionsArea)``;
