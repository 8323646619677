import React, { CSSProperties } from 'react';
import { Stylable } from '@avtkit/types/commons';
import { getScrollbarWidth } from '@avtkit/utils/getScrollbarWidth';
import { ScrollbarsView } from './RenderedElements';

type Props = Stylable & {
   style?: CSSProperties;
};

export const CustomScrollView: React.FC<Props> = ({ style, ...props }) => {
   const scrollWidth = getScrollbarWidth();

   return (
      <ScrollbarsView
         {...props}
         style={{ ...style, marginRight: `-${scrollWidth}px`, marginBottom: `-${scrollWidth}px` }}
      />
   );
};
