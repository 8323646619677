import { ThemeScheme } from '@avtkit/helpers/withTheme';

const SwitchBoxColor = {
   checked: {
      borderColor: '#4980f5',
      background: '#4980f5',
      pointerBackground: '#FFF',
      iconColor: '#FFF',
      disabled: {
         borderColor: '#444444',
         background: '#444444',
         pointerBackground: '#6A6A6A',
         iconColor: '#6A6A6A',
      },
   },
   unChecked: {
      borderColor: '#C4C4C4',
      background: '#363636',
      pointerBackground: '#C4C4C4',
      iconColor: '#C4C4C4',
      disabled: {
         borderColor: '#444444',
         background: '#363636',
         pointerBackground: '#6A6A6A',
         iconColor: '#6A6A6A',
      },
   },
   indeterminate: {
      background: '#444444',
      pointerBackground: '#FFF',
   },
};

export type SwitchBoxColors = typeof SwitchBoxColor;
const SwitchBoxColorsTheme: ThemeScheme<SwitchBoxColors> = {
   dark: SwitchBoxColor,
   light: {
      checked: {
         borderColor: '#2F80ED',
         background: '#2F80ED',
         pointerBackground: '#FFF',
         iconColor: '#FFF',
         disabled: {
            borderColor: '#444444',
            background: '#444444',
            pointerBackground: '#6A6A6A',
            iconColor: '#6A6A6A',
         },
      },
      unChecked: {
         borderColor: '#4F4F4F',
         background: 'white',
         pointerBackground: '#4F4F4F',
         iconColor: '#4F4F4F',
         disabled: {
            borderColor: '#444444',
            background: 'white',
            pointerBackground: '#6A6A6A',
            iconColor: '#6A6A6A',
         },
      },
      indeterminate: {
         background: '#444444',
         pointerBackground: '#FFF',
      },
   },
};

/* ------------------------------------------------------------------------------ */
const SwitchBoxSize = {
   small: {
      width: '26px',
      height: '14px',
      border: '1px',
      pointerWidth: '8px',
      pointerHeight: '8px',
      pointerGap: '2px',
      iconGap: '5px',
   },
   large: {
      width: '40px',
      height: '22px',
      border: '2px',
      pointerWidth: '13px',
      pointerHeight: '13px',
      pointerGap: '4px',
      iconGap: '6px',
   },
};

export type SwitchBoxSizes = typeof SwitchBoxSize;
const SwitchBoxSizesTheme: ThemeScheme<SwitchBoxSizes> = {
   default: SwitchBoxSize,
};

export const switchBox = {
   colors: SwitchBoxColorsTheme,
   sizes: SwitchBoxSizesTheme,
};
