import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';

import {
   MySubscriptionRemoteDataRequest,
   MySubscriptionsProvider,
   MySubscriptionsContext,
} from '../../contexts/MySubscriptionsData';
import PageLoading from '../../components/PageLoading';
import MySubscriptionsArea from './MySubscriptionsArea';

const MySubscriptions: React.FC<Stylable> = (props) => {
   const { className } = props;

   return (
      <div className={className}>
         <MySubscriptionsProvider>
            <MySubscriptionRemoteDataRequest />
            <PageLoading dataContext={MySubscriptionsContext}>
               <MySubscriptionsArea />
            </PageLoading>
         </MySubscriptionsProvider>
      </div>
   );
};

export default styled(MySubscriptions)``;
