import { KitEnvType } from './browser.envType';

const defaultEnv: KitEnvType = {
   scriptsPath: '',
   pluginAPI: 'http://localhost/depicter/wp-admin/admin-ajax.php',
   user: {
      tier: 'free-user',
      token: '',
      email: 'info@example.com',
      joinedNewsletter: false,
   },
   csrfToken: '',
   updateInfo: {
      from: '0',
      to: '1',
   },
};

export default {
   ...defaultEnv,
   ...(window as any)?.depicterKitEnv,
   ...(window as any)?.depicterEnv,
} as KitEnvType;
