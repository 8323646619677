import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { useChangelogs } from '../../contexts/ChangelogData';
import { PageTitle } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import ChangelogList from './ChangelogList';

const Header = styled.div``;

const ChangelogsArea: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();
   const {
      state: { hits, hasMore },
   } = useChangelogs();

   return (
      <div className={className}>
         <Header>
            <PageTitle>{t('Changelogs')}</PageTitle>
         </Header>
         <ChangelogList items={hits} hasMore={hasMore} />
      </div>
   );
};

export default styled(ChangelogsArea)`
   display: flex;
   flex-direction: column;
   height: 100%;

   ${ChangelogList} {
      margin-top: 48px;
   }
`;
