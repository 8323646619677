import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { ChangelogsRemoteDataRequest, ChangelogsProvider, ChangelogsContext } from '../../contexts/ChangelogData';
import PageLoading from '../../components/PageLoading';
import ChangelogsArea from './ChangelogsArea';

const Changelogs: React.FC<Stylable> = (props) => {
   const { className } = props;

   return (
      <div className={className}>
         <ChangelogsProvider>
            <ChangelogsRemoteDataRequest page={1} perpage={10} />
            <PageLoading dataContext={ChangelogsContext}>
               <ChangelogsArea />
            </PageLoading>
         </ChangelogsProvider>
      </div>
   );
};

export default styled(Changelogs)`
   height: 100%;
`;
