import { Stylable } from '@/types/commons';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Changelog, ChangelogsSet } from '../../API/types';
import ChangelogCard from './ChangelogCard';
import InfiniteScroll from '@avtkit/controls/InfiniteScroll';
import { DotSpinner } from '@avtkit/controls/Loading';
import { useChangelogs } from '../../contexts/ChangelogData';

type Props = Stylable & {
   items: Changelog[];
   hasMore: boolean;
   page?: number;
};

const Container = styled.div``;

const ChangelogList: React.FC<Props> = (props) => {
   const { className, items, hasMore } = props;
   const [page, setPage] = useState<number>(1);

   const filterResponse = useCallback(
      (response: ChangelogsSet) => ({
         ...response,
         hits: [...items, ...response.hits],
      }),
      [items]
   );

   const { request } = useChangelogs({ useStatus: false });

   const generateChangelog = (changelog: Changelog) => <ChangelogCard key={changelog.version} {...changelog} />;

   const updatePage = useCallback(() => {
      setPage((p) => p + 1);
      request({ page: page + 1, perpage: 10 }, filterResponse);
   }, [page, request, filterResponse]);

   return (
      <div className={className}>
         <InfiniteScroll
            dataLength={items.length}
            hasMore={hasMore}
            next={updatePage}
            loader={<DotSpinner size="small" />}
         >
            <Container>{items.map(generateChangelog)}</Container>
         </InfiniteScroll>
      </div>
   );
};

export default styled(ChangelogList)`
   flex: 1;

   ${InfiniteScroll} ${DotSpinner} {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;
   }

   ${Container} {
      padding-right: 15px;
   }
`;
