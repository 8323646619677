import styled, { css } from 'styled-components';
import { WithThemeProps, withTheme } from '@avtkit/helpers/withTheme';
import Icon from '@avtkit/controls/Icon/components/SimpleIcon';
import ButtonLogic from './ButtonLogic';
import { ButtonProps } from '../types';
import { simpleButton, SimpleButtonColors, SimpleButtonSizes } from '../theme';

type IconButtonProps = WithThemeProps & ButtonProps;

const theme = withTheme<IconButtonProps>(simpleButton);
const IconButton = styled(ButtonLogic)<IconButtonProps>`
   cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
   opacity: ${props => (!props.disabled ? '1' : '0.3')};

   ${Icon} {
      transition: fill 300ms;
   }

   ${theme.colors(
      (c: SimpleButtonColors, props: IconButtonProps) => css`
         color: ${c.color};
         background: ${c.background};
         appearance: none;
         border: none;

         ${Icon} {
            fill: ${c.iconColor};
         }

         ${!props.disabled &&
            css`
               &:hover {
                  color: ${c.hover.color};
                  ${Icon} {
                     fill: ${c.hover.iconColor};
                  }
               }
            `}
      `
   )}

   ${theme.sizes(
      (s: SimpleButtonSizes) => css`
         padding: ${s.padding};
      `
   )}
`;

export default IconButton;
