import { Stylable } from '@/types/commons';
import { Flag } from '@avtkit/controls/Flags';
import { Form, FormInput, FormLabel, FormSubmitButton } from '@avtkit/controls/Form';
import { useSubmitForm } from '@avtkit/hooks/useSubmitForm';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { api, axios } from '../../API';
import { useUserPanelData } from '../../contexts/UserPanelData';
import i18n from '../../services/i18n';

type UserDataFormProps = Stylable;

const Row = styled.div``;
const Input = styled(FormInput)``;
const SaveButton = styled(FormSubmitButton).attrs({
   size: 'medium',
   variant: 'round',
   children: i18n.t('Save Changes'),
})``;
const Empty = styled.div``;

const UserDataForm: React.FC<UserDataFormProps> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   const { state: userPanelData, setState: setUserPanelData } = useUserPanelData();
   const [success, setSuccess] = useState<boolean>(false);

   const onComplete = useCallback(
      (_data, form) => {
         const { value: firstName } = form.firstName;
         const { value: lastName } = form.lastName;
         const { value: email } = form.email;

         setUserPanelData({ ...userPanelData, user: { firstName, email, lastName } });
         setSuccess(true);
      },
      [setUserPanelData, userPanelData]
   );

   const { loading, errorMessage, submit } = useSubmitForm(api.updateUserProfile, onComplete, axios, () =>
      setSuccess(false)
   );

   return (
      <Form className={className} onSubmit={submit}>
         <Row>
            <FormLabel htmlFor="firstName">{t('First name')}</FormLabel>
            <Input
               name="firstName"
               type="text"
               id="firstName"
               required
               validationOn="submit"
               validationMessage={t('First name is required')}
               defaultValue={userPanelData.user.firstName}
            />
         </Row>
         <Row>
            <FormLabel htmlFor="lastName">{t('Last name')}</FormLabel>
            <Input
               name="lastName"
               type="text"
               id="lastName"
               required
               validationOn="submit"
               validationMessage={t('Last name is required')}
               defaultValue={userPanelData.user.lastName}
            />
         </Row>
         <Row>
            <FormLabel htmlFor="email">{t('Email address')}</FormLabel>
            <Input
               name="email"
               type="email"
               required
               disabled
               validationOn="submit"
               validationMessage={t('Email is required')}
               defaultValue={userPanelData.user.email}
            />
         </Row>
         <Row>
            <Empty />
            <SaveButton loading={loading} />
         </Row>
         {errorMessage && <Flag type="error">{errorMessage}</Flag>}
         {success && <Flag type="success">{t('Changed successfully.')}</Flag>}
      </Form>
   );
};

export default styled(UserDataForm)`
   ${Row} {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      ${FormLabel}, ${Empty} {
         width: 105px;
         text-align: right;
         margin-right: 15px;
      }
   }

   ${Input} {
      max-width: 260px;
   }

   ${SaveButton} {
      flex-shrink: 0;
   }
`;
