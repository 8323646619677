/* eslint-disable react/no-unused-prop-types */
import { Stylable } from '@/types/commons';
import { WithChildren } from '@avtkit/types/commons';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { MOBILE_BP } from '../constants';

type LabelProps = Stylable &
   WithChildren & {
      icon?: ReactNode;
      strong?: boolean;
   };

const IconHolder = styled.span``;

const Label: React.FC<LabelProps> = (props) => {
   const { className, children, icon } = props;

   return (
      <div className={className}>
         {icon && <IconHolder>{icon}</IconHolder>}
         {children}
      </div>
   );
};

export default styled(Label)`
   font-size: 16px;
   line-height: 19px;
   font-weight: 400;

   ${({ strong }) => css`
      font-weight: ${strong ? '700' : '400'};
   `}

   ${IconHolder} {
      display: inline-block;
      vertical-align: middle;
      margin-right: 7px;
      svg {
         fill: #828282;
      }
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      font-size: 14px;
   }
`;
