let scrollbarWidth: number | false = false;

export function getScrollbarWidth() {
   if (scrollbarWidth !== false) return scrollbarWidth;

   // Creating invisible container
   const outer = document.createElement('div');
   outer.style.visibility = 'hidden';
   outer.style.overflow = 'scroll'; // forcing scrollbar to appear
   // @ts-ignore
   outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
   document.body.appendChild(outer);

   // Creating inner element and placing it in the container
   const inner = document.createElement('div');
   outer.appendChild(inner);

   // Calculating difference between container's full width and the child width
   scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

   // Removing temporary elements from the DOM
   outer.parentNode!.removeChild(outer);

   if (scrollbarWidth > 0) {
      return scrollbarWidth + 3;
   }

   return scrollbarWidth;
}
