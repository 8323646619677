import { Stylable } from '@avtkit/types/commons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PrivacyPolicyNotice from '../../components/PrivacyPolicyNotice';
import Separator from '@avtkit/controls/Separator';
import { Content, PageTitle } from '../../components/Typography';
import { JWTGoogleLogin } from '@avtkit/controls/GoogleLogin';
import LoginForm from './LoginForm';
import { RouterLink } from '@avtkit/controls/Link';
import { api, axios } from '../../API';
import { useLogin } from '../../hooks/useLogin';

type Props = Stylable;

const Login = (props: Props) => {
   const { className } = props;
   const { t } = useTranslation();
   const [remember, setRemember] = useState<boolean>(false);
   const doLogin = useLogin(remember);

   return (
      <div className={className}>
         <PageTitle>{t('Login to Your Account')}</PageTitle>
         <Content>
            {t('Don’t have account?')} <RouterLink to="/auth/register">{t('Create account')}</RouterLink>
         </Content>
         <JWTGoogleLogin request={api.loginByGoogle} axios={axios} onLogin={doLogin}>
            {t('Login by Google')}
         </JWTGoogleLogin>
         <Separator>{t('OR')}</Separator>
         <LoginForm onRememberChange={setRemember} remember={remember} onLogin={doLogin} />
         <RouterLink to="/auth/reset-password">{t('Reset your password')}</RouterLink>
         <PrivacyPolicyNotice />
      </div>
   );
};

export default styled(Login)`
   ${PageTitle} {
      font-weight: 400;
      margin-bottom: 20px;
   }

   ${Content} {
      margin-bottom: 25px;
   }

   ${Separator} {
      margin-top: 20px;
      margin-bottom: 30px;
   }

   ${PrivacyPolicyNotice} {
      margin-top: 20px;
   }

   ${JWTGoogleLogin} {
      width: 100%;
   }
`;
