import { Stylable, WithChildren } from '@/types/commons';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CONTENT_WIDTH, OFFCANVAS_MENU_BP, SIDEBAR_WIDTH } from '../../constants';
import { UserPanelDataProvider, UserPanelRemoteDataRequest } from '../../contexts/UserPanelData';
import Footer from './Footer';
import Header from './Header';
import Sidebar from '../Sidebar';
import * as scheme from './theme';
import { OffCanvasProvider } from '@avtkit/controls/OffCanvas';

const MainArea = styled.main``;
export const ContentArea = styled.div``;
const ContentWrapper = styled.section``;

type Props = Stylable & WithChildren & WithThemeProps;

const PanelArea: React.FC<Props> = (props) => {
   const { className } = props;
   const [isSidebarOff, setIsSidebarOff] = useState<boolean>(false);

   return (
      <UserPanelDataProvider>
         <UserPanelRemoteDataRequest />
         <OffCanvasProvider defaultStack={[]}>
            <div className={className + (isSidebarOff ? ' dp-off-sidebar' : '')}>
               <Sidebar onOffCanvas={setIsSidebarOff} />
               <MainArea>
                  <Header />
                  <ContentWrapper>
                     <ContentArea>
                        <Outlet />
                     </ContentArea>
                     <Footer />
                  </ContentWrapper>
               </MainArea>
            </div>
         </OffCanvasProvider>
      </UserPanelDataProvider>
   );
};

const theme = withTheme<Props>(scheme);

export default styled(PanelArea)`
   display: flex;
   flex-direction: row;

   ${MainArea} {
      max-width: 100%;
      flex: 1;
      padding: 0 45px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      min-height: 100vh;
      margin-left: ${SIDEBAR_WIDTH};

      @media screen and (max-width: ${OFFCANVAS_MENU_BP}) {
         padding: 0 30px;
      }
   }

   &.dp-off-sidebar {
      ${MainArea} {
         margin-left: 0;
      }
   }

   ${ContentArea} {
      flex: 1 0 auto;
   }

   ${ContentWrapper} {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      margin: 0 auto;
      flex: 1;
      max-width: ${CONTENT_WIDTH};
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         background-color: ${c.background};

         ${Footer} {
            color: ${c.footer.textColor};
         }
      `
   )}
`;
