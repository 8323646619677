import { Stylable } from '@/types/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NeedHelpButton, NewSubscriptionButton } from '../../components/CommonButtons';
import PageLoading from '../../components/PageLoading';
import { PageTitle } from '../../components/Typography';
import { MOBILE_BP, TABLET_BP } from '../../constants';
import { ActivationContext } from '../../contexts/ActivationData';
import Label from '../../components/Label';
import ActiveSubscriptionsList from './ActiveSubscriptionsList';

const ButtonsContainer = styled.div``;
const Header = styled.div``;
const PageInfo = styled.div``;
const PageDesc = styled(Label)``;

const ActiveSubscriptions: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <div className={className}>
         <Header>
            <PageInfo>
               <PageTitle>{t('Activate your website')}</PageTitle>
               <PageDesc>{t('Choose one of the items from your available subscriptions list')}</PageDesc>
            </PageInfo>
            <ButtonsContainer>
               <NeedHelpButton />
               <NewSubscriptionButton />
            </ButtonsContainer>
         </Header>
         <PageLoading dataContext={ActivationContext}>
            <ActiveSubscriptionsList />
         </PageLoading>
      </div>
   );
};

export default styled(ActiveSubscriptions)`
   ${Header} {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 50px;
   }

   ${ButtonsContainer} {
      display: flex;
   }

   ${NeedHelpButton} {
      margin-right: 20px;
   }

   ${PageTitle} {
      margin-bottom: 10px;
   }
   @media screen and (max-width: ${TABLET_BP}) {
      ${Header} {
         flex-wrap: wrap;
         margin-bottom: 35px;

         ${PageInfo} {
            margin-bottom: 20px;
         }

         ${PageTitle} {
            width: 100%;
         }
      }
      ${NeedHelpButton} {
         margin-right: 10px;
      }
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      ${Header} {
         margin-bottom: 40px;
      }
   }
`;
