import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: 'black',
   border: '#222',
   hover: {
      border: '#5675E7',
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: {
      border: '#E6E6E6',
      background: '#FFFFFF',
      hover: {
         border: '#5675E7',
      },
   },
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   padding: '38px 45px',
   borderRadius: '10px',
   belowSpace: '20px',
   hover: {
      border: '3px',
   },
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
