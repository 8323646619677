import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { FormInputProps } from '../types';
import * as scheme from '../theme';
import { withTheme } from '@avtkit/helpers/withTheme';
import StyledTooltipWrap from '@avtkit/controls/Tooltip/StyledTooltipWrap';
import Info from '@avtkit/controls/Icon/shapes/Info.svgr';
import { Flag } from '@avtkit/controls/Flags';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import useInputProps from '../helpers/useInputProps';
import ClearButton from './ClearButton';

const Input = styled.input``;
const TextArea = styled.textarea``;
const InputWrapper = styled.div``;
const IconsWrapper = styled.div``;

const FormInput = (props: FormInputProps) => {
   const {
      className,
      validationMessage,
      showValidationFlag,
      showValidationTooltip = true,
      tooltipProps = { place: 'top' },
      clearButton,
      variant,
      inputType,
      ...rest
   } = props;
   const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
   const { valid, inputValue, ...inputProps } = useInputProps(inputRef, rest);

   const onClear = () => {
      if (inputRef?.current) {
         inputRef.current.value = '';
         inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      }
   };

   return (
      <div className={className}>
         <InputWrapper>
            {inputType === 'textArea' ? (
               // @ts-ignore
               <TextArea data-valid={valid} {...inputProps} />
            ) : (
               // @ts-ignore
               <Input data-valid={valid} {...inputProps} />
            )}
            <IconsWrapper>
               {!valid && showValidationTooltip && (
                  <StyledTooltipWrap tooltipProps={tooltipProps} tooltip={validationMessage}>
                     <Info />
                  </StyledTooltipWrap>
               )}
               {clearButton && inputValue && <ClearButton onClick={onClear} size={variant} />}
            </IconsWrapper>
         </InputWrapper>
         {!valid && showValidationFlag && <Flag type="error">{validationMessage}</Flag>}
      </div>
   );
};

const theme = withTheme<FormInputProps>(scheme);

const StyledFormInput = styled(FormInput)`
   ${Input}, ${TextArea} {
      display: block;
      border-style: solid;
      transition: all 150ms ease-out;
      width: 100%;

      &:focus {
         outline-style: none;
         box-shadow: none;
         border-color: transparent;
      }
   }

   ${InputWrapper} {
      display: inline-block;
      width: 100%;
      position: relative;

      svg {
         display: block;
      }
   }

   ${IconsWrapper} {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      top: 0;
      right: 5px;
   }

   ${Flag} {
      margin-top: 10px;
   }

   ${StyledTooltipWrap} {
      padding: 5px 5px;
      cursor: pointer;
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         ${Input}, ${TextArea} {
            background: ${c.input.normal.background};
            border-color: ${c.input.normal.border};
            color: ${c.input.normal.color};

            &:hover {
               background: ${c.input.hover.background};
               border-color: ${c.input.hover.border};
            }

            &:focus {
               background: ${c.input.focus.background};
               border-color: ${c.input.focus.border};
            }

            &:disabled {
               background: ${c.input.disable.background};
               border-color: ${c.input.disable.border};
               color: ${c.input.disable.color};
            }

            &[data-valid='false'] {
               border-color: ${c.input.error.border};
            }
         }

         ${StyledTooltipWrap} {
            + div {
               border: none;
               background: ${c.input.error.background} !important;
               color: ${c.input.error.color} !important;
            }

            svg {
               fill: ${c.input.error.icon};
            }
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes, { variant, inputType = 'input' }) => css`
         ${Input},${TextArea} {
            ${inputType === 'input' &&
            css`
               height: ${s.input[variant].height};
            `}
            font-size: ${s.input[variant].fontSize};
            padding: ${s.input[variant].padding};
            border-radius: ${s.input[variant].borderRadius};
            border-width: ${s.input[variant].border};
         }
      `
   )}
`;

export default withDefaultProps(StyledFormInput, {
   variant: 'medium',
   inputType: 'input',
});
