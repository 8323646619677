import { WithChildren } from '@/types/commons';

import React, { useState } from 'react';
import styled from 'styled-components';
import Avatar, { AvatarProps } from '../Avatar';
import { ControlledMenu } from '../ContextMenu';
import Arrow from './Arrow.svgr';
import { ClickEvent } from '@szhsin/react-menu';

export type UserMenuProps = AvatarProps &
   WithChildren & {
      onItemClick?: ({ value }: ClickEvent) => void;
   };

const AvatarHolder = styled.div``;

const UserMenu: React.FC<UserMenuProps> = (props) => {
   const { children, className, onItemClick, ...avatarProps } = props;
   const [isOpen, setOpen] = useState<Boolean>(false);

   return (
      <div className={className}>
         <AvatarHolder onMouseEnter={() => setOpen(true)}>
            <Avatar {...avatarProps} /> <Arrow />
         </AvatarHolder>
         <ControlledMenu
            state={isOpen ? 'open' : 'closed'}
            onMouseLeave={() => setOpen(false)}
            onClose={() => setOpen(false)}
            align="end"
            transition
            size="medium"
            menuClassName="dp-user-menu"
            onItemClick={onItemClick}
         >
            {children}
         </ControlledMenu>
      </div>
   );
};

export default styled(UserMenu)`
   display: inline-flex;
   flex-direction: column;
   position: relative;

   ${AvatarHolder} {
      display: flex;
      flex-direction: row;
      align-items: center;
   }

   ${ControlledMenu} {
      position: absolute;
      right: 0;
      top: 40px;

      .dp-user-menu {
         left: auto !important;
         right: 0;
      }
   }

   &,
   ${Avatar} {
      cursor: pointer !important;
   }

   svg {
      margin-left: 10px;
      fill: currentColor;
   }
`;
