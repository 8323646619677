import React from 'react';
import styled, { css } from 'styled-components';
import { SubMenu, applyStatics } from '@szhsin/react-menu';
import { SubMenuProps } from '../types';
import { withTheme } from '@avtkit/helpers/withTheme';
import * as scheme from '../theme';

const StyledSubMenu = (props: SubMenuProps) => <SubMenu {...props} />;

const theme = withTheme<SubMenuProps>(scheme);

applyStatics(SubMenu)(StyledSubMenu);

export default styled(StyledSubMenu)`
   .szh-menu__item--submenu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;

      &:after {
         content: '';
         display: inline-block;
         width: 5px;
         height: 8px;
         margin-left: 10px;
         background-image: url("data:image/svg+xml,%3Csvg%0Axmlns='http://www.w3.org/2000/svg'%0Awidth='5.055'%0Aheight='8.484'%0A%3E%3Cg data-name='dropdown arrow'%3E%3Cpath data-name='Subtraction 75' d='M5.055 4.24.811 8.479 0 7.668l3.435-3.43L.001.803l.81-.81 4.244 4.243Z' fill='%23fff' /%3E%3C/g%3E%3C/svg%3E");
      }
   }
   ${theme.colors(
      (c: scheme.Colors) => css`
         .szh-menu__item--submenu {
            color: ${c.color};

            &.szh-menu__item--hover {
               background-color: ${c.hoverBg};
               color: ${c.hoverText};
            }

            &.szh-menu__item--disabled {
               color: ${c.disabledColor};
            }
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes, { size }: SubMenuProps) => css`
         .szh-menu__item--submenu {
            font-size: ${s[size].fontSize};
            padding: ${s[size].padding};
         }
      `
   )}
`;
