import React from 'react';
import styled, { css } from 'styled-components';
import { Menu } from '@szhsin/react-menu';
import { MenuProps } from '../types';
import { withTheme } from '@avtkit/helpers/withTheme';
import * as scheme from '../theme';

const StyledMenu = (props: MenuProps) => <Menu {...props} />;

const theme = withTheme<MenuProps>(scheme);

export default styled(StyledMenu)`
   ${theme.colors(
      (c: scheme.Colors) => css`
         .szh-menu {
            background: ${c.background};
            border: 1px solid ${c.border};
            box-shadow: 2px 10px 25px ${c.shadow};
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes, { size }: MenuProps) => css`
         .szh-menu {
            border-radius: ${s.borderRadius};
            padding: ${s[size].wrapperPadding};
         }
      `
   )}
`;
