import { Stylable, WithChildren } from '@/types/commons';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import React from 'react';
import styled, { css } from 'styled-components';
import { CONTENT_WIDTH } from '../../constants';
import { UserPanelDataProvider, UserPanelRemoteDataRequest } from '../../contexts/UserPanelData';
import Header from './Header';
import Footer from '../PanelArea/Footer';
import * as scheme from '../PanelArea/theme';

const MainArea = styled.main``;
export const ContentArea = styled.div``;
const ContentWrapper = styled.section``;

type Props = Stylable & WithChildren & WithThemeProps;

const ActivationArea: React.FC<Props> = (props) => {
   const { className, children } = props;

   return (
      <UserPanelDataProvider>
         <UserPanelRemoteDataRequest />
         <div className={className}>
            <MainArea>
               <Header />
               <ContentWrapper>
                  <ContentArea>{children}</ContentArea>
               </ContentWrapper>
               <Footer />
            </MainArea>
         </div>
      </UserPanelDataProvider>
   );
};

const theme = withTheme<Props>(scheme);

export default styled(ActivationArea)`
   display: flex;
   flex-direction: row;

   ${MainArea} {
      max-width: 100%;
      flex: 1;
      padding: 0 45px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      min-height: 100vh;
   }

   ${ContentArea} {
      flex: 1 0 auto;
   }

   ${ContentWrapper} {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      margin: 0 auto;
      flex: 1;
      max-width: ${CONTENT_WIDTH};
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         background-color: ${c.background};

         ${Footer} {
            color: ${c.footer.textColor};
         }
      `
   )}
`;
