import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { ActivationRemoteDataRequest, ActivationProvider, ActivationContext } from '../../contexts/ActivationData';
import PageLoading from '../../components/PageLoading';
import ActiveSubscriptionsArea from './ActiveSubscriptionsArea';
import ActivationArea from '../../features/ActivationArea';

const Activation: React.FC<Stylable> = (props) => {
   const { className } = props;

   return (
      <div className={className}>
         <ActivationProvider>
            <ActivationRemoteDataRequest />
            <PageLoading dataContext={ActivationContext}>
               <ActivationArea>
                  <ActiveSubscriptionsArea />
               </ActivationArea>
            </PageLoading>
         </ActivationProvider>
      </div>
   );
};

export default styled(Activation)``;
