import { ThemeScheme } from '@avtkit/helpers/withTheme';
import { merge } from 'lodash';

const simpleButtonColor = {
   background: 'transparent',
   color: '#AAAAAA',
   iconColor: '#AAAAAA',
   opacity: 0.8,
   hover: {
      color: 'white',
      iconColor: 'white',
   },
};

export type SimpleButtonColors = typeof simpleButtonColor;
const simpleButtonColors: ThemeScheme<SimpleButtonColors> = {
   dark: simpleButtonColor,
};

/* ------------------------------------------------------------------------------ */
const simpleButtonSize = {
   padding: '5px',
};

export type SimpleButtonSizes = typeof simpleButtonSize;
const simpleButtonSizes: ThemeScheme<SimpleButtonSizes> = {
   default: simpleButtonSize,
};

export const simpleButton = {
   colors: simpleButtonColors,
   sizes: simpleButtonSizes,
};

/* ------------------------------------------------------------------------------ */

const outlineButtonColor = {
   background: 'transparent',
   color: 'white',
   border: '#707070',
   icon: '#FFFFFF',
   hover: {
      background: 'white',
      border: 'white',
      color: '#444444',
      icon: '#444444',
   },
};

export type OutlineButtonColors = typeof outlineButtonColor;
const outlineButtonColors: ThemeScheme<OutlineButtonColors> = {
   dark: outlineButtonColor,
   light: {
      background: 'transparent',
      color: '#000000',
      border: '#BDBDBD',
      icon: 'currentColor',
      hover: {
         background: '#000000',
         border: '#000000',
         color: 'white',
         icon: 'white',
      },
   },
};

/* ------------------------------------------------------------------------------ */
const outlineButtonSize = {
   borderRadius: {
      curve: '30em',
      round: '10px',
   },
   tiny: {
      padding: '0 12px',
      fontSize: '10px',
      border: '2px',
      height: '25px',
      fontWeight: 'normal',
   },
   large: {
      padding: '0 22px',
      fontSize: '16px',
      border: '2px',
      height: '50px',
      fontWeight: 'normal',
   },
   small: {
      padding: '0 16px',
      fontSize: '11px',
      border: '2px',
      height: '31px',
      fontWeight: 'normal',
   },
   medium: {
      padding: '0 20px',
      fontSize: '12px',
      border: '2px',
      height: '37px',
      fontWeight: 'normal',
   },
};

export type OutlineButtonSizes = typeof outlineButtonSize;
const outlineButtonSizes: ThemeScheme<OutlineButtonSizes> = {
   default: outlineButtonSize,
   lightSizing: merge({}, outlineButtonSize, {
      large: {
         fontSize: '16px',
         fontWeight: '500',
      },
      medium: {
         fontSize: '15px',
         fontWeight: '500',
      },
      small: {
         fontSize: '14px',
         fontWeight: '500',
      },
      tiny: {
         fontSize: '12px',
         fontWeight: '500',
      },
   }),
};

export const outlineButton = {
   colors: outlineButtonColors,
   sizes: outlineButtonSizes,
};

/* ------------------------------------------------------------------------------ */

const solidButtonColor = {
   background: {
      primary: '#4980F5',
      secondary: '#121212',
      tertiary: '#121212',
   },
   icon: '#FFFFFF',
   color: {
      primary: 'white',
      secondary: 'white',
      tertiary: 'white',
   },
   hover: {
      background: 'white',
      border: 'white',
      color: '#444444',
      icon: '#444444',
   },
};

export type SolidButtonColors = typeof solidButtonColor;
const solidButtonColors: ThemeScheme<SolidButtonColors> = {
   dark: solidButtonColor,
   light: {
      background: {
         primary: '#7361E8',
         secondary: '#F2F2F2',
         tertiary: '#4980F5',
      },
      icon: 'currentColor',
      color: {
         primary: 'white',
         secondary: '#000000',
         tertiary: 'white',
      },
      hover: {
         background: '#000000',
         border: '#000000',
         color: 'white',
         icon: 'white',
      },
   },
};

const solidButtonSize = {
   large: {
      borderRadius: {
         curve: '30em',
         round: '10px',
      },
      padding: '0 24px',
      fontSize: '16px',
      fontWeight: '600',
      border: '2px',
      height: '50px',
   },
   medium: {
      borderRadius: {
         curve: '30em',
         round: '10px',
      },
      padding: '0 22px',
      fontSize: '12px',
      fontWeight: '600',
      border: '2px',
      height: '37px',
   },
   small: {
      borderRadius: {
         curve: '30em',
         round: '10px',
      },
      padding: '0 16px',
      fontSize: '11px',
      fontWeight: '600',
      border: '2px',
      height: '31px',
   },
   tiny: {
      borderRadius: {
         curve: '30em',
         round: '10px',
      },
      padding: '0 12px',
      fontSize: '10px',
      fontWeight: '600',
      border: '2px',
      height: '25px',
   },
};

export type SolidButtonSizes = typeof solidButtonSize;
const solidButtonSizes: ThemeScheme<SolidButtonSizes> = {
   default: solidButtonSize,
   lightSizing: merge({}, solidButtonSize, {
      large: {
         fontSize: '16px',
         fontWeight: '500',
      },
      medium: {
         fontSize: '15px',
         fontWeight: '500',
      },
      small: {
         fontSize: '14px',
         fontWeight: '500',
      },
      tiny: {
         fontSize: '12px',
         fontWeight: '500',
      },
   }),
};

export const solidButton = {
   colors: solidButtonColors,
   sizes: solidButtonSizes,
};

/* ------------------------------------------------------------------------------ */

const optionButtonColor = {
   background: 'rgba(0, 0, 0, 0.06)',
};

export type OptionButtonColors = typeof optionButtonColor;
const optionButtonColors: ThemeScheme<OptionButtonColors> = {
   dark: optionButtonColor,
};

/* ------------------------------------------------------------------------------ */
const optionButtonSize = {
   width: '29px',
   height: '29px',
   border: '5px',
};

export type OptionButtonSizes = typeof optionButtonSize;
const optionButtonSizes: ThemeScheme<OptionButtonSizes> = {
   default: optionButtonSize,
};

export const optionButton = {
   colors: optionButtonColors,
   sizes: optionButtonSizes,
};

/* ------------------------------------------------------------------------------ */

const activateButtonColor = {
   background: '#070707',
   icon: '#ff5a52',
   color: '#ff5a52',
   hover: {
      background: '#ff5a52',
      color: '#FFFFFF',
      icon: '#FFFFFF',
   },
};

export type ActivateButtonColors = typeof activateButtonColor;
const activateButtonColors: ThemeScheme<ActivateButtonColors> = {
   dark: activateButtonColor,
};

export const activateButton = {
   colors: activateButtonColors,
};

/* ------------------------------------------------------------------------------ */

const upgradeButtonColor = {
   background: 'rgba(180, 102, 44, 0.25)',
   color: '#FFF',
   border: 'rgba(254, 177, 48, 1)',
};

export type UpgradeButtonColors = typeof upgradeButtonColor;
const upgradeButtonColors: ThemeScheme<UpgradeButtonColors> = {
   dark: upgradeButtonColor,
};

export const upgradeButton = {
   colors: upgradeButtonColors,
   sizes: solidButtonSizes,
};

/* ------------------------------------------------------------------------------ */

const textButtonColor = {
   color: '#AAAAAA',
   icon: '#AAAAAA',
   hover: {
      color: '#FFF',
      icon: '#FFF',
   },
};

export type TextButtonColors = typeof textButtonColor;
const textButtonColors: ThemeScheme<TextButtonColors> = {
   dark: textButtonColor,
};

export const textButton = {
   colors: textButtonColors,
};

/* ------------------------------------------------------------------------------ */

const publishButtonColor = {
   unpublished: {
      border: '#01D06F',
      dot: '#01D06F',
      background: '#173823',
      hover: {
         background: 'rgba(40,94,56, 1)',
         color: '#FFF',
      },
   },
};

export type PublishButtonColors = typeof publishButtonColor;
const publishButtonColors: ThemeScheme<PublishButtonColors> = {
   dark: publishButtonColor,
};

const publishButtonSize = {};

export type PublishButtonSizes = typeof publishButtonSize;
const publishButtonSizes: ThemeScheme<PublishButtonSizes> = {
   default: publishButtonSize,
   sizes: solidButtonSizes,
};

export const publishButton = {
   colors: publishButtonColors,
   sizes: publishButtonSizes,
};
