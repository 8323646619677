import { Stylable } from '@avtkit/types/commons';
import SwitchBox from '@avtkit/controls/SwitchBox';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card } from '../../components/Card';
import Label from '../../components/Label';
import { SolidButton } from '@avtkit/controls/Button';
import { MOBILE_BP } from '../../constants';
import { useModal } from '@avtkit/controls/ModalsManager/useModal';

type Props = Stylable & {
   autoRenewal: boolean;
};

const AutoRenewalContainer = styled.div``;
const BillingInfoContainer = styled.div``;

const PaymentInfoCard: React.FC<Props> = (props) => {
   const { className, autoRenewal } = props;
   const { t } = useTranslation();
   const { addToModalsStack } = useModal();

   const openBillingModal = () => {
      addToModalsStack([{ id: 'billingInfo', options: {} }]);
   };

   const autoRenewalChange = useCallback(() => {
      addToModalsStack([{ id: 'autoRenewal', options: {} }]);
   }, [addToModalsStack]);

   return (
      <Card className={className}>
         <AutoRenewalContainer>
            <Label>{t('Auto renewal')}</Label>
            <SwitchBox value={autoRenewal} onChange={autoRenewalChange} size="large" />
         </AutoRenewalContainer>
         <BillingInfoContainer>
            <Label>{t('Billing info')}</Label>
            <SolidButton variant="round" type="secondary" onClick={openBillingModal} size="small">
               {t('View and Edit')}
            </SolidButton>
         </BillingInfoContainer>
      </Card>
   );
};

export default styled(PaymentInfoCard)`
   display: flex;
   flex-wrap: wrap;

   ${Label} {
      font-weight: 600;
   }

   ${AutoRenewalContainer},${BillingInfoContainer} {
      display: flex;
      margin-right: 50px;
      align-items: center;
      flex-shrink: 0;

      ${Label} {
         margin-right: 10px;
      }
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      > * {
         margin-bottom: 10px;
         &:last-child {
            margin: 0;
         }
      }
   }
`;
