import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { Stylable, SrcSet, FitMode, Point, BoxSize } from '@avtkit/types/commons';
import LoadingSplash from '@avtkit/controls/LoadingSplash';
import { useCalcFocalPointMediaPosition } from '@avtkit/controls/MediaCropper/calcFocalPointMediaPosition';
import { useTranslation } from 'react-i18next';
import LoadingFailed from '@avtkit/controls/Icon/shapes/LoadingFailed.svg';
import NoticeOverlay from './NoticeOverlay';
import equal from 'fast-deep-equal';

export type ImageBoxProps = Stylable & {
   src: string | SrcSet;
   alt?: string;
   width?: string | number;
   height?: string | number;
   fitMode?: FitMode;
   position?: string;
   displayLoading?: boolean;
   displayLoadFailed?: boolean;
   crop?: {
      focalPoint?: Point;
      mediaSize?: BoxSize;
      breakpoint?: string;
   };
   onLoad?: () => void;
   onError?: () => void;
   onImageResize?: (size: BoxSize) => void;
   style?: React.CSSProperties;
   revertToBlack?: boolean;
};

const PreviewImg = styled.img``;

const ImageBox: React.FC<ImageBoxProps> = (props) => {
   const {
      className,
      src,
      width,
      height,
      alt = '',
      onLoad,
      onError,
      style,
      displayLoading,
      crop,
      onImageResize,
      displayLoadFailed = true,
   } = props;

   const { t } = useTranslation();
   const imageRef = useRef<HTMLImageElement | null>(null);
   const lastSrc = useRef<string | SrcSet | null>(src);
   const styles: React.CSSProperties = {
      width: typeof width === 'number' ? `${width}px` : width,
      height: typeof height === 'number' ? `${height}px` : height,
      ...style,
   };

   const [loadStatus, setLoadStatus] = useState<'loaded' | 'failed' | 'loading'>(displayLoading ? 'loading' : 'loaded');

   // Show loading again if new image has started loading
   useLayoutEffect(() => {
      if (
         !imageRef.current?.complete &&
         !equal(lastSrc.current, src) &&
         (loadStatus === 'failed' || loadStatus === 'loaded')
      ) {
         setLoadStatus('loading');
         lastSrc.current = src;
      }
   }, [loadStatus, src]);

   const onLoadHandler = useCallback(() => {
      onLoad?.();
      setLoadStatus('loaded');
   }, [onLoad]);

   const onErrorHandler = useCallback(() => {
      onError?.();
      setLoadStatus('failed');
   }, [onError]);

   const [imagePosition, newMediaSize, setContainerRef, setMediaElementRef] = useCalcFocalPointMediaPosition(
      crop?.mediaSize,
      crop?.focalPoint,
      loadStatus === 'loaded' && !!crop,
      crop?.breakpoint,
      onImageResize
   );

   const setImageRef = useCallback(
      (node: HTMLImageElement) => {
         imageRef.current = node;
         setMediaElementRef?.(node);
      },
      [setMediaElementRef]
   );

   useEffect(() => {
      if (imageRef.current && imageRef.current.complete) {
         onLoadHandler();
      }
   }, [onLoadHandler]);

   const imageStyles: React.CSSProperties = {};

   if (loadStatus === 'loaded' && crop && imagePosition) {
      imageStyles.top = `${-imagePosition.y}px`;
      imageStyles.left = `${-imagePosition.x}px`;
   }

   if (loadStatus === 'loaded' && crop && newMediaSize) {
      const { width: imageWidth, height: imageHeight } = newMediaSize;
      imageStyles.width = `${imageWidth}px`;
      imageStyles.height = `${imageHeight}px`;
   }

   let imageElement;
   if (src && typeof src === 'string') {
      imageElement = (
         <PreviewImg
            style={imageStyles}
            src={src}
            alt={alt}
            ref={setImageRef}
            onLoad={onLoadHandler}
            onError={onErrorHandler}
            draggable={false}
         />
      );
   } else if (src) {
      imageElement = (
         <PreviewImg
            src={(src as SrcSet).src}
            srcSet={(src as SrcSet).set.join(',')}
            alt={alt}
            style={imageStyles}
            ref={setImageRef}
            onLoad={onLoadHandler}
            onError={onErrorHandler}
            draggable={false}
         />
      );
   }

   return (
      <div className={`${className} depicter-status-${loadStatus}`} style={styles} ref={setContainerRef}>
         <LoadingSplash overlay visible={loadStatus === 'loading'} background={loadStatus === 'loading'}>
            {loadStatus !== 'failed' || !displayLoadFailed ? (
               imageElement
            ) : (
               <NoticeOverlay label={t('Oops! Image loading failed.')} iconSrc={LoadingFailed} hideLabelOn={300} />
            )}
         </LoadingSplash>
      </div>
   );
};

export default styled(ImageBox)<ImageBoxProps>`
   overflow: hidden;

   &.depicter-status-loading {
      ${PreviewImg} {
         filter: blur(5px);
      }
   }

   ${PreviewImg} {
      transition: filter 300ms;
      position: relative;
      max-width: none;
      width: 100%;
      height: 100%;
      ${(props) =>
         props.fitMode &&
         css`
            object-fit: ${props.fitMode};
         `}

      ${(props) =>
         props.position &&
         css`
            object-position: ${props.position};
         `}

      ${(props) =>
         props.revertToBlack &&
         css`
            filter: brightness(0%);
         `}
   }
`;
