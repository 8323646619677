import React from 'react';
import { OutlineButton, SolidButton, RoundSolidButton } from '@avtkit/controls/Button';
import styled from 'styled-components';
import { BackArrow, Gem, Upgrade } from './icons';
import i18n from '../services/i18n';

export const NewSubscriptionButton = styled(SolidButton).attrs({
   icon: <Gem />,
   children: i18n.t('Get a Subscription'),
   size: 'medium',
   variant: 'round',
})``;

export const NeedHelpButton = styled(OutlineButton).attrs({
   variant: 'round',
   size: 'medium',
   children: i18n.t('Need Help?'),
})`
   display: none;
`;

export const PageBackButton = styled(SolidButton).attrs({
   variant: 'round',
   size: 'small',
   icon: <BackArrow />,
   type: 'secondary',
})``;

export const PrimaryUpgradeButton = styled(SolidButton).attrs({
   size: 'medium',
   icon: <Upgrade />,
   type: 'primary',
   variant: 'round',
   children: i18n.t('Upgrade'),
})`
   display: none;
`;

export const SubmitButton = styled(RoundSolidButton).attrs({
   size: 'medium',
   type: 'tertiary',
   variant: 'round',
   children: i18n.t('Submit'),
})``;
