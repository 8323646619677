import styled from 'styled-components';

export const VerticalTrack = styled.div`
   right: 5px;
   bottom: 2px;
   top: 2px;
`;

export const HorizontalTrack = styled.div`
   right: 2px;
   left: 2px;
   bottom: 5px;
`;

export const VerticalThumb = styled.div`
   cursor: pointer;
   border-radius: inherit;
`;

export const HorizontalThumb = styled.div`
   cursor: pointer;
   border-radius: inherit;
`;

export const ScrollbarsView = styled.div``;
