import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import ButtonLogic from './ButtonLogic';
import { ButtonProps } from '../types';
import { SolidButtonColors, SolidButtonSizes, solidButton } from '../theme';
import DotSpinner from '@avtkit/controls/Loading/components/DotSpinner';

export type SolidButtonProps = ButtonProps &
   WithThemeProps & {
      size: 'tiny' | 'large' | 'medium' | 'small';
      type: 'primary' | 'secondary' | 'tertiary';
      variant?: 'round' | 'curve';
   };

const theme = withTheme<SolidButtonProps>(solidButton);
const SolidButton = styled(ButtonLogic)<SolidButtonProps>`
   appearance: none;
   transition: background-color 300ms, color 300ms, border-color 300ms;
   display: flex;
   border: none;
   align-items: center;
   justify-content: center;
   cursor: ${(props) => (props.disabled || props.loading ? 'auto' : 'pointer')};
   opacity: ${(props) => (!(props.disabled || props.loading) ? '1' : '0.3')};

   ${(props) =>
      props.icon &&
      css`
         svg {
            margin-right: 8px;
            display: block;
            transition: fill 300ms;
         }
      `}

   ${(props) =>
      props.loading &&
      css`
         position: relative;

         > * {
            visibility: hidden;
         }

         > ${DotSpinner} {
            position: absolute;
            top: 50%;
            left: 50%;
            visibility: visible;
            transform: translate(-50%, -50%);
         }
      `}
      
   ${theme.colors(
      (c: SolidButtonColors, props) => css`
         color: ${c.color[props.type]};
         background-color: ${c.background[props.type]};

         ${props.icon &&
         css`
            svg {
               fill: ${c.icon} !important;
            }
         `}

         ${!(props.disabled || props.loading) &&
         css`
            &:hover {
               color: ${c.hover.color};
               background-color: ${c.hover.background};

               ${props.icon &&
               css`
                  svg {
                     fill: ${c.hover.icon} !important;
                  }
               `}
            }
         `}
      `
   )}

   ${theme.sizes(
      (s: SolidButtonSizes, { size, variant = 'curve' }) => css`
         border-radius: ${s[size].borderRadius[variant]};
         padding: ${s[size].padding};
         height: ${s[size].height};
         font-size: ${s[size].fontSize};
         border-width: ${s[size].border};
      `
   )}
`;

export default withDefaultProps(SolidButton, {
   size: 'large',
   type: 'primary',
   variant: 'curve',
});

export const RoundSolidButton = withDefaultProps((props: SolidButtonProps) => <SolidButton {...props} />, {
   size: 'large',
   type: 'primary',
   variant: 'round',
});
