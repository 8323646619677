import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';

export const useSimpleRequest = <T>(
   requestFn: (params: T) => AxiosRequestConfig,
   success: (data: any) => void,
   axios: AxiosInstance = Axios,
   onRequest?: () => void
) => {
   const [loading, setLoading] = useState<boolean>(false);
   const [errorMessage, setErrorMessage] = useState<string>('');

   const request = useCallback(
      (params: T) => {
         setLoading(true);
         setErrorMessage('');

         axios
            .request(requestFn(params))
            .then(({ data }) => {
               success(data);
            })
            .catch((error: AxiosError) => {
               setErrorMessage(error.response?.data?.errors?.[0] || error.message);
            })
            .finally(() => setLoading(false));

         onRequest?.();
      },
      [axios, onRequest, requestFn, success]
   );

   return { loading, errorMessage, request };
};
