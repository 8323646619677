import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import Label from '@avtkit/controls/Label';
import { SwitchBoxProps } from './types';
import Checked from './icons/checked.svgr';
import Unchecked from './icons/unchecked.svgr';
import LargeChecked from './icons/largeChecked.svgr';
import LargeUnchecked from './icons/largeUnchecked.svgr';

import { switchBox, SwitchBoxSizes, SwitchBoxColors } from './theme';

const HitArea = styled.span``;

const Switch: React.FC<SwitchBoxProps> = ({
   value,
   className,
   onChange,
   disabled,
   label,
   tooltip,
   size = 'small',
}: SwitchBoxProps) => {
   const onClickHandler = (e: React.MouseEvent) => {
      e.preventDefault();

      if (disabled || !onChange) {
         return;
      }

      if (value === 'indeterminate') {
         onChange(true);
      } else {
         onChange(!value);
      }
   };

   const checkedIcon = size === 'small' ? <Checked /> : <LargeChecked />;
   const unCheckedIcon = size === 'small' ? <Unchecked /> : <LargeUnchecked />;

   return (
      <div className={className} onClick={onClickHandler}>
         <HitArea>{value ? checkedIcon : unCheckedIcon}</HitArea>
         {label && (
            <Label display="block" tooltip={tooltip}>
               {label}
            </Label>
         )}
      </div>
   );
};

const theme = withTheme<SwitchBoxProps>(switchBox);

export default styled(Switch)<SwitchBoxProps>`
   position: relative;
   display: inline-flex;
   flex-direction: row;
   align-items: center;

   ${(props) => (props.disabled ? 'cursor: auto;' : 'cursor: pointer;')}

   input {
      width: 0;
      height: 0;
      opacity: 0;
   }

   > ${Label} {
      margin-bottom: 0;
   }

   > ${HitArea} {
      position: relative;
      box-sizing: border-box;
      border-style: solid;
      padding: 3px;
      border-radius: 50em;
      transition: all 150ms ease-out;
      margin-top: 2px;
      margin-right: 10px;
      ${(props) => props.indeterminate && 'border: none;'}

      &:before {
         content: '';
         position: absolute;
         top: 50%;
         border-radius: 50%;
         transition: all 150ms ease-out;

         ${(props) =>
            props.value
               ? css`
                    transform: translateY(-50%) translateX(-100%);
                 `
               : css`
                    transform: translateY(-50%);
                 `}

         ${(props) =>
            props.indeterminate &&
            css`
               left: 50% !important;
               transform: translateY(-50%) translateX(-50%);
            `}
      }

      svg {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);

         ${(props) =>
            props.indeterminate &&
            css`
               display: none;
            `}
      }
   }

   ${theme.sizes(
      (s: SwitchBoxSizes, { value, size = 'small' }) => css`
         > ${HitArea} {
            border-width: ${s[size].border};
            width: ${s[size].width};
            height: ${s[size].height};

            &:before {
               height: ${s[size].pointerWidth};
               width: ${s[size].pointerHeight};
            }

            ${value
               ? css`
                    &:before {
                       left: calc(100% - ${s[size].pointerGap});
                    }

                    svg {
                       left: ${s[size].iconGap};
                    }
                 `
               : css`
                    &:before {
                       left: ${s[size].pointerGap};
                    }

                    svg {
                       right: ${s[size].iconGap};
                    }
                 `}
         }
      `
   )}

   ${theme.colors(
      (c: SwitchBoxColors, props: SwitchBoxProps) => css`
         > ${HitArea} {
            ${props.value
               ? css`
                    ${props.disabled
                       ? css`
                            background-color: ${c.checked.disabled.background};
                            border-color: ${c.checked.disabled.borderColor};

                            &:before {
                               background-color: ${c.checked.disabled.pointerBackground};
                            }

                            svg {
                               fill: ${c.checked.disabled.iconColor};
                            }
                         `
                       : css`
                            background-color: ${c.checked.background};
                            border-color: ${c.checked.borderColor};

                            &:before {
                               background-color: ${c.checked.pointerBackground};
                            }

                            svg {
                               fill: ${c.checked.iconColor};
                            }
                         `}
                 `
               : css`
                    ${props.disabled
                       ? css`
                            background-color: ${c.unChecked.disabled.background};
                            border-color: ${c.unChecked.disabled.borderColor};

                            &:before {
                               background-color: ${c.unChecked.disabled.pointerBackground};
                            }

                            svg {
                               fill: ${c.unChecked.disabled.iconColor};
                            }
                         `
                       : css`
                            background-color: ${c.unChecked.background};
                            border-color: ${c.unChecked.borderColor};

                            &:before {
                               background-color: ${c.unChecked.pointerBackground};
                            }

                            svg {
                               fill: ${c.unChecked.iconColor};
                            }
                         `}
                 `}

            ${props.indeterminate &&
            css`
               background-color: ${c.indeterminate.background};
            `}
         }
      `
   )}
`;
