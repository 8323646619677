import React, { useCallback } from 'react';
import { Stylable } from '@/types/commons';
import styled from 'styled-components';
import { IconButton } from '@avtkit/controls/Button';
import { SimpleIcon as Icon } from '@avtkit/controls/Icon';
import Support from '@avtkit/controls/Icon/shapes/Support.svgr';
import { useLoadBeacon } from '@avtkit/controls/HelpScoutBeacon/useHelpScoutBeacon';
import { MenuItem, Menu } from '@avtkit/controls/ContextMenu';
import { useTranslation } from 'react-i18next';
import useOpenChangelogs from '@avtkit/controls/Changelog/useOpenChangelogs';
import useOpenOnboardingVideo from '@avtkit/controls/OnboardingVideo/useOpenOnboardingVideo';
import { useModal } from '@avtkit/controls/ModalsManager/useModal';

type HelpButtonProps = Stylable & { isSubscribed?: boolean };

const HelpButton: React.FC<HelpButtonProps> = ({ className, isSubscribed }: HelpButtonProps) => {
   const { t } = useTranslation();
   const { addToModalsStack } = useModal();
   const { loaded, status, action } = useLoadBeacon('a3b75763-1818-443e-b864-55449580d7c0');
   const openChangelog = useOpenChangelogs();
   const openOnboardingVideo = useOpenOnboardingVideo();
   const MenuButton = <IconButton icon={<Icon shape={Support} />} />;

   const onContactHandler = useCallback(() => {
      if (loaded) action(status === 'open' ? 'close' : 'open');
   }, [action, loaded, status]);

   const onQuickStartHandler = useCallback(() => {
      openOnboardingVideo(true);
   }, [openOnboardingVideo]);

   const onOpenChangelogHandler = useCallback(() => {
      openChangelog(true);
   }, [openChangelog]);

   const onSubscribeHandler = useCallback(() => {
      addToModalsStack([
         {
            id: 'subscribe',
            options: {},
         },
      ]);
   }, [addToModalsStack]);

   const onFeedbackHandler = useCallback(() => {
      addToModalsStack([
         {
            id: 'feedback',
            options: {},
         },
      ]);
   }, [addToModalsStack]);

   return (
      <div className={className}>
         <Menu direction="bottom" align="end" transition size="medium" menuButton={MenuButton}>
            <MenuItem size="medium" onClick={onContactHandler}>
               {t('Contact support')}
            </MenuItem>
            <MenuItem size="medium" onClick={onFeedbackHandler}>
               {t('Feedback')}
            </MenuItem>
            <MenuItem size="medium" onClick={onQuickStartHandler}>
               {t('Quick start video')}
            </MenuItem>
            <MenuItem size="medium" onClick={onOpenChangelogHandler}>
               {t('Changelogs')}
            </MenuItem>
            {!isSubscribed && (
               <MenuItem size="medium" onClick={onSubscribeHandler}>
                  {t('Subscribe to newsletter')}
               </MenuItem>
            )}
         </Menu>
      </div>
   );
};
export default styled(HelpButton)``;
