import Cookies from 'js-cookie';
import { useCallback, useContext } from 'react';
import AuthenticationContext from './components/AuthenticationContext';
import { AuthenticationData } from './types';

const useAuthentication = () => {
   const { setAuthenticationData, ...authenticationData } = useContext(AuthenticationContext);

   const setToken = useCallback(
      (token: string, data: Partial<AuthenticationData>, remember?: boolean) => {
         const cookieData = {
            token,
            ...data,
         };

         Cookies.set('depicterTokenData', JSON.stringify(cookieData), { expires: remember ? 365 : undefined });

         setAuthenticationData({
            isAuthorized: true,
            ...cookieData,
         });
      },
      [setAuthenticationData]
   );

   const removeToken = useCallback(() => {
      setAuthenticationData({
         isAuthorized: false,
      });

      Cookies.remove('depicterTokenData');
   }, [setAuthenticationData]);

   return {
      ...authenticationData,
      setToken,
      removeToken,
      setAuthenticationData,
   };
};

export default useAuthentication;
