import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Label from '../../components/Label';
import { Content, PageTitle } from '../../components/Typography';
import { Form, FormInput, FormSubmitButton } from '@avtkit/controls/Form';
import i18n from '../../services/i18n';
import { Flag } from '@avtkit/controls/Flags';
import { Stylable } from '@/types/commons';
import { RouterLink } from '@avtkit/controls/Link';
import { useSubmitForm } from '@avtkit/hooks/useSubmitForm';
import { api, axios } from '../../API';
import ForgotPasswordSuccess from './ForgotPasswordSuccess';

type Props = Stylable;

const Row = styled.div``;
const Input = styled(FormInput).attrs({ variant: 'medium' })``;
const ResetButton = styled(FormSubmitButton).attrs({
   size: 'large',
   variant: 'round',
   children: i18n.t('Send'),
})``;

const ForgotPasswordForm = (props: Props) => {
   const { className } = props;
   const { t } = useTranslation();
   const [success, setSuccess] = useState<boolean>(false);

   const onSuccess = useCallback((data) => {
      if (data.success) {
         setSuccess(true);
      }
   }, []);

   const { submit, errorMessage, loading } = useSubmitForm(api.sendForgotPasswordFormData, onSuccess, axios);

   return success ? (
      <ForgotPasswordSuccess />
   ) : (
      <div className={className}>
         <PageTitle>{t('Reset Your Password')}</PageTitle>
         <Content>{t('You will receive an email containing the reset password link.')}</Content>
         <Form onSubmit={submit}>
            <Row>
               <Label>{t('Email')}</Label>
               <Input type="email" required validationOn="submit" size="32" name="email" />
            </Row>

            {errorMessage && <Flag type="error">{errorMessage}</Flag>}
            <Row>
               <ResetButton loading={loading} />
            </Row>
            <RouterLink backIcon to="/auth/login">
               {t('Back to login')}
            </RouterLink>
         </Form>
      </div>
   );
};

export default styled(ForgotPasswordForm)`
   ${PageTitle} {
      font-weight: 400;
      margin-bottom: 20px;
   }

   ${Content} {
      margin-bottom: 25px;
   }

   ${Form} {
      ${Label} {
         margin-bottom: 10px;
      }

      ${Row} {
         margin-bottom: 20px;
      }

      ${ResetButton} {
         width: 100%;
         justify-content: center;
      }
   }
`;
