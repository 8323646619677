/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkCircles from '@loadings/loadings/circles/dark.html';
import lightCircles from '@loadings/loadings/circles/light.html';
import LoadingSymbol from './LoadingSymbol';

const Circles = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkCircles} light={lightCircles} />;

export default styled(Circles)`
   transform: scale(${(props) => (props.size === 'small' ? '0.3' : '1')});
`;
