import React from 'react';
import { IconButton } from '@avtkit/controls/Button';
import Icon from '@avtkit/controls/Icon/components/SimpleIcon';
import styled from 'styled-components';
import { CloseButtonProps } from '../types';
import Close from '../icons/Close.svgr';

const CloseButton = (props: CloseButtonProps) => (
   <IconButton {...props}>
      <Icon shape={Close} />
   </IconButton>
);

export default styled(CloseButton)``;
