import { Checkbox } from '@avtkit/controls/Checkbox';
import { Flag } from '@avtkit/controls/Flags';
import { Form, FormInput, FormSubmitButton } from '@avtkit/controls/Form';
import { Stylable } from '@avtkit/types/commons';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LoginIcon from '../icons/Login.svgr';
import Label from '../components/FormLabel';

type Props = Stylable & {
   onSubmit: (event: FormEvent<HTMLFormElement>) => void;
   onRememberChange: (status: boolean) => void;
   isRemember: boolean;
   hasError?: boolean;
   loading?: boolean;
   errorMessage?: string;
};

const Row = styled.div``;
const Input = styled(FormInput).attrs({ variant: 'medium' })``;
export const LoginButton = styled(FormSubmitButton).attrs({
   size: 'large',
   variant: 'round',
   icon: <LoginIcon />,
})``;

const LoginForm = (props: Props) => {
   const { className, hasError, errorMessage, isRemember, loading, onRememberChange, onSubmit } = props;
   const { t } = useTranslation();

   return (
      <Form className={className} onSubmit={onSubmit}>
         <Row>
            <Label htmlFor="email">{t('Email')}</Label>
            <Input
               type="email"
               validationMessage={t('Please enter a valid email address')}
               required
               validationOn="submit"
               size="32"
               id="email"
               name="username"
               autoComplete="username"
            />
         </Row>
         <Row>
            <Label htmlFor="password">{t('Password')}</Label>
            <Input
               type="password"
               validationMessage={t('Please enter your password')}
               required
               validationOn="submit"
               id="password"
               name="password"
               autoComplete="current-password"
            />
         </Row>
         <Checkbox value={isRemember} round onChange={onRememberChange}>
            {t('Remember password')}
         </Checkbox>
         <LoginButton loading={loading}>{t('Login')}</LoginButton>
         {hasError && errorMessage && <Flag type="error">{errorMessage}</Flag>}
      </Form>
   );
};

export default styled(LoginForm)``;
