import { withTheme } from '@avtkit/helpers/withTheme';
import styled, { css } from 'styled-components';
import DropdownIndicator from './DropdownIndicator';
import DropdownLogic from './DropdownLogic';
import { DropdownColors, DropdownSizes, dropdown } from '../theme';
import { StyledDropdownProps } from '../types';
import DropdownContainer from './DropdownContainer';
import OptimizeOption from './OptimizeOption';

const theme = withTheme<StyledDropdownProps>(dropdown);

const StyledDropdown = styled(DropdownLogic)<StyledDropdownProps>`
   display: inline-block;
   cursor: pointer;
   ${(props) =>
      props.width &&
      css`
         width: ${props.width};
      `}

   .ms-dd__placeholder {
      position: static;
      margin: 0;
      transform: none;
   }

   .ms-dd__control {
      position: relative;
      min-height: auto;
      border: none;
      transition: 150ms ease-out;

      &.ms-dd__control--menu-is-open {
         ${(props) =>
            props.menuPlacement === 'top'
               ? css`
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                 `
               : css`
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                 `}

         ${(props) =>
            props.isSearchable &&
            css`
               &.ms-dd__control--is-focused {
                  .ms-dd__placeholder,
                  .ms-dd__single-value {
                     background: #4980f5;
                     color: #fff;
                  }
               }
            `}
      }
   }

   .ms-dd__single-value {
      max-width: none;
      position: static;
      transform: none;
   }

   .ms-dd__indicator-separator {
      display: none;
   }

   [class*='-dummyInput'] {
      position: absolute;
   }

   .ms-dd__menu {
      position: absolute;
      margin: 0;
      border-radius: 0;

      ${(props) =>
         props.menuPlacement === 'top'
            ? css`
                 border-bottom-right-radius: 0;
                 border-bottom-left-radius: 0;
              `
            : css`
                 border-top-right-radius: 0;
                 border-top-left-radius: 0;
              `}

      border: none;
      margin-top: -1px;
      z-index: 9999999;
   }

   .ms-dd__option {
      cursor: pointer;
      background: transparent;

      &--is-selected {
         background: transparent;
      }
   }

   .ms-dd__menu-list {
      padding: 0;
   }

   .ms-dd__value-container {
      cursor: pointer;

      > div {
         margin: 0;
         padding: 0;
      }
   }

   ${DropdownIndicator} {
      cursor: pointer;
   }

   ${theme.colors(
      (c: DropdownColors, props: StyledDropdownProps) => css`
         &:hover {
            .ms-dd__control {
               box-shadow: 0 0 0 1px ${c.hovers.borderColor} inset;
            }
            .ms-dd__control--menu-is-open {
               box-shadow: 0 0 0 1px ${c.borderColor} inset;
            }
         }

         .ms-dd__placeholder,
         .ms-dd__single-value,
         .ms-dd__input {
            color: ${c.color};
         }

         .ms-dd__control {
            background-color: ${c.background};
            box-shadow: 0 0 0 1px ${c.borderColor} inset;

            &.ms-dd__control--is-focused {
               z-index: 99999;
               box-shadow: 0 0 0 1px ${c.focus} inset;
            }
         }

         .ms-dd__menu {
            background-color: ${c.background};
            box-shadow: 0 0 0 1px ${c.borderColor} inset;
            color: ${c.color};
         }

         .ms-dd__option:hover {
            background: ${c.hover};
         }

         .ms-dd__option:active {
            background: inherit;
         }

         .ms-dd__option--is-selected {
            color: ${c.activeColor};
         }

         ${DropdownIndicator} svg path {
            fill: ${c.icon};
         }

         ${props.disabled &&
         css`
            .ms-dd__placeholder,
            .ms-dd__single-value,
            .ms-dd__input {
               color: ${c.disabled.color};
               user-select: none;
            }

            .ms-dd__control {
               background-color: ${c.disabled.background};
               box-shadow: 0 0 0 1px ${c.disabled.borderColor} inset;
            }

            .ms-dd__menu {
               background-color: ${c.disabled.background};
               box-shadow: 0 0 0 1px ${c.disabled.borderColor} inset;
               color: ${c.disabled.color};
            }

            ${DropdownIndicator} svg path {
               fill: ${c.disabled.icon};
            }
         `}
      `
   )}

   .ms-dd__value-container {
      padding: 0 10px;
   }

   ${theme.sizes(
      (s: DropdownSizes, { size, menuPlacement, minWidth }) => css`
         .ms-dd__placeholder,
         .ms-dd__single-value,
         .ms-dd__input {
            font-size: ${s[size].fontSize};
            font-weight: ${s[size].fontWeight};
         }

         .ms-dd__control {
            min-width: ${minWidth || s[size].minWidth};
            border-radius: ${s[size].borderRadius};
            height: ${s[size].height};
         }

         ${DropdownIndicator} {
            padding: ${s[size].indicatorPadding};
         }

         .ms-dd__menu {
            padding: ${s[size].dropdownDistance} 0px;

            ${menuPlacement === 'top'
               ? css`
                    border-top-right-radius: ${s[size].borderRadius};
                    border-top-left-radius: ${s[size].borderRadius};
                 `
               : css`
                    border-bottom-right-radius: ${s[size].borderRadius};
                    border-bottom-left-radius: ${s[size].borderRadius};
                 `}

            font-size: ${s[size].fontSize};
            font-weight: ${s[size].fontWeight};
         }

         .ms-dd__option {
            padding: ${s[size].padding};
         }
      `
   )}
`;

StyledDropdown.defaultProps = {
   isSearchable: false,
   classNamePrefix: 'ms-dd',
   components: {
      DropdownIndicator,
      MenuList: DropdownContainer,
      Option: OptimizeOption,
   },
   size: 'medium',
};

export default StyledDropdown;
