import { Stylable } from '@avtkit/types/commons';
import * as React from 'react';
import styled from 'styled-components';
import { PageTitle, Content } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import { RouterLink } from '@avtkit/controls/Link';

type Props = Stylable;

const ResetPasswordSuccess = (props: Props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <div className={className}>
         <PageTitle>{t('Reset Your Password')}</PageTitle>
         <Content>{t('Your password has been reset')}</Content>
         <RouterLink backIcon to="/auth/login">
            {t('Back to login')}
         </RouterLink>
      </div>
   );
};

export default styled(ResetPasswordSuccess)`
   ${PageTitle}, ${Content} {
      margin-bottom: 20px;
   }
`;
