import './assets/styles/reset.css';
import './assets/styles/global.scss';

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Stylable } from '@/types/commons';
import { themeConfig } from './ThemeConfig';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PanelArea from './features/PanelArea';
import Login from './pages/Login';
import Register from './pages/Register';
import SingleSubscription from './pages/SingleSubscription';
import ForgotPassword from './pages/ForgotPassword';
import NotFoundPage from './pages/NotFoundPage';
import Changelogs from './pages/Changelogs';
import MySubscriptions from './pages/MySubscriptions';
import Activation from './pages/Activation';
import AuthArea from './features/AuthArea';
import { AuthenticationProvider, PrivateRoute } from '@avtkit/helpers/Authentication';
import ResetPassword from './pages/ResetPassword';
import { axios } from './API';
import { GoogleOAuthProvider } from '@react-oauth/google';
import browserEnv from './env/browser.env';
import Profile from './pages/Profile';
import HelpscoutBeacon from './features/HelpscoutBeacon';

type AppProps = Stylable;

const App: React.FC<AppProps> = ({ className }: AppProps) => (
   <ThemeProvider theme={themeConfig}>
      <BrowserRouter>
         <AuthenticationProvider>
            <GoogleOAuthProvider clientId={browserEnv.googleClientId}>
               <div className={className}>
                  <HelpscoutBeacon />
                  <Routes>
                     <Route path="/auth" element={<AuthArea />}>
                        <Route path="/auth" element={<Navigate to="/auth/login" />} />
                        <Route path="/auth/login" element={<Login />} />
                        <Route path="/auth/register" element={<Register />} />
                        <Route path="/auth/reset-password" element={<ForgotPassword />} />
                        <Route path="/auth/reset-password/:token" element={<ResetPassword />} />
                     </Route>
                     <Route path="/activation" element={<PrivateRoute axios={axios} redirectPath="/auth/login" />}>
                        <Route path="/activation" element={<Activation />} />
                     </Route>
                     <Route path="/" element={<PrivateRoute axios={axios} redirectPath="/auth/login" />}>
                        <Route path="/" element={<PanelArea />}>
                           <Route path="/" element={<MySubscriptions />} />
                           <Route path="/subscriptions" element={<MySubscriptions />} />
                           <Route path="/subscriptions/:id" element={<SingleSubscription />} />
                           <Route path="/changelogs" element={<Changelogs />} />
                           <Route path="/profile" element={<Profile />} />
                        </Route>
                     </Route>
                     <Route path="*" element={<NotFoundPage />} />
                  </Routes>
               </div>
            </GoogleOAuthProvider>
         </AuthenticationProvider>
      </BrowserRouter>
   </ThemeProvider>
);

export default styled(App)``;
