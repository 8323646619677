import { useCallback, useEffect, useState } from 'react';
import { useModal } from '../ModalsManager/useModal';

const useOpenOnboardingVideo = () => {
   const { addToModalsStack } = useModal();
   const [open, setOpen] = useState(false);

   useEffect(() => {
      if (localStorage.getItem('dpIsNewUser') !== '0') {
         setOpen(true);
      } else {
         setOpen(false);
      }
   }, [addToModalsStack]);

   const openOnboarding = useCallback(
      (force?: boolean) => {
         if (open || force) {
            addToModalsStack([
               {
                  id: 'onboardingVideo',
                  options: { embedVideoUrl: '//www.youtube.com/embed/yi_NjXKlvmg?rel=0' },
               },
            ]);
         }
      },
      [open, addToModalsStack]
   );

   return openOnboarding;
};

export default useOpenOnboardingVideo;
