import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';

const NotFoundPage: React.FC<Stylable> = (props) => {
   const { className } = props;

   return <div className={className}>NotFoundPage</div>;
};

export default styled(NotFoundPage)``;
