import { SolidButton } from '@avtkit/controls/Button';
import React from 'react';
import styled from 'styled-components';
import { BurgerMenu as BurgerIcon, BurgerMenuClose as BurgerCloseIcon } from './icons';

export const BurgerButton = styled(SolidButton).attrs({ icon: <BurgerIcon />, iconOnly: true, type: 'secondary' })`
   width: 50px;
   height: 50px;
   padding: 0;
   svg {
      margin: 0;
      display: block;
   }
`;

export const BurgerCloseButton = styled(SolidButton).attrs({
   icon: <BurgerCloseIcon />,
   iconOnly: true,
   type: 'secondary',
})`
   width: 50px;
   height: 50px;
   padding: 0;
   svg {
      margin: 0;
      display: block;
   }
`;
