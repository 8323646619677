import React from 'react';
import { OptionProps, components, OptionTypeBase } from 'react-select';

const OptimizeOption = ({ children, ...props }: OptionProps<OptionTypeBase, boolean>) => {
   const { innerProps } = props;
   const { onMouseMove, onMouseOver, ...rest } = innerProps;
   const newProps = Object.assign(props, { innerProps: rest });

   return <components.Option {...newProps}>{children}</components.Option>;
};

export default OptimizeOption;
