import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { SimpleLink } from '@avtkit/controls/Link';
import { useTranslation } from 'react-i18next';
import { MOBILE_BP } from '../../constants';

const PrivacyPolicyLink = styled(SimpleLink)``;
const CopyrightText = styled.div``;

const Footer: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <div className={className}>
         <PrivacyPolicyLink href="#" target="_blank">
            {t('Privacy Policy')}
         </PrivacyPolicyLink>
         <CopyrightText>
            {t('Copyright © {{year}} {{name}} all rights reserved.', {
               name: 'Averta',
               year: new Date().getFullYear(),
            })}
         </CopyrightText>
      </div>
   );
};

export default styled(Footer)`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   height: 70px;

   @media screen and (max-width: ${MOBILE_BP}) {
      font-size: 14px;
      flex-direction: column;
      justify-content: end;
      ${PrivacyPolicyLink} {
         margin-bottom: 15px;
      }

      margin-bottom: 15px;
   }
`;
