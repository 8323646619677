import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PrivateRouteProps } from '../types';
import { useAddAxiosAuth } from '../useAddAxiosAuth';
import useAuthentication from '../useAuthentication';

const PrivateRoute = ({ redirectPath, axios }: PrivateRouteProps) => {
   const { setToken, removeToken, setAuthenticationData, ...authenticationData } = useAuthentication();
   const { isAuthorized } = authenticationData;
   const { pathname } = useLocation();

   useAddAxiosAuth(axios);

   useEffect(() => {
      if (!isAuthorized) {
         setAuthenticationData({
            ...authenticationData,
            redirectPath: pathname,
         });
      }
   }, [isAuthorized, authenticationData, pathname, setAuthenticationData]);

   if (!isAuthorized) {
      return <Navigate to={redirectPath} replace />;
   }

   return <Outlet />;
};

export default PrivateRoute;
