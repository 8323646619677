/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import StyledTooltipWrap from '@avtkit/controls/Tooltip/StyledTooltipWrap';
import IconButton from '@avtkit/controls/Button/components/IconButton';
import Icon from '@avtkit/controls/Icon/components/SimpleIcon';
import { LabelProps } from './types';
import * as scheme from './theme';
import Info from './icons/info.svg';

const Label = (props: LabelProps) => {
   const {
      className,
      children,
      tooltip: {
         content: tooltipContent,
         options: tooltipProps,
         hitComponent: tooltipHitComponent = (
            <IconButton theme={props.theme}>
               <Icon shape={Info} />
            </IconButton>
         ),
      } = {},
      tooltipLocation = 'after',
   } = props;

   const TooltipComponent = tooltipContent && (
      <StyledTooltipWrap tooltipProps={tooltipProps} tooltip={tooltipContent}>
         {tooltipHitComponent}
      </StyledTooltipWrap>
   );

   return (
      <span className={className}>
         {tooltipLocation === 'before' && TooltipComponent}
         {children}
         {tooltipLocation === 'after' && TooltipComponent}
      </span>
   );
};

const theme = withTheme<LabelProps>(scheme);

export default styled(Label)`
   display: ${(props) => props.display || 'inline-block'};
   margin-bottom: 5px;

   ${StyledTooltipWrap} {
      position: relative;
      top: 2px;
      display: inline-block;

      ${(props) =>
         props.tooltipLocation === 'before'
            ? css`
                 margin-right: 4px;
              `
            : css`
                 margin-left: 4px;
              `}

      ${IconButton} {
         padding: 0;
         vertical-align: top;
      }
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         color: ${c.color};
      `
   )}
   ${theme.sizes(
      (s: scheme.Sizes) => css`
         font-size: ${s.fontSize};
         font-weight: ${s.fontWeight};
      `
   )}
`;
