import React from 'react';
import styled, { css } from 'styled-components';
import { MenuHeader } from '@szhsin/react-menu';
import { MenuHeaderProps } from '../types';
import { withTheme } from '@avtkit/helpers/withTheme';
import * as scheme from '../theme';

const StyledMenuHeader = (props: MenuHeaderProps) => <MenuHeader {...props} />;

const theme = withTheme<MenuHeaderProps>(scheme);

export default styled(StyledMenuHeader)`
   text-transform: uppercase;
   letter-spacing: 1px;
   font-weight: 600;

   ${theme.colors(
      (c: scheme.Colors) => css`
         color: ${c.header};
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes, { size }: MenuHeaderProps) => css`
         font-size: ${s[size].headerSize};
         padding: ${s[size].padding};
      `
   )}
`;
