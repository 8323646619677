import { Stylable, WithChildren } from '@avtkit/types/commons';
import React from 'react';
import { Subscription } from '../../API/types';
import { CardTitle } from '../../components/Typography';
import styled from 'styled-components';
import Label from '../../components/Label';
import { Trans, useTranslation } from 'react-i18next';
import { ActiveWebsites, Renewal, Time } from '../../components/icons';
import Tag from '../../components/Tag';
import { MOBILE_BP } from '../../constants';
import { Card } from '../../components/Card';
import { CardProps } from '@avtkit/controls/Card';
import { formatDateAndTime } from '@avtkit/utils/functions';

export type SubscriptionCardProps = Stylable &
   WithChildren &
   Subscription &
   CardProps & {
      buttons?: React.ReactNode;
   };

const Header = styled.div``;
const Content = styled.div``;
const Strong = styled.span`
   font-weight: 600;
`;
const Labels = styled.div``;
const Buttons = styled.div``;
const LabelRow = styled.div``;

const SubscriptionCard: React.FC<SubscriptionCardProps> = (props) => {
   const {
      className,
      children,
      name,
      id,
      activeWebsites,
      websites,
      status,
      renewable,
      expirationDate,
      renewPrice,
      buttons,
      onClick,
   } = props;
   const { t } = useTranslation();

   return (
      <Card className={className} onClick={onClick}>
         <Header>
            <CardTitle>
               <span>{name}</span>
               {status && <Tag variant={status} />}
            </CardTitle>
            <Label>ID {id}</Label>
         </Header>
         <Content>
            <Labels>
               <LabelRow>
                  <Label icon={<ActiveWebsites />}>
                     <Trans t={t}>
                        Active websites:{' '}
                        <Strong>
                           {activeWebsites.toString()} of {websites.toString()}
                        </Strong>
                     </Trans>
                  </Label>
                  <Label icon={<Renewal />}>
                     <Trans t={t}>
                        Auto renewal: <Strong>{renewable ? 'On' : 'Off'}</Strong>
                     </Trans>
                  </Label>
               </LabelRow>
               <Label icon={<Time />}>
                  {renewable && (
                     <Trans t={t}>
                        Next payment on: <Strong>{formatDateAndTime(expirationDate)}</Strong> for{' '}
                        <Strong>{renewPrice}</Strong>
                     </Trans>
                  )}
                  {!renewable && (
                     <Trans t={t}>
                        Valid until: <Strong>{formatDateAndTime(expirationDate)}</Strong>
                     </Trans>
                  )}
               </Label>
            </Labels>
            {buttons && <Buttons>{buttons}</Buttons>}
         </Content>
         {children}
      </Card>
   );
};

export default styled(SubscriptionCard)`
   ${Header} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      margin-bottom: 25px;

      ${Label} {
         flex-shrink: 0;
      }
   }

   ${CardTitle} {
      display: flex;
      align-items: center;
      ${Tag} {
         margin-left: 15px;
      }
   }

   ${Content} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: end;
   }

   ${Labels} {
      ${LabelRow} {
         > * {
            display: inline-block;

            &:not(:last-child) {
               margin-right: 20px;
            }
         }
      }

      > *:not(:last-child) {
         margin-bottom: 10px;
      }
   }

   ${Buttons} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      ${CardTitle} {
         flex-wrap: wrap;

         > span:first-child {
            order: 2;
            width: 100%;
            display: block;
            margin-top: 10px;
         }

         ${Tag} {
            order: 1;

            margin-left: 0;
            display: block;
         }
      }

      ${Content} {
         flex-wrap: wrap;
      }

      ${Labels} {
         width: 100%;
      }

      ${Buttons} {
         margin-top: 20px;
      }
   }
`;
