import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.minimal.css';
import { withDefaultProps } from '@avtkit/helpers/withDefaultProps';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { FlagsContainerProps } from '../types';
import * as scheme from '../theme';
import Close from './icons/close.svg';
import Fade from './Fade';

const Flags = (props: FlagsContainerProps) => <ToastContainer transition={Fade} {...props} />;

const theme = withTheme<FlagsContainerProps>(scheme);

export default withDefaultProps(
   styled(Flags)`
      &.Toastify__toast-container {
         position: fixed;
         z-index: 9999;
      }

      .Toastify__toast {
         position: relative;

         &.fade-in {
            animation-name: fadeIn;
         }

         &.fade-out {
            animation-name: fadeOut;
         }
      }

      .Toastify__close-button {
         position: absolute;
         top: 3px;
         right: 10px;
         background-image: url(${Close});
         background-color: transparent;
         border: none;
         background-repeat: no-repeat;
         background-position: center;
         padding: 5px 10px;
         cursor: pointer;
      }

      &.Toastify__close-button > svg {
         display: none;
      }

      ${theme.sizes(
         (s: scheme.Sizes) => css`
            &.Toastify__toast-container--top-left {
               top: ${s.top};
               left: ${s.left};
            }
            &.Toastify__toast-container--top-center {
               top: ${s.top};
               left: 50%;
               transform: translateX(-50%);
            }
            &.Toastify__toast-container--top-right {
               top: ${s.top};
               right: ${s.right};
            }
            &.Toastify__toast-container--bottom-left {
               bottom: ${s.bottom};
               left: ${s.left};
            }
            &.Toastify__toast-container--bottom-center {
               bottom: ${s.bottom};
               left: 50%;
               transform: translateX(-50%);
            }
            &.Toastify__toast-container--bottom-right {
               bottom: ${s.bottom};
               right: ${s.right};
            }
         `
      )}

      @keyframes fadeIn {
         0% {
            opacity: 0;
         }
         100% {
            opacity: 1;
         }
      }

      @keyframes fadeOut {
         0% {
            opacity: 1;
         }
         100% {
            opacity: 0;
         }
      }
   `,
   {
      closeOnClick: false,
      autoClose: 3000,
      position: 'bottom-left',
   }
);
