import { Stylable } from '@/types/commons';
import { WithChildren } from '@avtkit/types/commons';
import React from 'react';
import styled from 'styled-components';
import { SimpleIcon as Icon } from '@avtkit/controls/Icon';
import CheckmarkSymbol from './CheckmarkSymbol.svg';
import Label from '../../../../components/Label';
import { MOBILE_BP } from '../../../../constants';

type Props = Stylable & WithChildren;
const PremiumFeaturesItem: React.FC<Props> = (props) => {
   const { className, children } = props;

   return (
      <div className={className}>
         <Label icon={<Icon shape={CheckmarkSymbol} />}>{children}</Label>
      </div>
   );
};

export default styled(PremiumFeaturesItem)`
   ${Label} {
      font-weight: 500;
      font-size: 18px;
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      ${Label} {
         font-size: 18px;
      }
   }
`;
