import { Stylable, WithChildren } from '@/types/commons';
import React from 'react';
import styled, { css } from 'styled-components';
import VerticalMenuItem from './VerticalMenuItem';
import * as scheme from './theme';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';

type VerticalMenuProps = Stylable & WithChildren & WithThemeProps & {};

const VerticalMenu: React.FC<VerticalMenuProps> = (props) => {
   const { className, children } = props;
   return <div className={className}>{children}</div>;
};

const theme = withTheme<VerticalMenuProps>(scheme);

export default styled(VerticalMenu)`
   display: flex;
   flex-direction: column;

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         ${VerticalMenuItem} {
            margin-bottom: ${s.itemsGap};
         }
      `
   )}
`;
