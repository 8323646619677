import { nanoid } from 'nanoid';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import InfiniteScrollComp, { Props as InfiniteScrollCompProps } from 'react-infinite-scroll-component';
import styled from 'styled-components';
import Scrollbars from '../Scrollbars';
import customScrollView from './CustomScrollView';

type Props = InfiniteScrollCompProps;

const InfiniteScroll = (props: Props) => {
   const { children, className, next, dataLength, hasMore, ...infiniteScrollProps } = props;

   const customViewId = useMemo<string>(nanoid, []);
   const customView = useMemo(() => customScrollView(customViewId), [customViewId]);

   const [scrollBarHeight, setScrollBarHeight] = useState(0);
   const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(0);

   const scrollbarsRef = useRef<HTMLDivElement | null>();
   const infiniteScrollRef = useRef<HTMLDivElement | null>();

   // eslint-disable-next-line react-hooks/exhaustive-deps
   useEffect(() => {
      if (infiniteScrollRef.current && scrollbarsRef.current) {
         setScrollBarHeight(scrollbarsRef.current.getBoundingClientRect().height);
         setInfiniteScrollHeight(infiniteScrollRef.current.getBoundingClientRect().height);
      }
   });

   useEffect(() => {
      if (hasMore && infiniteScrollHeight < scrollBarHeight) {
         next();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [scrollBarHeight, infiniteScrollHeight]);

   return (
      <Scrollbars
         className={className}
         renderView={customView}
         ref={(ref) => {
            if (ref) {
               scrollbarsRef.current = (ref as any).view;
            }
         }}
      >
         <InfiniteScrollComp
            next={next}
            dataLength={dataLength}
            scrollableTarget={customViewId}
            hasMore={hasMore}
            ref={(ref) => {
               if (ref) {
                  // eslint-disable-next-line no-underscore-dangle
                  infiniteScrollRef.current = (ref as any)._infScroll;
               }
            }}
            {...infiniteScrollProps}
         >
            {children}
         </InfiniteScrollComp>
      </Scrollbars>
   );
};

export default styled(InfiniteScroll)`
   & > div {
      z-index: 1;
   }
`;
