import React, { useEffect, useRef } from 'react';
import { MenuListComponentProps, OptionTypeBase } from 'react-select';
import Scrollbars from '@avtkit/controls/Scrollbars';
import styled from 'styled-components';

const DropdownContainer: React.FC<MenuListComponentProps<OptionTypeBase, boolean>> = ({
   className,
   children,
   getValue,
}) => {
   const scrollbarsRef = useRef<HTMLDivElement | null>();
   const value = getValue();

   useEffect(() => {
      if (scrollbarsRef.current) {
         const scrollbarContainerRef = (scrollbarsRef.current as any).view;
         const selectedValueRef = scrollbarContainerRef.querySelector('.ms-dd__option--is-selected');

         if (selectedValueRef) {
            const { top: containerTopOffset } = scrollbarContainerRef.getBoundingClientRect();
            const { top: selectedValueTopOffset } = selectedValueRef.getBoundingClientRect();
            (scrollbarsRef.current as any).scrollTop(selectedValueTopOffset - containerTopOffset);
         }
      }
   }, [value]);

   return (
      <Scrollbars
         className={className}
         autoHeight
         ref={(scrollRef) => {
            if (scrollRef) {
               scrollbarsRef.current = scrollRef as any;
            }
         }}
      >
         {children}
      </Scrollbars>
   );
};

export default styled(DropdownContainer)`
   > div {
      z-index: 9999;
   }
`;
