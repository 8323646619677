import React from 'react';
import { SolidButton } from '@avtkit/controls/Button';
import styled from 'styled-components';
import i18n from '../../services/i18n';
import { Details } from '../../components/icons';

export const DetailsButton = styled(SolidButton).attrs({
   size: 'medium',
   type: 'secondary',
   variant: 'round',
   icon: <Details />,
   children: i18n.t('Details'),
})``;
