import {
   createRemoteDataContext,
   createRemoteDataProvider,
   useRemoteDataRequest,
} from '@avtkit/helpers/RemoteDataContext';
import { WithChildren } from '@avtkit/types/commons';
import { AxiosRequestConfig } from 'axios';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { api, axios } from '../API';
import { SingleSubscription, SingleSubscriptionRequestParams } from '../API/types';

export const SingleSubscriptionContext = createRemoteDataContext<SingleSubscription>();
const DataProvider = createRemoteDataProvider<SingleSubscription>(SingleSubscriptionContext);

export const SingleSubscriptionProvider: React.FC<WithChildren> = (props) => {
   const { children } = props;
   return <DataProvider>{children}</DataProvider>;
};

export const useSingleSubscription = (props?: SingleSubscriptionRequestParams) => {
   const requestConfig: AxiosRequestConfig | undefined = useMemo(
      () => props && api.getSingleSubscription(props),
      [props]
   );
   const request = useRemoteDataRequest<SingleSubscription>(SingleSubscriptionContext, axios);
   const requestHandler = useCallback(() => requestConfig && request(requestConfig), [requestConfig, request]);

   return { ...useContext(SingleSubscriptionContext), request: requestHandler };
};

export const SingleSubscriptionRemoteDataRequest = (props: SingleSubscriptionRequestParams) => {
   const { request } = useSingleSubscription(props);
   useEffect(() => request(), [request]);
   return null;
};
