import { Stylable } from '@avtkit/types/commons';
import React from 'react';
import styled from 'styled-components';
import { PageTitle } from '../../components/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Label from '../../components/Label';
import { Warning } from '../../components/icons';
import { NewSubscriptionButton } from '../../components/CommonButtons';

type Props = Stylable & {};

const PageDesc = styled(Label)``;
const Strong = styled.span`
   font-weight: 600;
`;

const NoActiveSubscriptions: React.FC<Props> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <div className={className}>
         <PageTitle>{t('Activate your website')}</PageTitle>
         <PageDesc icon={<Warning />}>
            <Trans t={t}>
               <Strong>No available</Strong> subscriptions for activating this website.
            </Trans>
         </PageDesc>
         <NewSubscriptionButton />
      </div>
   );
};

export default styled(NoActiveSubscriptions)`
   margin-bottom: 50px;

   ${PageTitle} {
      margin-bottom: 50px;
   }

   ${PageDesc} {
      margin-bottom: 20px;
   }
`;
