/* eslint-disable react/no-danger */
import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { FlagsProps } from '../types';
import * as scheme from '../theme';
import Success from './icons/success.svgr';
import Warning from './icons/warning.svgr';
import Info from './icons/info.svgr';
import Error from './icons/alert.svgr';
import closeIcon from './icons/close.svg';

const Title = styled.span``;
const IconHolder = styled.div``;
const Content = styled.div``;
const CloseButton = styled.img``;

const Flag = ({ className, title, type, children, icon, htmlContent, close, onClose }: FlagsProps) => (
   <div className={className}>
      <IconHolder>
         {type === 'success' && <Success />}
         {type === 'warning' && <Warning />}
         {type === 'info' && <Info />}
         {type === 'error' && <Error />}
         {type === 'custom' && icon}
      </IconHolder>
      <Content>
         {title && <Title>{title}</Title>}
         {children && <div>{children}</div>}
         {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
         {close && <CloseButton src={closeIcon} onClick={onClose} />}
      </Content>
   </div>
);

const theme = withTheme<FlagsProps>(scheme);

export default styled(Flag)`
   display: flex;
   flex-direction: row;

   ${Content} {
      flex: 1;
      position: relative;
   }

   ${IconHolder} {
      margin-right: 8px;
      svg {
         display: block;
      }
   }

   ${CloseButton} {
      position: absolute;
      top: 3px;
      right: 10px;
      cursor: pointer;
   }

   ${theme.colors(
      (c: scheme.Colors, { type }: FlagsProps) => css`
         background: ${c[type].bg};
         color: ${c.color};
         border: 1px solid ${c[type].accent};
         box-shadow: 2px 10px 25px ${c.shadow};

         ${IconHolder} {
            > svg {
               circle,
               path {
                  fill: ${c[type].accent};
               }

               rect {
                  fill: ${c.iconColor};
               }
            }
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         border-radius: ${s.border};
         padding: ${s.padding};
         margin-bottom: ${s.distance};

         ${Title} {
            font-size: ${s.title.size};
            font-weight: ${s.title.weight};
         }

         > div {
            font-size: ${s.content.size};
            font-weight: ${s.content.weight};
         }
      `
   )}
`;
