import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: '#FFFFFF',
   border: '#E6E6E6',
   backdrop: 'rgba(0,0,0,0.7)',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
};
