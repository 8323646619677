import { Stylable } from '@avtkit/types/commons';
import React from 'react';
import styled from 'styled-components';
import ActiveSubscriptionsItem from './ActiveSubscriptionsItem';
import { CardTitle } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import { useActivation } from '../../contexts/ActivationData';

type Props = Stylable & {};

const ActiveSubscriptionsList: React.FC<Props> = (props) => {
   const { className } = props;
   const { t } = useTranslation();
   const {
      state: { items },
   } = useActivation();

   return (
      <div className={className}>
         <CardTitle>{t('Your available subscriptions')}</CardTitle>
         {items.map((item) => (
            <ActiveSubscriptionsItem key={item.id} {...item} />
         ))}
      </div>
   );
};

export default styled(ActiveSubscriptionsList)`
   > ${CardTitle} {
      margin-bottom: 25px;
   }
`;
