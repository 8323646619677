import { BoxSize, Point } from '@avtkit/types/commons';
import { fitToBox } from '@avtkit/utils/functions';
import equal from 'fast-deep-equal';
import { useLayoutEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';

export const calcFocalPointMediaPosition = (
   mediaWidth: number,
   mediaHeight: number,
   focalX: number,
   focalY: number,
   cropWidth: number,
   cropHeight: number,
   forceFit: boolean = false
) => {
   if (forceFit || mediaWidth < cropWidth || mediaHeight < cropHeight) {
      // eslint-disable-next-line no-param-reassign
      ({ width: mediaWidth, height: mediaHeight } = fitToBox('cover', cropWidth, cropHeight, mediaWidth, mediaHeight));
   }

   const mediaPosition = {
      x: mediaWidth * focalX - cropWidth / 2,
      y: mediaHeight * focalY - cropHeight / 2,
   };

   mediaPosition.x = Math.min(mediaWidth - cropWidth, Math.max(0, mediaPosition.x));
   mediaPosition.y = Math.min(mediaHeight - cropHeight, Math.max(0, mediaPosition.y));

   return { position: mediaPosition, mediaSize: { width: mediaWidth, height: mediaHeight } };
};

export const useCalcFocalPointMediaPosition = (
   initialMediaSize?: BoxSize,
   focalPoint: Point = { x: 0.5, y: 0.5 },
   enabled: boolean = true,
   breakpoint: string = 'default',
   onMediaResize?: (size: BoxSize) => void
): [Point | undefined, BoxSize | undefined, (node: HTMLElement | null) => void, (node: HTMLElement | null) => void] => {
   // const [mediaSize, setMediaSize] = useState<BoxSize | undefined>(initialMediaSize);
   const mediaSize = useRef<BoxSize | undefined>(initialMediaSize);
   const [cropSize, setCropSize] = useState<{ [key: string]: BoxSize | null }>({});
   const [containerRef, setContainerRef] = useState<HTMLElement | null>();
   const [mediaRef, setMediaRef] = useState<HTMLElement | null>();
   const [mediaPosition, setMediaPosition] = useState<Point | undefined>();
   const lastCropSize = useRef<{ [key: string]: BoxSize | null }>({});

   useLayoutEffect(() => {
      if (!enabled) {
         return;
      }

      if (!initialMediaSize && mediaRef) {
         mediaSize.current = { width: mediaRef.clientWidth, height: mediaRef.clientHeight };
      } else {
         mediaSize.current = initialMediaSize;
      }
   }, [enabled, initialMediaSize, mediaRef]);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   useLayoutEffect(() => {
      if (containerRef && enabled) {
         const newCropSize = { width: containerRef.clientWidth, height: containerRef.clientHeight };
         if (!equal(cropSize[breakpoint], newCropSize)) setCropSize({ [breakpoint]: newCropSize });
      }
   });

   useLayoutEffect(() => {
      if (enabled && cropSize[breakpoint] && mediaSize.current) {
         const internalMediaSize = { ...mediaSize.current };
         const bpCropSize = cropSize[breakpoint]!;
         const lastCropSizeValue = lastCropSize.current?.[breakpoint];

         if (!lastCropSizeValue) {
            lastCropSize.current![breakpoint] = bpCropSize;
         } else if (!shallowEqual(bpCropSize, lastCropSizeValue)) {
            const { width: lastWidth, height: lastHeight } = lastCropSizeValue;
            // scale media size if aspect is not changed
            if (bpCropSize.width !== lastWidth && bpCropSize.height !== lastHeight) {
               const currentAspect = bpCropSize.width / bpCropSize.height;
               const refAspect = lastWidth / lastHeight;
               if (Math.abs(currentAspect - refAspect) < 0.01) {
                  internalMediaSize.width =
                     Math.floor(internalMediaSize.width * (bpCropSize.width / lastWidth) * 10) / 10;
                  internalMediaSize.height =
                     Math.floor(internalMediaSize.height * (bpCropSize.height / lastHeight) * 10) / 10;
               }
            }

            lastCropSize.current![breakpoint] = bpCropSize;
         }

         const { position, mediaSize: calcMediaSize } = calcFocalPointMediaPosition(
            internalMediaSize.width,
            internalMediaSize.height,
            focalPoint.x,
            focalPoint.y,
            bpCropSize.width,
            bpCropSize.height,
            !initialMediaSize // force fit the image when media size is not given
         );

         setMediaPosition(position);

         if (!shallowEqual(calcMediaSize, mediaSize.current)) {
            mediaSize.current = calcMediaSize;
         }

         onMediaResize?.(calcMediaSize);
      }
   }, [breakpoint, cropSize, enabled, focalPoint.x, focalPoint.y, initialMediaSize, onMediaResize]);

   return [mediaPosition, mediaSize.current, setContainerRef, setMediaRef];
};
