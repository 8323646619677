import { Stylable } from '@/types/commons';
import React from 'react';
import md5 from 'md5';
import styled, { css } from 'styled-components';

export type AvatarProps = Stylable & {
   onClick?: (e: React.MouseEvent) => void;
   size?: number;
   gravatar?: string;
   avatar?: string;
   name?: string;
};

const generateGravatar = (email: string) => `https://www.gravatar.com/avatar/${md5(email)}?s=200`;
const PhotoFrame = styled.div``;
export const NameHolder = styled.span``;

const Avatar: React.FC<AvatarProps> = (props) => {
   const { className, onClick, gravatar, avatar, name } = props;

   const imgSrc = avatar || (gravatar ? generateGravatar(gravatar) : '');

   return (
      <div className={className} onClick={onClick}>
         {imgSrc && (
            <PhotoFrame>
               <img src={imgSrc} alt="" />
            </PhotoFrame>
         )}
         {name && <NameHolder>{name}</NameHolder>}
      </div>
   );
};

export default styled(Avatar)`
   display: flex;
   flex-direction: row;
   align-items: center;

   ${PhotoFrame} {
      border-radius: 50%;
      overflow: hidden;
      ${({ size = 34 }) => css`
         width: ${size}px;
         height: ${size}px;
      `}

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   ${NameHolder} {
      margin-left: 9px;
      font-weight: 600;
   }

   ${({ onClick }) => css`
      cursor: ${onClick ? 'pointer' : 'auto'};
   `}
`;
