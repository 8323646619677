import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkBallTriangle from '@loadings/loadings/ballTriangle/dark.html';
import lightBallTriangle from '@loadings/loadings/ballTriangle/light.html';
import LoadingSymbol from './LoadingSymbol';

const BallTriangle = (props: LoadingProps) => (
   <LoadingSymbol {...props} dark={darkBallTriangle} light={lightBallTriangle} />
);

export default styled(BallTriangle)`
   transform: scale(${(props) => (props.size === 'small' ? '0.6' : '1')});
`;
