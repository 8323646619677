import React, { useEffect } from 'react';
import { useLoadBeacon } from '@avtkit/controls/HelpScoutBeacon/useHelpScoutBeacon';
import { useUserPanelData } from '../../contexts/UserPanelData';

const HelpscoutBeacon: React.FC = () => {
   const { loaded: beaconLoaded } = useLoadBeacon('b392fe50-e5c1-4320-a6e2-080dcf171587');
   const {
      status,
      state: { user },
   } = useUserPanelData();

   useEffect(() => {
      if (status === 'loaded' && beaconLoaded) {
         (window as any)?.Beacon('identify', {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
         });
      }
   }, [status, beaconLoaded, user?.firstName, user?.lastName, user?.email]);

   return null;
};

export default HelpscoutBeacon;
