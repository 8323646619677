import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   item: {
      color: 'white',
      hover: {
         color: '#222',
         background: 'white',
      },
      selected: {
         color: '#222',
         background: 'white',
      },
      badge: {
         color: '#828282',
         background: '#F2F2F2',
      },
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: {
      item: {
         color: '#000000',
         hover: {
            background: '#000000',
            color: 'white',
         },
         selected: {
            color: 'white',
            background: '#000000',
         },
         badge: {
            color: '#828282',
            background: '#F2F2F2',
         },
      },
   },
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   item: {
      borderRadius: '7px',
      iconGap: '8px',
      height: '36px',
      padding: '0 13px',
      fontSize: '15px',
      badge: {
         fontSize: '10px',
         gap: '8px',
         padding: '0 7px',
         height: '17px',
      },
   },

   itemsGap: '4px',
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
