import * as React from 'react';
import Select, { OptionTypeBase, ValueType } from 'react-select';
import { DropdownProps } from '../types';
import { findOption } from '../helpers';
import { useCallback } from 'react';

const DropDownLogic: React.FC<DropdownProps> = ({
   disabled,
   indeterminate,
   value,
   defaultValue,
   options,
   isMulti = false,
   onChange,
   ...props
}) => {
   const onChangeHandler = useCallback(
      (newValue: ValueType<OptionTypeBase, false>) => {
         const changedValue = isMulti
            ? (newValue as ValueType<OptionTypeBase, true>)?.map((c) => c.value) || []
            : (newValue as ValueType<OptionTypeBase, false>)?.value;

         onChange?.(changedValue, newValue);
      },
      [isMulti, onChange]
   );

   const findValue = useCallback(() => {
      if (indeterminate) {
         return null;
      }

      const targetValue = typeof value === 'undefined' || value === null ? defaultValue : value;
      const currentValue = isMulti
         ? (targetValue as string[]).map((innerValue) => findOption(options, innerValue))
         : findOption(options, targetValue as string);

      return currentValue as ValueType<OptionTypeBase, typeof isMulti>;
   }, [options, value, isMulti, defaultValue, indeterminate]);

   return (
      <Select
         captureMenuScroll={false}
         options={options}
         isMulti={isMulti}
         isDisabled={disabled}
         placeholder={indeterminate ? '—' : 'Select...'}
         ignoreAccents={false}
         onChange={onChangeHandler}
         value={!indeterminate ? findValue() : null}
         {...props}
      />
   );
};
export default DropDownLogic;
