import React, { useCallback, useState } from 'react';
import countries from 'country-list';
import { Dropdown } from '../Dropdown';
import { DropdownProps } from '../Dropdown/types';

const countriesList = Object.entries(countries.getCodeList()).map(([key, value]) => ({
   value: key.toUpperCase(),
   label: value,
}));

export const CountriesDropdown: React.FC<DropdownProps> = (props) => {
   const { defaultValue, onChange: onChangeProp } = props;
   const [value, setValue] = useState<string>(defaultValue as string);

   const onChange = useCallback(
      (ddValue) => {
         setValue(ddValue);
         onChangeProp?.(ddValue);
      },
      [onChangeProp]
   );

   return <Dropdown options={countriesList} value={value} onChange={onChange} {...props} />;
};
