import { Stylable } from '@/types/commons';
import { ModalsProvider, Modal } from '@avtkit/controls/ModalsManager';
import React from 'react';
import styled from 'styled-components';
import { useSingleSubscription } from '../../contexts/SingleSubscriptionData';
import AutoRenewal from '../../features/Modals/AutoRenewal';
import BillingInfo from '../../features/Modals/BillingInfo';
import ActiveWebsitesCard from './ActiveWebsitesCard';
import PaymentHistoryCard from './PaymentHistoryCard';
import PaymentInfoCard from './PaymentInfoCard';
import SubscriptionInfo from './SubscriptionInfo';

const PageContent: React.FC<Stylable> = (props) => {
   const { className } = props;

   const {
      state: { billingInfo, paymentHistory, activeWebsitesList: activesWebsitesList, ...info },
   } = useSingleSubscription();

   return (
      <div className={className}>
         <ModalsProvider>
            <SubscriptionInfo {...info} />
            <ActiveWebsitesCard items={activesWebsitesList} total={info.websites} />
            <PaymentInfoCard autoRenewal={info.renewable} />
            <PaymentHistoryCard items={paymentHistory} />
            <Modal index="billingInfo">
               <BillingInfo {...billingInfo} />
            </Modal>
            <Modal index="autoRenewal">
               <AutoRenewal />
            </Modal>
         </ModalsProvider>
      </div>
   );
};

export default styled(PageContent)``;
