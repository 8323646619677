import { Stylable } from '@avtkit/types/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/Typography';
import UserDataForm from './UserDataForm';

type Props = Stylable;

const UserDataCard: React.FC<Props> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <Card className={className}>
         <CardTitle>{t('Personal information')}</CardTitle>
         <UserDataForm />
      </Card>
   );
};

export default styled(UserDataCard)`
   ${UserDataForm} {
      margin-top: 43px;
   }
`;
