import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   color: 'rgba(170, 170, 170, 0.87)',
};

const lightColors = {
   color: '#000000',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: lightColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   fontSize: '12px',
   fontWeight: '400',
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
