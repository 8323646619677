import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import ActiveSubscriptions from './ActiveSubscriptions';
import { OutlineButton } from '@avtkit/controls/Button';
import i18n from '../../services/i18n';
import { useLinkClickHandler } from 'react-router-dom';
import { ModalsProvider, Modal } from '@avtkit/controls/ModalsManager';
import ActivateSubscription from './ActivateSubscription';
import NoActiveSubscriptions from './NoActiveSubscriptions';
import { useActivation } from '../../contexts/ActivationData';
import { useLogout } from '../../hooks/useLogin';

const MySubscriptionsButton = styled(OutlineButton).attrs({
   variant: 'round',
   size: 'medium',
   children: i18n.t('My Subscriptions'),
})``;
const SwitchAccountButton = styled(OutlineButton).attrs({
   variant: 'round',
   size: 'medium',
   children: i18n.t('Switch Account'),
})``;

const Buttons = styled.div``;

const ActiveSubscriptionsArea: React.FC<Stylable> = (props) => {
   const { className } = props;
   const change = useLinkClickHandler(`/`);
   const {
      state: { items },
   } = useActivation();

   const doLogout = useLogout();

   return (
      <div className={className}>
         {!items.length ? (
            <NoActiveSubscriptions />
         ) : (
            <>
               <ModalsProvider>
                  <ActiveSubscriptions />
                  <Modal index="activeSubscription" closeButton={false} clickOverlayClose={false}>
                     <ActivateSubscription />
                  </Modal>
               </ModalsProvider>
            </>
         )}
         <Buttons>
            <MySubscriptionsButton onClick={change} />
            <SwitchAccountButton onClick={doLogout} />
         </Buttons>
      </div>
   );
};

export default styled(ActiveSubscriptionsArea)`
   ${Buttons} {
      display: flex;

      > *:not(:last-child) {
         margin-right: 10px;
      }
   }
`;
