import { Stylable, WithChildren } from '@/types/commons';
import ImageBox from '@avtkit/controls/ImageBox';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { TABLET_BP, MOBILE_BP } from '../../constants';
import * as scheme from './theme';
import LoginSymbolDesktop from './LoginSymbolDesktop.svg';
import { useMatchMedia } from '@avtkit/hooks/useMatchMedia';
import Logo from '../../components/Logo';

const ContentArea = styled.div``;
const ContentWrapper = styled.section``;
const SymbolArea = styled.div``;

type Props = Stylable & WithChildren & WithThemeProps;

const AuthArea: React.FC<Props> = (props) => {
   const { className } = props;
   const [isMobileBP, setMobileBP] = useState(false);
   useMatchMedia(`(max-width: ${MOBILE_BP})`, setMobileBP);

   return (
      <div className={className}>
         <ContentWrapper>
            <SymbolArea>
               {isMobileBP ? <Logo variant="light" /> : <ImageBox src={LoginSymbolDesktop} fitMode="cover" />}
            </SymbolArea>
            <ContentArea>
               <Outlet />
            </ContentArea>
         </ContentWrapper>
      </div>
   );
};

const theme = withTheme<Props>(scheme);

export default styled(AuthArea)`
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   min-height: 100vh;
   padding: 50px;

   @media screen and (max-width: ${TABLET_BP}) {
      padding: 25px;
   }

   ${ContentArea} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 45px 125px;

      @media screen and (max-width: ${TABLET_BP}) {
         padding-right: 50px;
         padding-left: 50px;
      }
   }

   ${ImageBox} {
      height: 100%;
   }

   ${ContentWrapper} {
      display: flex;
      width: 100%;
      flex-direction: row;
      border-radius: 10px;
      overflow: hidden;

      @media screen and (max-width: ${MOBILE_BP}) {
         flex-direction: column;
      }
   }

   ${SymbolArea} {
      @media screen and (max-width: ${MOBILE_BP}) {
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 40px 40px;

         ${Logo} {
            position: relative;
            display: inline-block;
            left: -10px;
         }
      }
   }
   ${theme.colors(
      (c: scheme.Colors) => css`
         background-color: ${c.background};

         ${SymbolArea} {
            background-color: ${c.symbolArea};
         }

         ${ContentArea} {
            background-color: ${c.contentArea};
         }

         ${ContentWrapper} {
            border: 1px solid ${c.border};
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         ${ContentWrapper} {
            max-width: ${s.wrapper.desktop};
         }

         ${ContentArea} {
            flex: ${s.content.desktop} 0 0;
         }
         @media screen and (max-width: ${TABLET_BP}) {
            ${ContentWrapper} {
               max-width: ${s.wrapper.tablet};
            }
            ${ContentArea} {
               flex: ${s.content.tablet} 0 0;
            }
         }

         @media screen and (max-width: ${MOBILE_BP}) {
            ${ContentWrapper} {
               max-width: ${s.wrapper.mobile};
            }
            ${ContentArea} {
               flex: ${s.content.mobile} 0 0;
            }
         }
      `
   )}
`;
