import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Label from '../../components/Label';
import { Content, PageTitle } from '../../components/Typography';
import { Form, FormInput, FormSubmitButton } from '@avtkit/controls/Form';
import i18n from '../../services/i18n';
import { Flag } from '@avtkit/controls/Flags';
import { Stylable } from '@/types/commons';
import { RouterLink } from '@avtkit/controls/Link';
import { useParams } from 'react-router-dom';
import { useSubmitForm } from '@avtkit/hooks/useSubmitForm';
import { api, axios } from '../../API';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { eightCharPass } from '@avtkit/controls/Form/helpers/presetPatterns';

type Props = Stylable;

const Row = styled.div``;
const Input = styled(FormInput).attrs({ variant: 'medium' })``;
const ChangePasswordButton = styled(FormSubmitButton).attrs({
   size: 'large',
   variant: 'round',
   children: i18n.t('Change password'),
})``;

const ResetPasswordForm = (props: Props) => {
   const { className } = props;
   const { t } = useTranslation();
   const formRef = useRef<HTMLFormElement | null>(null);
   const { token } = useParams();
   const [success, setSuccess] = useState<boolean>(false);

   const onSuccess = useCallback((data) => {
      if (data.success) {
         setSuccess(true);
      }
   }, []);

   const { submit, errorMessage, loading } = useSubmitForm(api.sendResetPasswordFormData, onSuccess, axios);
   const onConfirmPasswordValidator = useCallback((value) => formRef?.current?.newPassword.value === value, []);

   return success ? (
      <ResetPasswordSuccess />
   ) : (
      <div className={className}>
         <PageTitle>{t('Reset Your Password')}</PageTitle>
         <Content>{t('Please enter your new password below')}</Content>
         <Form onSubmit={submit} ref={formRef}>
            <input type="hidden" value={token} name="key" />
            <Row>
               <Label>{t('New password')}</Label>
               <Input
                  type="password"
                  required
                  validationOn="submit"
                  pattern={eightCharPass}
                  validationMessage={t(
                     'Password should be minimum eight characters, at least one letter and one number'
                  )}
                  size="32"
                  name="newPassword"
               />
            </Row>
            <Row>
               <Label>{t('Repeat new password')}</Label>
               <Input
                  type="password"
                  pattern={onConfirmPasswordValidator}
                  required
                  validationOn="submit"
                  size="32"
                  name="confirmedNewPassword"
               />
            </Row>
            <Row>
               <ChangePasswordButton loading={loading} />
            </Row>
            {errorMessage && <Flag type="error">{errorMessage}</Flag>}
            <RouterLink to="/auth/login" backIcon>
               {t('Back to login')}
            </RouterLink>
         </Form>
      </div>
   );
};

export default styled(ResetPasswordForm)`
   ${PageTitle} {
      font-weight: 400;
      margin-bottom: 20px;
   }

   ${Content} {
      margin-bottom: 25px;
   }

   ${Form} {
      ${Label} {
         margin-bottom: 10px;
      }

      ${Row} {
         margin-bottom: 20px;
      }

      ${ChangePasswordButton} {
         width: 100%;
         justify-content: center;
      }
   }
`;
