import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   color: '#222222',
   background: '#F2F3F5',
   border: '#C9C9C9',
   shadow: '2px 10px 25px rgba(0,0,0,.25)',

   inverted: {
      color: '#FFFFFF',
      background: '#272727',
      border: '#191919',
      shadow: '2px 10px 25px rgba(0,0,0,.25)',
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   space: '5px',
   padding: '10px',
   fontSize: '12px',
   radius: '5px',
   maxWidth: '200px',
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */

const tooltipWrapColor = {
   background: '#222222',
   border: '#000000',
   shadow: 'rgba(0, 0, 0, 0.25)',
   color: '#FFF',
};

export type TooltipWrapColors = typeof tooltipWrapColor;
const tooltipWrapColors: ThemeScheme<TooltipWrapColors> = {
   dark: tooltipWrapColor,
};

const tooltipWrapSize = {
   border: '5px',
};

export type TooltipWrapSizes = typeof tooltipWrapSize;
const tooltipWrapSizes: ThemeScheme<TooltipWrapSizes> = {
   default: tooltipWrapSize,
};

export const tooltipWrap = {
   colors: tooltipWrapColors,
   sizes: tooltipWrapSizes,
};
