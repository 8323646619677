import React from 'react';

export type ModalContextType = {
   modalsStack: ModalStack[];
   setModalsStack: React.Dispatch<React.SetStateAction<ModalStack[]>>;
};
export type ModalStack = {
   id: string;
   options: { [key: string]: any };
};

export default React.createContext<ModalContextType>({
   modalsStack: [],
   setModalsStack: () => {},
});
