import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkDotFlashing from '@loadings/loadings/dotFlashing/dark.html';
import lightDotFlashing from '@loadings/loadings/dotFlashing/light.html';
import '@loadings/loadings/dotFlashing/styles.scss';
import LoadingSymbol from './LoadingSymbol';

const DotFlashing = (props: LoadingProps) => (
   <LoadingSymbol {...props} dark={darkDotFlashing} light={lightDotFlashing} />
);

export default styled(DotFlashing)`
   transform: scale(${(props) => (props.size === 'small' ? '0.8' : '1')});
`;
