import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   icon: '#000000',
   iconBg: 'white',
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   border: '10px',
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
};
