import { Stylable } from '@/types/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PremiumFeaturesItem from './PremiumFeaturesItem';

const PremiumFeatures: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <div className={className}>
         <PremiumFeaturesItem>{t('Premium templates and assets')}</PremiumFeaturesItem>
         <PremiumFeaturesItem>{t('​​Premium data sources')}</PremiumFeaturesItem>
         <PremiumFeaturesItem>{t('WooCommerce integration')}</PremiumFeaturesItem>
         <PremiumFeaturesItem>{t('Partial view sliders')}</PremiumFeaturesItem>
         <PremiumFeaturesItem>{t('Parallax effects')}</PremiumFeaturesItem>
         <PremiumFeaturesItem>{t('Premium animations')}</PremiumFeaturesItem>
         <PremiumFeaturesItem>{t('Premium support service')}</PremiumFeaturesItem>
         <PremiumFeaturesItem>{t('All upcoming premium features')}</PremiumFeaturesItem>
      </div>
   );
};

export default styled(PremiumFeatures)`
   ${PremiumFeaturesItem} {
      margin-bottom: 14px;

      &:last-of-type {
         margin-bottom: 0;
      }
   }
`;
