import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { PageTitle } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import { UserProfileProvider } from '../../contexts/UserProfileData';
import UserDataCard from './UserDataCard';
import UserForgotPasswordCard from './UserChangePasswordCard';

const Header = styled.div``;

const Profile: React.FC<Stylable> = (props) => {
   const { className } = props;
   const { t } = useTranslation();

   return (
      <div className={className}>
         <UserProfileProvider>
            <Header>
               <PageTitle>{t('Profile Page')}</PageTitle>
            </Header>
            <UserDataCard />
            <UserForgotPasswordCard />
         </UserProfileProvider>
      </div>
   );
};

export default styled(Profile)`
   ${Header} {
      margin-bottom: 48px;
   }
`;
