import { useCallback, useRef } from 'react';

const useInputValidation = (pattern?: string | ((inputValue?: string) => boolean)) => {
   const ref = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

   const checkPatternValidation = useCallback(
      (inputValue?: string) => {
         if (!pattern) {
            return true;
         }
         if (typeof pattern === 'string') {
            return RegExp(pattern).test(inputValue ?? '');
         }
         return pattern(inputValue);
      },
      [pattern]
   );

   const checkValidity = useCallback(() => {
      ref.current?.setCustomValidity('');

      const value = ref.current?.value;
      const patternValidation = checkPatternValidation(value);

      if (ref.current?.validity.valid && patternValidation) {
         ref.current?.setCustomValidity('');

         return true;
      }
      ref.current?.setCustomValidity('invalid');

      return false;
   }, [checkPatternValidation]);

   const setInputRef = useCallback((inputRef: HTMLInputElement | HTMLTextAreaElement) => {
      ref.current = inputRef;
   }, []);

   return {
      checkValidity,
      setInputRef,
   };
};

export default useInputValidation;
