import { useCallback, useEffect, useMemo } from 'react';

export const useMatchMedia = (query: string, change: (matches: boolean) => void) => {
   const media = useMemo(() => window.matchMedia(query), [query]);

   const check = useCallback(() => {
      change(media.matches);
   }, [change, media.matches]);

   useEffect(() => {
      media.addEventListener('change', check);
      change(media.matches);
      return () => {
         media.removeEventListener('change', check);
      };
   }, [change, check, media]);
};
