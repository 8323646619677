import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@avtkit/helpers/withTheme';
import { TableProps } from './types';
import * as scheme from './theme';
// import Scrollbars from '@avtkit/controls/Scrollbars';

const Table: React.FC<TableProps> = (props: TableProps) => <table {...props} />;
const theme = withTheme<TableProps>(scheme);

export default styled(Table)<TableProps>`
   width: 100%;
   border-collapse: collapse;

   th {
      text-align: left;
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         tbody tr {
            &:nth-child(odd) {
               background-color: ${c.row.odd.background};
               color: ${c.row.odd.color};
            }
            &:nth-child(even) {
               background-color: ${c.row.even.background};
               color: ${c.row.even.color};
            }
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         th {
            font-size: ${s.heading.fontSize};
            font-weight: ${s.heading.fontWeight};
            padding: ${s.heading.padding};
         }

         td {
            font-size: ${s.cell.fontSize};
            font-weight: ${s.cell.fontWeight};
            padding: ${s.cell.padding};
         }
      `
   )}
`;
