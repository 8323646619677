import React from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import styled from 'styled-components';
import { MOBILE_BP } from '../../constants';
import SubscriptionCard, { SubscriptionCardProps } from '../../features/SubscriptionCard';
import { DetailsButton } from './DetailsButton';
import { UpgradeButton } from './UpgradeButton';

const SubscriptionItem: React.FC<SubscriptionCardProps> = (props) => {
   const { id } = props;
   const change = useLinkClickHandler(`/subscriptions/${id}`);

   const buttons = (
      <>
         <DetailsButton onClick={change} />
         <UpgradeButton />
      </>
   );

   return <SubscriptionCard buttons={buttons} onClick={change} {...props} />;
};

export default styled(SubscriptionItem)`
   ${DetailsButton} {
      /* margin-right: 20px; */
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      ${DetailsButton} {
         margin-right: 10px;
      }
   }
`;
