import { SolidButton } from '@avtkit/controls/Button';
import { Flag } from '@avtkit/controls/Flags';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MOBILE_BP } from '../../constants';
import SubscriptionCard, { SubscriptionCardProps } from '../../features/SubscriptionCard';

const ExpiredContainer = styled.div``;
const RenewButton = styled(SolidButton)``;

const SubscriptionInfo: React.FC<SubscriptionCardProps> = (props) => {
   const { t } = useTranslation();
   const { status } = props;

   return (
      <SubscriptionCard {...props}>
         {status === 'expired' && (
            <ExpiredContainer>
               <Flag type="error">
                  {t(
                     'Your subscription has been expired. Please renew it to have access to the Depicter Pro features.'
                  )}
               </Flag>
               <RenewButton size="small">{t('Renew')}</RenewButton>
            </ExpiredContainer>
         )}
      </SubscriptionCard>
   );
};

export default styled(SubscriptionInfo)`
   ${ExpiredContainer} {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
   }

   ${Flag} {
      width: 100%;
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      ${ExpiredContainer} {
         margin-top: 20px;
      }
   }
`;
