import { AxiosError, AxiosInstance } from 'axios';
import { useEffect, useRef } from 'react';
import useAuthentication from './useAuthentication';

export const useAddAxiosAuth = (axios?: AxiosInstance, onUnauthorized?: (error: AxiosError) => void) => {
   const { removeToken, token } = useAuthentication();
   const isSet = useRef<boolean>(false);
   const requestIntercepts = useRef<number>(0);
   const responseIntercepts = useRef<number>(0);

   if (!isSet.current && token && axios) {
      isSet.current = true;
      requestIntercepts.current = axios.interceptors.request.use(
         (request) => ({ ...request, headers: { ...request.headers, Authorization: `Bearer ${token}` } }),
         (error) => Promise.reject(error)
      );

      responseIntercepts.current = axios.interceptors.response.use(
         (response) => response,
         (error) => {
            if ([401].includes(error?.response?.status)) {
               onUnauthorized?.(error);
               removeToken();
            }
            return Promise.reject(error);
         }
      );
   }

   useEffect(
      () => () => {
         axios?.interceptors.response.eject(responseIntercepts.current);
         axios?.interceptors.request.eject(requestIntercepts.current);
         isSet.current = false;
      },
      [axios, axios?.interceptors.request, axios?.interceptors.response, onUnauthorized, removeToken, token]
   );
};
