import { WithThemeProps, withTheme } from '@avtkit/helpers/withTheme';
import { Stylable, WithChildren } from '@avtkit/types/commons';
import * as React from 'react';
import styled, { css } from 'styled-components';
import * as scheme from './theme';

type Props = Stylable & WithChildren & WithThemeProps;

const ContentWrapper = styled.div``;

const Separator = (props: Props) => {
   const { children } = props;

   return (
      <div {...props}>
         <ContentWrapper>{children}</ContentWrapper>
      </div>
   );
};

const theme = withTheme<Props>(scheme);

export default styled(Separator)`
   display: flex;
   align-items: center;
   font-size: 13px;
   line-height: 16px;
   font-weight: 600;

   &:before,
   &:after {
      content: '';
      width: 100%;
      height: 1px;
   }

   > ${ContentWrapper} {
      margin: 0 5px;
      color: #cacaca;
   }

   ${theme.colors(
      (c: scheme.Colors) => css`
         color: ${c.color};

         &:before,
         &:after {
            background: ${c.color};
         }
      `
   )}
`;
