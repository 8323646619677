/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-sequences */
/* eslint-disable consistent-return */
/* eslint-disable no-multi-assign */
/* eslint-disable no-cond-assign */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck

import { useCallback, useEffect, useState } from 'react';

export const useLoadBeacon = (beaconId: string) => {
   const [loaded, setIsLoaded] = useState<boolean>(false);
   const [status, setStatus] = useState<'open' | 'close'>('close');

   useEffect(() => {
      !(function (e, t, n) {
         function a() {
            const e = t.getElementsByTagName('script')[0];
            const n = t.createElement('script');
            (n.type = 'text/javascript'),
               (n.async = !0),
               (n.src = 'https://beacon-v2.helpscout.net'),
               e.parentNode.insertBefore(n, e);
         }
         if (
            ((e.Beacon = n =
               function (t, n, a) {
                  e.Beacon.readyQueue.push({ method: t, options: n, data: a });
               }),
            (n.readyQueue = []),
            t.readyState === 'complete')
         )
            return a();
         e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
      })(window, document, window.Beacon || (() => {}));

      window.Beacon('on', 'ready', () => {
         setIsLoaded(true);
      });

      window.Beacon('on', 'open', () => {
         setStatus('open');
      });

      window.Beacon('on', 'close', () => {
         setStatus('close');
      });

      window.Beacon('init', beaconId);
   }, [beaconId]);

   const action = useCallback((action: 'open' | 'close') => {
      window.Beacon(action);
   }, []);

   return { loaded, status, action };
};
