import { Stylable } from '@/types/commons';
import { useModal } from '@avtkit/controls/ModalsManager/useModal';
import React from 'react';
import styled from 'styled-components';
import { Subscription } from '../../API/types';
import { CardTitle } from '../../components/Typography';
import SubscriptionCard from '../../features/SubscriptionCard';
import { useTranslation } from 'react-i18next';
import Label from '../../components/Label';
import browserEnv from '../../env/browser.env';
import { MOBILE_BP, TABLET_BP } from '../../constants';
import { SolidButton } from '@avtkit/controls/Button';
import i18n from '../../services/i18n';

const ModalDesc = styled(Label)``;
const Buttons = styled.div``;
const CloseButton = styled(SolidButton).attrs({
   size: 'medium',
   type: 'secondary',
   variant: 'round',
   children: i18n.t('Cancel'),
})``;

const ActiveButton = styled(SolidButton).attrs({
   size: 'medium',
   variant: 'round',
   type: 'tertiary',
   children: i18n.t('Activate Now'),
})``;

const DomainName = styled.span``;

const ActivateSubscription: React.FC<Stylable> = (props) => {
   const { className } = props;
   const {
      activeModal: { id, options },
      closeModal,
   } = useModal();
   const { t } = useTranslation();

   return (
      <div className={className}>
         <CardTitle>{t('Would you like to activate?')}</CardTitle>
         <ModalDesc>
            {t('This will activate ')}
            <DomainName>{browserEnv.activation?.domain}</DomainName>
            {t(' on the below following subscription')}
         </ModalDesc>
         <SubscriptionCard {...(options as Subscription)} />
         <Buttons>
            <CloseButton onClick={() => closeModal(id)} />
            <ActiveButton />
         </Buttons>
      </div>
   );
};

export default styled(ActivateSubscription)`
   width: 830px;
   padding: 45px;

   ${CardTitle} {
      margin-bottom: 10px;
   }

   ${ModalDesc} {
      margin-bottom: 30px;
   }

   ${DomainName} {
      font-weight: 600;
      color: #2f80ed;
   }

   ${Buttons} {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > *:not(last-child) {
         margin-left: 10px;
      }
   }

   @media screen and (max-width: ${TABLET_BP}) {
      width: 680px;
   }

   @media screen and (max-width: ${MOBILE_BP}) {
      width: 390px;
      padding: 30px;
   }
`;
