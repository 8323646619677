import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { ChangelogData } from '../../API/types';
import Tag from '../../components/Tag';
import ReactMarkdown from 'react-markdown';

type Props = Stylable & ChangelogData;

const ChangelogCard: React.FC<Props> = (props) => {
   const { className, type, content } = props;

   return (
      <div className={className}>
         <Tag variant={type} />
         <ReactMarkdown>{content}</ReactMarkdown>
      </div>
   );
};

export default styled(ChangelogCard)`
   ${Tag} {
      margin-bottom: 10px;
   }

   ul {
      margin: 0;
      padding-inline-start: 20px;

      li::marker {
         color: #bdbdbd;
      }
   }
`;
