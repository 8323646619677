import { ThemeScheme } from '@avtkit/helpers/withTheme';
import { merge } from 'lodash';

const dropdownColor = {
   background: '#272727',
   borderColor: '#6A6A6A',
   color: '#FFFFFF',
   hover: '#4980f5',
   activeColor: '#aaaaaa',
   icon: '#AAAAAA',
   focus: '#4980F5',
   disabled: {
      background: '#444444',
      borderColor: '#6A6A6A',
      color: '#6A6A6A',
      icon: '#6A6A6A',
   },
   hovers: {
      borderColor: '#C4C4C4',
      color: '#FFFFFF',
   },
   multi: {
      background: '#444444',
      color: '#FFF',
      hover: '#6A6A6A',
      icon: '#FFF',
   },
};

export type DropdownColors = typeof dropdownColor;
const dropdownColors: ThemeScheme<DropdownColors> = {
   dark: dropdownColor,
   light: merge({}, dropdownColor, {
      background: '#FFFFFF',
      borderColor: '#CACACA',
      color: '#000000',
      hover: '#D9D9D9',
      activeColor: '#aaaaaa',
      icon: '#000000',
      focus: '#D9D9D9',

      disabled: {
         icon: '#6A6A6A',
         background: '#FDFDFD',
         borderColor: '#CACACA',
         color: '#CACACA',
      },
   }),
};

/* ------------------------------------------------------------------------------ */
const dropdownSize = {
   medium: {
      minWidth: '160px',
      borderRadius: '5px',
      fontSize: '12px',
      fontWeight: '600',
      dropdownDistance: '5px',
      padding: '11px 15px',
      indicatorPadding: '10px 12px 10px 0',
      height: '37px',
   },
   small: {
      minWidth: '120px',
      borderRadius: '5px',
      fontSize: '11px',
      fontWeight: '600',
      dropdownDistance: '5px',
      padding: '8px 9px',
      indicatorPadding: '5px 9px 5px 0px',
      height: '31px',
   },
   tiny: {
      minWidth: '100px',
      borderRadius: '5px',
      fontSize: '9px',
      fontWeight: '600',
      dropdownDistance: '5px',
      padding: '6px 9px',
      indicatorPadding: '3px 9px 3px 0px',
      height: '25px',
   },
   multi: {
      borderRadius: '3px',
   },
};

export type DropdownSizes = typeof dropdownSize;
const dropdownSizes: ThemeScheme<DropdownSizes> = {
   default: dropdownSize,
};

export const dropdown = {
   colors: dropdownColors,
   sizes: dropdownSizes,
};
