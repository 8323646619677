import { Stylable } from '@/types/commons';
import { withTheme } from '@avtkit/helpers/withTheme';
import { useMatchMedia } from '@avtkit/hooks/useMatchMedia';
import { OffCanvasWrapper, useOffCanvas } from '@avtkit/controls/OffCanvas';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Logo from '../../components/Logo';
import { OFFCANVAS_MENU_BP, SIDEBAR_WIDTH } from '../../constants';
import Menu from '../Menu';

import * as scheme from './theme';
import { BurgerCloseButton } from '../../components/BurgerMenu';
import { useLocation } from 'react-router-dom';

type Props = Stylable & { onOffCanvas: (isOffCanvas: boolean) => void };

const Sidebar: React.FC<Props> = (props) => {
   const { className, onOffCanvas } = props;
   const [offCanvas, setOffCanvas] = useState<boolean>();
   const location = useLocation();
   const { closeOffCanvas } = useOffCanvas();

   const onMediaStateChange = useCallback(
      (state: boolean) => {
         setOffCanvas(state);
         onOffCanvas(state);
      },
      [onOffCanvas]
   );

   useEffect(() => {
      closeOffCanvas('offcanvasMenu');
   }, [closeOffCanvas, location]);

   useMatchMedia(`(max-width: ${OFFCANVAS_MENU_BP})`, onMediaStateChange);

   const content = (
      <div className={className}>
         <Logo />
         <Menu />
      </div>
   );

   return offCanvas ? (
      <OffCanvasWrapper
         useBackdrop
         index="offcanvasMenu"
         closeButton={<BurgerCloseButton />}
         width={SIDEBAR_WIDTH}
         height="100vh"
      >
         {content}
      </OffCanvasWrapper>
   ) : (
      content
   );
};

const theme = withTheme(scheme);

export default styled(Sidebar)`
   width: ${SIDEBAR_WIDTH};
   height: 100vh;
   background-color: white;
   position: fixed;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding: 50px 48px;

   ${theme.colors(
      (c: scheme.Colors) => css`
         background-color: ${c.background};
         border-right: solid 1px ${c.border};
      `
   )}
`;
