import React from 'react';
import MultiValueRemoveProps, { components } from 'react-select';
import styled from 'styled-components';
import Remove from './icons/Remove.svgr';

const RemoveIndicator = ({ ...props }: MultiValueRemoveProps<any>) => (
      <components.MultiValueRemove {...props}>
         <Remove />
      </components.MultiValueRemove>
   );

export default styled(RemoveIndicator)``;
