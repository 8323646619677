import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';
import { Changelog, ChangelogData } from '../../API/types';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/Typography';
import ChangelogItem from './ChangelogItem';

type Props = Stylable & Changelog;

const ReleaseDate = styled(CardTitle)``;
const Title = styled(CardTitle)``;

const ChangelogCard: React.FC<Props> = (props) => {
   const { className, title, releaseDate, changelog } = props;

   const generateChangelogItem = (changelogItem: ChangelogData) => (
      <ChangelogItem key={changelogItem.type} {...changelogItem} />
   );

   return (
      <Card className={className}>
         <Title>{title}</Title>
         <ReleaseDate>{`- ${releaseDate}`}</ReleaseDate>
         {changelog.map(generateChangelogItem)}
      </Card>
   );
};

export default styled(ChangelogCard)`
   ${ReleaseDate} , ${Title} {
      display: inline-block;
   }

   ${Title} {
      margin-right: 5px;
      margin-bottom: 15px;
   }

   ${ReleaseDate} {
      color: #bdbdbd;
   }

   ${ChangelogItem} {
      margin-bottom: 20px;

      &:last-child {
         margin-bottom: 0;
      }
   }
`;
