import { Flag } from '@avtkit/controls/Flags';
import { LoginForm } from '@avtkit/controls/Form';
import { useSubmitForm } from '@avtkit/hooks/useSubmitForm';
import { Stylable } from '@avtkit/types/commons';
import React from 'react';
import styled from 'styled-components';
import { api, axios } from '../../API';
import { UserData } from '../../API/types';

type Props = Stylable & {
   remember: boolean;
   onLogin: (data: UserData) => void;
   onRememberChange: (remember: boolean) => void;
};

const LoginFormComponent = (props: Props) => {
   const { className, onLogin, onRememberChange, remember } = props;
   const { submit, errorMessage, loading } = useSubmitForm(api.sendLoginFormData, onLogin, axios);

   return (
      <LoginForm
         className={className}
         onSubmit={submit}
         isRemember={remember}
         onRememberChange={onRememberChange}
         hasError={!!errorMessage}
         errorMessage={errorMessage}
         loading={loading}
      />
   );
};

export default styled(LoginFormComponent)`
   margin-bottom: 20px;
   > div {
      margin-bottom: 20px;

      > label {
         display: block;
         margin-bottom: 10px;
      }

      button[type='submit'] {
         width: 100%;
         justify-content: center;
      }

      &:nth-of-type(3) {
         font-size: 13px;
      }
   }

   ${Flag} {
      margin-top: 20px;
   }
`;
