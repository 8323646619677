import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   background: '#FAFAFA',
   footer: {
      textColor: '#70798B',
   },
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   light: defaultColors,
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
