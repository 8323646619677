import { RegisterForm } from '@avtkit/controls/Form';
import { useSubmitForm } from '@avtkit/hooks/useSubmitForm';
import { Stylable } from '@avtkit/types/commons';
import React from 'react';
import styled from 'styled-components';
import { api, axios } from '../../API';
import { UserData } from '../../API/types';

type Props = Stylable & { onLogin: (data: UserData) => void };

const RegisterFormComponent = (props: Props) => {
   const { className, onLogin } = props;
   const { submit, errorMessage, loading } = useSubmitForm(api.sendRegisterFormData, onLogin, axios);

   return (
      <RegisterForm
         className={className}
         onSubmit={submit}
         hasError={!!errorMessage}
         errorMessage={errorMessage}
         loading={loading}
      />
   );
};

export default styled(RegisterFormComponent)`
   > div {
      margin-bottom: 20px;

      &:nth-of-type(1) {
         display: inline-block;
         margin-right: 5px;
         width: calc(50% - 5px);
      }

      &:nth-of-type(2) {
         display: inline-block;
         margin-left: 5px;
         width: calc(50% - 5px);
      }
   }
   label {
      margin-bottom: 10px;
      display: block;
   }

   button {
      width: 100%;
      justify-content: center;
   }
`;
