import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Stylable, WithChildren } from '@avtkit/types/commons';
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import GoogleLogin from './GoogleLogin';
import { Flag } from '../Flags';

type Props = Stylable &
   WithChildren & {
      request: (token: string) => AxiosRequestConfig;
      axios?: AxiosInstance;
      onLogin: (data: any) => void;
   };

const JWTGoogleLogin: React.FC<Props> = (props) => {
   const { className, axios = Axios, request, children, onLogin } = props;
   const [error, setError] = useState<string>('');

   const loginByGoogle = useCallback(
      (googleData) => {
         const { access_token: token } = googleData;
         axios
            .request(request(token))
            .then(({ data }) => onLogin(data))
            .catch((errorRes: AxiosError) => setError(errorRes.response?.data.errors?.[0] || errorRes.message));
      },
      [axios, onLogin, request]
   );

   return (
      <div className={className}>
         <GoogleLogin
            onError={(errorResp) => setError(`${errorResp.error}-${errorResp.error_description}`)}
            onSuccess={loginByGoogle}
         >
            {children}
         </GoogleLogin>
         {!!error && <Flag type="error">{error}</Flag>}
      </div>
   );
};

export default styled(JWTGoogleLogin)`
   ${GoogleLogin}, ${Flag} {
      width: 100%;
   }

   ${Flag} {
      margin-top: 10px;
   }
`;
