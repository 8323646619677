import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import IconButton from './IconButton';
import { OptionButtonColors, OptionButtonSizes, optionButton } from '../theme';
import { ButtonProps } from '../types';

export type OptionButtonProps = WithThemeProps & ButtonProps;
const theme = withTheme<OptionButtonProps>(optionButton);

export default styled(IconButton)`
   display: inline-flex;
   align-items: center;
   justify-content: center;

   ${theme.colors(
      (c: OptionButtonColors) => css`
         background: ${c.background};
      `
   )}

   ${theme.sizes(
      (s: OptionButtonSizes) => css`
         width: ${s.width};
         height: ${s.height};
         border-radius: ${s.border};
      `
   )}
`;
