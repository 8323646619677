import { Stylable } from '@/types/commons';
import React from 'react';
import styled from 'styled-components';

import { useUserPanelData } from '../../contexts/UserPanelData';
import Logo from '../../components/Logo';
import UserMenu from '../UserMenu';

const Header: React.FC<Stylable> = (props) => {
   const { className } = props;
   const {
      state: { user },
      status,
   } = useUserPanelData();

   return (
      <div className={className}>
         <Logo />
         {status === 'loaded' && <UserMenu {...user} />}
      </div>
   );
};

export default styled(Header)`
   display: flex;
   position: relative;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 50px;
   min-height: 100px;

   ${Logo} {
      width: 128px;
   }
`;
