import { AuthenticationData } from '../types';
import { createContext } from 'react';

export type AuthenticationContext = AuthenticationData & {
   setAuthenticationData: React.Dispatch<React.SetStateAction<AuthenticationData>>;
};

export default createContext<AuthenticationContext>({
   isAuthorized: false,
   setAuthenticationData: () => {},
});
