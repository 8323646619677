import React from 'react';
import styled, { css } from 'styled-components';
import { MenuDivider } from '@szhsin/react-menu';
import { DividerProps } from '../types';
import { withTheme } from '@avtkit/helpers/withTheme';
import * as scheme from '../theme';

const StyledMenuDivider = ({ className }: DividerProps) => <MenuDivider className={className} />;

const theme = withTheme<DividerProps>(scheme);

export default styled(StyledMenuDivider)`
   margin: 5px 0px;

   ${theme.colors(
      (c: scheme.Colors) => css`
         background: ${c.divider};
      `
   )}
`;
